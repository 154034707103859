import profileStore from "../stores/profile-store";
import subscriptionStore from '../stores/subscription-store';
import usertimelineStore from '../stores/user-timeline-store';
import authStore from '../stores/auth-store';


/**
 * Load necessary data for feed
 */
 export async function LoadFeed() {
  if (authStore.loggedIn) await usertimelineStore.retrieveFeed('all');
}

/**
 * Load necessary data for profile
 */
export async function LoadProfile({ pathname }) {
  // await profileStore.getProfile(pathname.slice(1));
}

/**
 * Load necessary data for verifying email
 */
export async function LoadVerifyEmail() {}

/**
 * Load necessary data for reset password
 */
export async function LoadResetPassword() {}

/**
 * Load necessary data for sent meetups
 */
export async function LoadSentMeetups() {}

/**
 * Load necessary data for reported bugs
 */
export async function LoadReportedBugs() {}

/**
 * Load necessary data for edit profile
 */
export async function LoadEditProfile() {
  await profileStore.getSignals();
}

/**
 * Load necessary data for settings
 */
export async function LoadSettings() {}

/**
 * Load ncessary data for subscribe page
 */
 export async function LoadSubscribePage() {
  await subscriptionStore.getSubscriptions();
}

