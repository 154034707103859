import authenticationService from "../_services/authentication.service";
const textResponse = {
  OK: 'OK',
  '': ''
}
/**
  *  handle response from the api
  *  - checks the response status. 
  *  - If it is 401, 
  *  - update refresh token,other wise throw an error
  *  - on success return data
  * 
  */
async function handleResponse(response, fetchResponseAgain) {
  if (response == null) {
    return Promise.reject("Network Error");
  }
  else {
    return response.text().then(async (text) => {
      const data = textResponse[text] || (text && JSON.parse(text))
      if (!response.ok) {
        if ([401].indexOf(response.status) !== -1 && fetchResponseAgain) {
          await authenticationService.updateToken();
          return handleResponse(await fetchResponseAgain())
        }
        const error = (data && data.message) || response.statusText;

        return Promise.reject(error);
      }
      return data;
    });

  }

}

export default handleResponse;
