export const rootId = "root";
export const token = "eventPlannerAuthenticationToken";
export const eventPlannerCurrentUser = "eventPlannerCurrentUser";
export const isLoggedIn = "isLoggedIn";
export const image="https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/A_black_image.jpg/640px-A_black_image.jpg"
export const REACT_APP_GA = process.env.REACT_APP_GA || 949749753754
export const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000'
export const CLIENT_ID = '576714084170-nclprv26vuav3hh4tj4uet1muob7lu8c.apps.googleusercontent.com'
export const API_KEY = 'AIzaSyBeDrv3TRs0m95DA2pv5k0FujG-Jr9HmU0'
export const REACT_APP_DEMO_VIDEO = process.env.REACT_APP_DEMO_VIDEO || "https://www.youtube.com/watch?v=mg7QR30DRDA"
export const REACT_APP_EMAILS_FORM = process.env.REACT_APP_EMAILS_FORM || "https://docs.google.com/forms/d/e/1FAIpQLSciPO2zZ9QMymks12CwmHbd9sbSgOQ8JdWUjf__9Z1p3tQZKA/viewform?embedded=true"
export const FEATURED_ON_TOP = process.env.REACT_APP_FEATURED_ON_TOP === "true"
export const WHATSAPP_MESSAGE = process.env.REACT_APP_WHATSAPP_MESSAGE || "I can see all Leuven's events in one place on Leuven Event Planner. Sign up so we can find and go to events together"
export const REACT_APP_ORGANIZER_PAGE_REQUIRED = process.env.REACT_APP_ORGANIZER_PAGE_REQUIRED === "true"
export const REACT_APP_ORGANIZER_PAGE_MIN_DATE = process.env.REACT_APP_ORGANIZER_PAGE_MIN_DATE || "31/12/2030 23:59:59.999"
export const REACT_APP_INVITES_PAGE_REQUIRED = process.env.REACT_APP_INVITES_PAGE_REQUIRED === "true"
export const REACT_APP_INVITES_PAGE_MIN_DATE = process.env.REACT_APP_INVITES_PAGE_MIN_DATE || "31/12/2030 23:59:59.999"
export const REACT_APP_THANK_YOU_VIDEO = process.env.REACT_APP_THANK_YOU_VIDEO || "https://www.youtube.com/watch?v=mg7QR30DRDA"
export const MIN_INVITES = parseInt(process.env.REACT_APP_MIN_INVITES) || 2
export const BLOCK_STUDENT_EMAIL = process.env.REACT_APP_BLOCK_STUDENT_EMAIL === "true"

