import React, { useState, useEffect } from 'react';
import {
  getOrganizers,
  deleteOrganizer,
  numberOfOrganizers,
} from '../../_services/organizers.service';
import hermes from '../../utilities/hermes';
import {
  Image,
  Button,
  Grid,
  Card,
  Header,
  Container,
} from 'semantic-ui-react';
import LinkWithLoader from '../../components/link-with-loader';
import Loader from '../../components/loader';
import ReactPaginate from 'react-paginate';
import '../../css/user-timeline.css';
import authenticationService from '../../_services/authentication.service';
import { Link, BrowserRouter as Router, Route } from 'react-router-dom';

const PER_PAGE = 20;

const ListOrganizers = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [organizerTotal, setOrganizerTotal] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  // get all orgnaizers
  const getOrganizerList = async () => {
    setLoading(true);
    const res = await getOrganizers(pageNumber);
    setEvents(res);
    setLoading(false);
  };

  const getTotalNumberOfOrganizers = async () => {
    const res = await numberOfOrganizers();
    setOrganizerTotal(res);
  };

  useEffect(() => {
    getOrganizerList();
    getTotalNumberOfOrganizers();
  }, []);

  const resE = Object.values(events);
  // display in alphbetical order
  const displayArrayinAlpa =
    resE[0] && resE[0].sort((a, b) => a.name.localeCompare(b.name));
  let pagL = resE[0];

  function calculatePagesCount(pageSize, totalCount) {
    // we suppose that if we have 0 items we want 1 empty page
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  }

  var pageSize = 10;
  var itemsCount = organizerTotal;
  var pagesCount = calculatePagesCount(pageSize, itemsCount);

  // delete organizer
  const deleteOrganizerHandler = async (id) => {
    const res = deleteOrganizer(id).then((res) => {
      getOrganizerList(res);
    });
    await getOrganizerList();
    hermes.success('Organizer deleted');
    await getOrganizerList();
  };

  // paginate using backend
  const loadPage = (e) => {
    e.preventDefault();
    const params = new URLSearchParams(window.location.search);
    const page = parseInt(params.get('page')) || 1;
    if (page !== pageNumber) {
      getOrganizerList();
    }
    getOrganizerList();
  };
  return (
    <>
      <Container fluid>
        <div className="feed">
          <div className="main">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <div>
                <div>
                  Page {pageNumber} of {pagesCount}
                </div>
                <form
                  onSubmit={(e) => loadPage(e)}
                  style={{ marginBottom: '10px' }}
                >
                  <input
                    name="page"
                    value={pageNumber}
                    placeholder="Page Number Input"
                    type="number"
                    className="page-count-input"
                    min="1"
                    max={pagesCount}
                    onChange={(e) => setPageNumber(e.target.value)}
                  />
                  <Button className="page-count-submit" type="submit" basic>
                    Go To
                  </Button>
                </form>
              </div>
              <div style={{height:'25px', marginLeft:'10px'}}>
                <Link to={`/add-organizer`}>
                  <Button primary type="submit">
                    Add Organizer
                  </Button>
                </Link>
              </div>
            </div>

            <div>
              {loading ? (
                <span>Loading...</span>
              ) : (
                <>
                  <div>
                    <b>A-Z</b>
                  </div>
                  {pagL.map((org) => {
                    return (
                      <Card fluid className="event-list-card" key={org._id}>
                        <Card.Content>
                          <Image
                            style={{
                              width: '80px',
                              height: '80px',
                              marginBottom: '10px',
                            }}
                            src={org.image}
                            size="small"
                            circular
                          />
                          <Card.Header
                            style={{ color: 'white' }}
                            content={`Organizer: ${org.name}`}
                          />
                        </Card.Content>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                          <div className="mb">
                            <Link to={`/edit-organizer/${org._id}`}>
                              <Button
                                className="e_buttons"
                                primary
                                type="submit"
                              >
                                Edit
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <a onClick={() => deleteOrganizerHandler(org._id)}>
                              <Button type="button" className="e_buttons" basic>
                                Delete
                              </Button>
                            </a>
                          </div>
                        </div>
                      </Card>
                    );
                  })}
                </>
              )}
            </div>
            <Loader />
            {/* {children} */}
          </div>
        </div>{' '}
      </Container>
    </>
  );
};

export default ListOrganizers;
