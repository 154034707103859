/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { errors, success } from '../../messages';
import { useHistory } from 'react-router';
import hermes from '../../utilities/hermes';
import {
  Button,
  Input,
  Form,
  Segment,
  Header,
  Popup,
  Container,
  Select,
  Label,
  Checkbox,
  Dropdown
} from 'semantic-ui-react';
import {
  getEvent,
  updateEvent,
  getOrganizers,
} from '../../_services/event.service';
import Fuse from 'fuse.js';
import {
  createCategory,
  getCategories
} from '../../_services/categories.service';
import '../../css/settings.css';
import subscriptionStore from '../../stores/subscription-store';
import moment from 'moment';
import SearchWithAddition from '../../components/forms/SearchWithAddition';
import { capitalize } from '../../utilities';

const SelectedEntity = ({ id, name, remove }) => {
  return (
    <Label className="backend-ui entity-label" basic>
      {name}
      <div onClick={remove} className="remove">
        <span>x</span>
      </div>
    </Label>
  );
};

const EditEvent = ({ match }) => {
  const {
    params: { id },
  } = match;
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [select, setSelect] = useState(false);
  const [evnt, setEvnt] = useState({});
  const [eventOrganizer, setEventOrganizer] = useState([]);
  const [categoryFuse, setCategoryFuse] = useState(null);
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const createBtnData = {
    name: capitalize(category.trim()),
    isAddition: true,
    onClick: () => handleCreateCategory(capitalize(category.trim()))
  }
  const [categoryResults, setCategoryResults] = useState([createBtnData]);



  useEffect(() => {
    if (categoryFuse) {;
      setCategoryResults([...categoryFuse.search(category).map(i => (
        i.item
      )), createBtnData]);
    }
  }, [categoryFuse, category]);


  const startMM = moment(evnt.startDate).utc(true).format();
  const startED = moment(evnt.endDate).utc(true).format();

  useEffect(async () => {
    const res = await getEvent(id);
    const startMM = moment(res.startDate).utc(true).format();
    const startED = moment(res.endDate).utc(true).format();
    setEvnt(res);

    const categories = await getCategories();
    setCategories(categories)
    setCategoryFuse(new Fuse(categories, {
      keys: ['name'],
      threshold: 0.3,
    }));

    setFormData({
      title: res.title,
      startDate: startMM && startMM.slice(0, -4),
      endDate: startED && startED.slice(0, -4),
      organizerId: res.organizer._id,
      link: res.link,
      cancelled: res.cancelled,
      categories: res.categories,
    });
  }, [id]);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: evnt.title,
    startDate: evnt.startDate,
    endDate: evnt.endDate,
    organizerId: evnt.organizerId,
    link: evnt.link,
    cancelled: evnt.cancelled,
    categories: evnt.categories || [],
  });
  const [org, setOrg] = useState('');

  useEffect(async () => {
    const organizerRes = await subscriptionStore.getOrganizers();
    setEventOrganizer(organizerRes);
  }, []);
  const {
    title,
    startDate,
    startTime,
    endDate,
    endTime,
    organizerId,
    link,
    cancelled,
  } = formData;

  console.log(formData);


  const convertObjectToArray = (obj) => {
    const array = [];
    for (let key in obj) {
      array.push(obj[key]);
    }
    return array;
  };

  const listOrganizer = convertObjectToArray(eventOrganizer);
  const list = listOrganizer.map((organizer) => {
    return organizer;
  });

  // drop down on change function
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onChangeDrop = (e, data) => {
    setFormData({ ...formData, organizerId: data?.value?._id });
    setOrg(data?.value?.name);
    console.log('formdata', formData);
    console.log('drop down value', data.value._id);
  };

  // remove bubble
  const onRemove = () => {
    setFormData({ ...formData, organizerId: '' });
    setOrg('');
    setEvnt(evnt.organizer && evnt.organizer.name == '');
  };

  // check if checkbox is true of false
  const onCheck = (e) => {
    setFormData({ ...formData, cancelled: !cancelled });
  };

  const onSubmit = () => {
    // try {
    //   await updateEvent(formData, id);
    //   hermes.success('Update succesfull');
    // } catch (error) {
    //   hermes.error("Update failed");
    // }

    const data = {
      ...formData,
      categories: formData.categories.map((category) => category._id),
    };

    updateEvent(data, id).then(() => {
      hermes.success('Update successful');
      history.push('/all-event');
    });
  };

  const handleSelectCategory = (e, { result }) => {
    if (result.id !== 'add') {
      if (!formData.categories.includes(result)) {
        setFormData({
          ...formData,
          categories: [...formData.categories, result]
        });
      }
    }
  }


  const handleCreateCategory = async (category) => {
    try {
      const newCategory = await createCategory(category);
      setCategories([...categories, newCategory]);
      setFormData({
        ...formData,
        categories: [...formData.categories, newCategory]
      });
      setCategory('');
    } catch (error) {
      hermes.error("Create category failed");
      console.log(error)
    }
  };

  const removeCategory = async (category) => {
    setFormData({
      ...formData,
      categories:formData.categories.filter(c => c._id !== category._id)
    });
  }

  let sortOrganizers = eventOrganizer.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  sortOrganizers.forEach((organizer) => {
    delete organizer.image
    delete organizer.description;
  });
  // const startMM = moment(evnt.startDate).utc(true).format();
  // const startED = moment(evnt.endDate).utc(true).format();
  const start = startMM && startMM.slice(0, -4);
  const end = startED && startED.slice(0, -4);
  // console.log('SD', startMM);
  // console.log('ED', end);
  let currentOrgName = evnt.organizer && evnt.organizer.name;
  const currentOrgId = evnt.organizer && evnt.organizer._id;
  return (
    <div>
      <div className="form-template-wrapper">
        <Container fluid className="form-template settings">
          <Header inverted>Edit Event</Header>
          <Segment>
            <Form onSubmit={onSubmit}>
              <div className="form-section">
                <Form.Field>
                  <label className="label">Event Title</label>
                  <Input
                    required
                    name="title"
                    value={title || evnt.title}
                    placeholder="Event Title"
                    onChange={(e) => onChange(e)}
                  />
                </Form.Field>
              </div>
              <div className="form-section date-time">
                <Form.Field className="">
                  <label className="label">Start Date</label>
                  <Input
                    required
                    name="startDate"
                    value={startDate || start}
                    type="datetime-local"
                    placeholder="Start Date"
                    onChange={(e) => onChange(e)}
                  />
                </Form.Field>
              </div>
              <div className="form-section date-time">
                <Form.Field className="">
                  <label className="label">End Date</label>
                  <Input
                    required
                    name="endDate"
                    value={endDate || end}
                    type="datetime-local"
                    placeholder="End Date"
                    onChange={(e) => onChange(e)}
                  />
                </Form.Field>
              </div>

              <div className="form-section" value={organizerId}>
                <Form.Field
                  name="organizerId"
                  value={organizerId}
                  control={Select}
                  className="select-organizer"
                  label="Select an Organizer"
                  options={sortOrganizers}
                  placeholder="Select an Organizer"
                  onChange={onChangeDrop}
                />  
                <div className="organizer">
                {/* {currentOrgId && (
                  <SelectedEntity name={currentOrgName} remove={onRemove} />
                )} */}
                {org ? (
                  <SelectedEntity name={org} remove={onRemove} />
                ) : (
                  <SelectedEntity name={currentOrgName} />
                )}
                </div>
              </div>
              <Form.Field>
                <label className="label">Event Link</label>
                <Input
                  required
                  name="link"
                  value={link || evnt.link}
                  type="url"
                  placeholder="https://example.com"
                  onChange={(e) => onChange(e)}
                />
              </Form.Field>

              <Form.Field>
                <label className="label">Categories</label>
                <SearchWithAddition
                  icon=""
                  className="search-category"
                  results={categoryResults}
                  value={category}
                  onChange={(e, { value }) => { setCategory(value) }}
                  onSelect={handleSelectCategory}
                  placeholder="Add categories"
                />
                <div className="entity-list">
                  {formData.categories.map((category) => {
                    return (
                      <SelectedEntity
                        key={category._id}
                        name={category.name}
                        remove={() => removeCategory(category)}
                      />
                    );
                  })}
                </div>
              </Form.Field>

              <div className="form-section date-time">
                <Form.Field className="">
                  <label className="label">Tick the box to cancel event</label>
                  <div
                    className="checkbox"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {/* <input
                      type="checkbox"
                      name="cancelled"
                      value={cancelled}
                      onChange={(e) => onCheck(e)}
                      checked={cancelled}
                    />{' '}
                    <label className="label" style={{marginLeft:'10px'}} htmlFor="cancelled">
                      {cancelled ? 'Cancelled' : 'Not Cancelled'}
                    </label> */}
                    <Checkbox name="cancelled" className="cancel_box" value={cancelled} checked={cancelled} onChange={(e) => onCheck(e)}  label={cancelled ? 'Cancelled' : 'Not Cancelled'} />
                  </div>
                </Form.Field>
              </div>
              <Button
                primary
                type="submit"
                //   disabled={savingSettingsData || uploadingImage}
              >
                Save
              </Button>
              <Button onClick={history.goBack} type="button" basic>
                Cancel
              </Button>
            </Form>
          </Segment>
        </Container>
      </div>
    </div>
  );
};

export default EditEvent;