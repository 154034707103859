import { Button, Dropdown, Header, Input, Table } from "semantic-ui-react";
import { useState } from "react";
import EditEventRow from "./EditEventRow";
import EventRow from "./EventRow";
import useAdminEvents, { AdminEventsContextProvider, useEventCount } from "./useAdminEvents";
import { filterOptions, sortOptions, formatEvent } from "./helpers";

import styles from "./AdminEvents.module.css";

function AdminEventsTable() {
    const {
        events,
        sort,
        setSort,
        filter,
        setFilter,
        search,
        setSearch,
    } = useAdminEvents();

    const { 
        dateToSearch,
        setDateToSearch,
        count
    } = useEventCount();

    const [adding, setAdding] = useState(false);

    return (
        <div className={styles.container}>
            <Header>Events</Header>

            <div className={styles.filters}>
                <Button
                    onClick={() => setAdding(true)}
                    primary
                    size="small"
                >
                    Add Event
                </Button>

                <div className={styles.dropdownWrapper}>
                    <span>Sort by:</span>
                    <Dropdown
                        className={styles.sortDropdown}
                        options={sortOptions}
                        placeholder="Sort by"
                        search
                        selection
                        value={sort}
                        onChange={(e, { value }) => setSort(value as string)}
                    />
                </div>

                <div className={styles.dropdownWrapper}>
                    <span>Filter:</span>
                    <Dropdown
                        className={styles.sortDropdown}
                        options={filterOptions}
                        placeholder="Filter by"
                        search
                        selection
                        value={filter}
                        onChange={(e, { value }) => setFilter(value as string)}
                    />
                </div>

                <div className={styles.searchWrapper}>
                    <span>Search:</span>
                    <Input
                        className={styles.searchInput}
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            
                <div className={styles.searchWrapper}> 
                    <span>{isNaN(count) ? "Invalid date" : `${count} events after the date: `}</span>
                      <Input
                        value={dateToSearch}
                        onChange={(e) => setDateToSearch(e.target.value)}
                        className={styles.searchInput}
                        placeholder="Search by date"
                    />

                     
                </div>
            </div>


            <Table inverted stackable>
                <Table.Header className={styles.tableHeader} fullWidth>
                    <Table.Row>
                        <Table.HeaderCell>Organizer</Table.HeaderCell>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.HeaderCell>Start Date</Table.HeaderCell>
                        <Table.HeaderCell>End Date</Table.HeaderCell>
                        <Table.HeaderCell>Link</Table.HeaderCell>
                        <Table.HeaderCell>Categories</Table.HeaderCell>
                        <Table.HeaderCell>Featured</Table.HeaderCell>
                        <Table.HeaderCell>Cancelled</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Last updated</Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} textAlign="center">Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {adding && (
                        <EditEventRow
                            exit={() => setAdding(false)}
                        />
                    )}
                    {events.map(event => (
                        <EventRow
                            key={event._id}
                            event={formatEvent(event)}
                        />
                    ))}
                    {events.length === 0 && (
                        <Table.Row>
                            <Table.Cell>
                                No events found
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </div>
    );
}


export default function AdminEvents() {
    return (
        <AdminEventsContextProvider>
            <AdminEventsTable />
        </AdminEventsContextProvider>
    );
}