import RequireRandomPassword from "../../RequireRandomPassword";
import Terms from "./Terms";
import IcpLogo from "../../images/icp-logo.svg";
import { Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import LandingPageNav from "../../components/shared/LandingPageNav";
import authenticationService from "../../_services/authentication.service";

export default function ProtectedTerms() {
  const history = useHistory();
  
  return (
    <>
      {!authenticationService.currentUserValue && (
        <LandingPageNav
          className={"terms-nav"}
          joinColor={"#1f364d"}
          hiddeButtons={false}
          onJoinClick={() => history.push(`/?action=join`)}
          onLoginClick={() => history.push(`/?action=login`)}
        />
    )}
    <RequireRandomPassword
      className="terms"
      label={(password: string) => `Input password "${password}" to read terms`}
      buttonLabel="See Terms"
    >
      <div className="terms-no-navbar">
        <Terms />
      </div>
    </RequireRandomPassword>
    </>
  );
}
