import config from "../_helpers/config";
import { getAsync, postAsync, putAsync, deleteAsync, postFormData, putFormData } from "../_helpers/adapters";
import axios from "axios";
import { storage } from "../utilities";

export const createOrganizer = async (formData) => {
    try {
        const res = await postAsync(`${config.apiUrl}/organizers/`, formData);
        console.log(res);
        return res
    } catch (err) {
        throw err;
    }   
}


export const createOrganizerWithImage = (formData) =>
    postFormData(`${config.apiUrl}/organizers/new`, formData)


export const updateOrganizer = async (formData,id) => {
    try {
        const res = await putAsync(`${config.apiUrl}/organizers/${id}`, formData);
        console.log(res);
        return res
    } catch (err) {
        throw err;
    }   
}

export const updateOrganizerWithImage = (formData,id) =>
    putFormData(`${config.apiUrl}/organizers/with-image/${id}`, formData)

export const numberOfOrganizers = async () => {
    try {
        const res = await getAsync(`${config.apiUrl}/organizers/organizers-total`);
        return res;;
    } catch (err) {
        throw err;
    }
}

export const getOrganizers = async (page) => {
    try {
        const res = await getAsync(`${config.apiUrl}/organizers?page=${page || 1}`);
        // console.log(res);
        return res;
    } catch (err) {
        throw err;
    }
}


export const uploadOrganizerImage = async (formImageData,id) => {
    try {
        const res = await putAsync(`${config.apiUrl}/organizers/${id}/upload-image`, formImageData);
        return res;
    } catch (err) {
        throw err;
    }
}

export const getOrganizer = async (id) => {
    try {
        const res = await getAsync(`${config.apiUrl}/organizers/${id}`);
        return res.organizer;
    } catch (err) {
        throw err;
    }
}

export const deleteOrganizer = async (id) => {
    try {
        const res = await deleteAsync(`${config.apiUrl}/organizers/${id}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const getOrganizerSuggestions = async () => {
    try {
        const res = await getAsync(`${config.apiUrl}/organizers/suggestions`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const suggestOrganizer = async (name) => {
    try {
        const res = await postAsync(`${config.apiUrl}/organizers/suggest`, {name});
        return res;
    } catch (err) {
        throw err;
    }
}

export const approveOrganizer = async (id) => {
    try {
        const res = await postAsync(`${config.apiUrl}/organizers/approve`, {id});
        return res;
    } catch (err) {
        throw err;
    }
}

export const rejectOrganizer = async (id) => {
    try {
        const res = await postAsync(`${config.apiUrl}/organizers/reject`, {id});
        return res;
    } catch (err) {
        throw err;
    }
}



