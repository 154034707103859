import useSWR from 'swr';
import { hermes } from '../utilities';
import { getCategories } from '../_services/categories.service';

export default function useCategories(): ICategory[] {
  const { data, error } = useSWR('categories', getCategories);

  if (error) {
    hermes.error("Error fetching categories");
  }

  return data || [];
}
