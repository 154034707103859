import React, { useState, useEffect } from 'react';
import { getAsync, postAsync } from '../../_helpers/adapters';
import api from '../../_services/authentication.service';
import '../../css/notification-preferences.css';
import { Container, Input, Table } from 'semantic-ui-react';
import Fuse from 'fuse.js';


const fuseOptions = {
    threshold: 0.0,
    keys: [
        'name',
        'email',
        'phone',
        'newsletterSearch',
        'personalEmailsSearch',
        'whatsappSearch',
        'filterOrganizerSearch'
    ],
}

async function getAllPreferences() {
    let data = await api.getAllNotificationPreferences();
    return data;
}

const AdminNotificationPreferences = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [fuse, setFuse] = useState(null);

    const onSearchChange = (e, { value }) => {
        setSearch(value);
        setFilteredUsers(fuse.search(value).map(result => result.item));
    }

    useEffect(() => {
        const fetchData = async () => {
            let preferences = await getAllPreferences();
            const users = preferences.map(preference => {
                return {
                    _id: preference._id,
                    name: preference.user.fullName,
                    email: preference.user.email,
                    phone: preference.user.phoneNumber,
                    newsletter: preference.newsletter,
                    personalEmails: preference.personalEmails,
                    whatsapp: preference.whatsapp,
                    filterOrganizer: preference.filterOrganizer,
                    newsletterSearch: `newsletter ${preference.newsletter ? 'on' : 'off'}`,
                    personalEmailsSearch: `personal emails ${preference.personalEmails ? 'on' : 'off'}`,
                    whatsappSearch: `whatsapp ${preference.whatsapp ? 'on' : 'off'}`,
                    filterOrganizerSearch: `filter organizer ${preference.filterOrganizer ? 'on' : 'off'}`,
                };
            });
            setUsers(users);
            setFuse(new Fuse(users, fuseOptions));
        }

        fetchData();
    }, []);

    const usersToDisplay = search ? filteredUsers : users;

    return (
        <Container className='notifications'>
            <h1>Notification Preferences</h1>
            <Input
                placeholder='Search...'
                onChange={onSearchChange}
                value={search}
            />
        <Table inverted celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>User</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Phone</Table.HeaderCell>
                    <Table.HeaderCell>Newsletter</Table.HeaderCell>
                    <Table.HeaderCell>Personal Emails</Table.HeaderCell>
                    <Table.HeaderCell>Whatsapp</Table.HeaderCell>
                    <Table.HeaderCell>Filter Organizer</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {usersToDisplay.length? usersToDisplay.map(user => (
                    <Table.Row key={user._id}>
                        <Table.Cell>{user.name}</Table.Cell>
                        <Table.Cell>{user.email}</Table.Cell>
                        <Table.Cell>{user.phone}</Table.Cell>
                        <Table.Cell>{user.newsletter ? 'On' : 'Off'}</Table.Cell>
                        <Table.Cell>{user.personalEmails ? 'On' : 'Off'}</Table.Cell>
                        <Table.Cell>{user.whatsapp ? 'On' : 'Off'}</Table.Cell>
                        <Table.Cell>{user.filterOrganizer ? 'On' : 'Off'}</Table.Cell>
                    </Table.Row>
                    )):
                    <Table.Row>
                        <Table.Cell>No users found</Table.Cell>
                    </Table.Row>
                }
            </Table.Body>
        </Table>
        </Container>
    );
}



export default AdminNotificationPreferences;