import loadingStore from "../stores/loading-store";
import {
  parseLocationURL,
  getSinglePathRoute,
  getDoublePathRoute,
  getTriplePathRoute,
} from "./util";

/**
 * Get the route loader function from a specific path.
 *
 * @param {String} path Path to get the route soon.
 * @return {Function} Loader function
 */
function getRouteLoader(path) {
  // let route = undefined;
  const sp = path.split("/");
  if (sp.length === 2) {
    return getSinglePathRoute(sp);
  }

  if (sp.length === 3) {
    return getDoublePathRoute(sp);
  }

  if (sp.length === 4) {
    return getTriplePathRoute(sp);
  }

  return () => {};
}

/**
 * Loads a route's data from a given location.
 *
 * @param {String} location URL location for the route.
 */
async function loadRoute(location) {
  const parsedLocation = parseLocationURL(location);
  const load = getRouteLoader(parsedLocation.pathname);
  await load(parsedLocation);
}

/**
 * Load the route's data and than go to the page.
 *
 * @param {String} location Location object
 * @param {Object} history React router history object
 */
export async function goTo(location, history) {
  loadingStore.setPBLoading();
  await loadRoute(location);
  history.push(location);
  loadingStore.setPBLoaded();
}

/**
 * Load's the data on current page
 *
 * @param {String} location Location
 */
export async function loadCurrentRoute() {
  loadingStore.setPBLoading();

  let location = window.location.pathname;

  if (window.location.search) {
    location += window.location.search;
  }

  if (window.location.hash) {
    location += window.location.hash;
  }

  await loadRoute(location);
  loadingStore.setPBLoaded();
}
