import React, { useEffect, useState } from "react";
import "../../css/onboarding.css";
import { Progress, Container, Button, Dropdown, Card, Form } from "semantic-ui-react";
import { useHistory } from 'react-router';
import loadingStore from "../../stores/loading-store";
import { hermes } from "../../utilities";
import Bowser from "bowser";

import iosSafariInstructions from '../../images/ios-safari-instructions.gif'
import androidFirefoxInstructions from '../../images/android-firefox-instructions.jpg'
import androidChromeInstructions from '../../images/android-chrome-instructions.jpeg'
import androidOperaInstructions from '../../images/android-opera-instructions.jpeg'

import usePWAInstall from "../../hooks/usePWAInstall";

const parser = Bowser.getParser(window.navigator.userAgent);
const detectedOS = parser.getOSName(true);
const detectedBrowser = parser.getBrowserName(true);

const instructionGifs = {
  android: {
    chrome: {
      image: androidChromeInstructions,
      instructions: [
        'The image below shows you how to install Leuven Event Planner on your phone',
        // 'Instruction 2',
      ],
      
    },
    firefox: {
      image: androidFirefoxInstructions,
      instructions: [
        'The image below shows you how to install Leuven Event Planner on your phone',
        // 'Instruction 2',
      ]
    },
    opera: {
      image: androidOperaInstructions,
      instructions: [
        'The image below shows you how to install Leuven Event Planner on your phone',
        // 'Instruction 2',
      ]
    }
  },
  ios: {
    safari: iosSafariInstructions,
  },
}

function parseAndroidBrowser(browser: string) {
  switch (browser) {
    case 'firefox':
      return 'firefox';
    case 'opera':
      return 'opera';
    default:
      return 'chrome';
  }
}



function parseBrowser(os: string, browser: string) {
  switch (os) {
    case 'ios':
      return 'safari';
    default:
      return parseAndroidBrowser(browser);
  }
}

function parseOS(os: string) {
  switch (os) {
    case 'android':
      return 'android';
    case 'ios':
      return 'ios';
    default:
      return 'android';
  }
}

const androidBrowserOptions = [
  { key: 'chrome', text: 'Chrome', value: 'chrome' },
  { key: 'firefox', text: 'Firefox', value: 'firefox' },
  { key: 'opera', text: 'Opera', value: 'opera' },
]

const iosBrowserOptions = [
  { key: 'safari', text: 'Safari', value: 'safari' },
]

const osOptions = [
  { key: 'android', text: 'Android', value: 'android' },
  { key: 'ios', text: 'iOS', value: 'ios' },
];

const Install = () => {
  const [os, setOs] = useState<"ios"|"android">(parseOS(detectedOS));
  const [browser, setBrowser] = useState<"safari"|"chrome"|"firefox"|"opera">(parseBrowser(detectedOS, detectedBrowser));
  const { installPrompt } = usePWAInstall();

  let history = useHistory();

  useEffect(() => {
    // @ts-ignore navigator.standalone is not typed
    const isOnInstalledPWA = window.navigator.standalone || window.matchMedia("(display-mode: standalone)").matches;
    const isUndesiredOs = detectedOS !== 'android' && detectedOS !== 'ios';

    const isUndesiredBrowser = (
      detectedOS === 'android' && (
        detectedBrowser !== 'chrome' &&
        detectedBrowser !== 'firefox' &&
        detectedBrowser !== 'opera'
      )
    ) || (
        detectedOS === 'ios' && detectedBrowser !== 'safari'
      )

    if (isOnInstalledPWA || isUndesiredOs || isUndesiredBrowser) {
      return history.push('/onboarding/thanks');
    }

    loadingStore.setPageMaskLoaded();
  }, []);


  const onContinue = async () => {
    loadingStore.setLoading();
    try {
      loadingStore.setLoaded();
      history.push('/onboarding/thanks');
    }
    catch (error) {
      hermes.error("Error updating user preferences");
      loadingStore.setLoaded();
    }
  }

  // @ts-ignore
  const instructions = instructionGifs[os][browser] as string | { image: string, instructions: string[] };

  return (
    <>
      <Progress percent={100} className="onboarding-progress-bar" />
      <Container fluid>
        <div className="onboarding-5-container install">
          <div className="onboarding-heading ">Install Leuven Event Planner</div>
          <div className="onboarding-sub-heading">

            <p>
              Install Leuven Event Planner on your phone
            </p>
            <Card>
              <Form>
                {/* <div className="platform-selection"> */}
                <Form.Field>
                  <label>Platform</label>
                  <Dropdown
                    placeholder='Select your OS'
                    search
                    selection
                    value={os}
                    options={
                      osOptions
                    }
                    onChange={(e, { value }) => {
                      setOs(value as "ios" | "android");
                      setBrowser(browser => parseBrowser(value as string, browser))
                    }}
                  />

                </Form.Field>

                <Form.Field>
                  <label>Browser</label>
                  <Dropdown
                    placeholder='Select your browser'
                    search
                    selection
                    value={browser}
                    options={
                      os === 'android' ? androidBrowserOptions :
                        iosBrowserOptions
                    }
                    onChange={(e, { value }) => setBrowser(value as "safari" | "chrome" | "firefox" | "opera")}
                  />
                </Form.Field>
                {/* </div> */}


                {typeof instructions !== 'string' ? (
                  <>
                    {/* <h5 className="instructions-title" >{instructions[0]}</h5>
                      <ul className="instructions">
                        {instructions.slice(1).map((instruction, index) => (
                          <li key={index}>
                            {instruction}
                          </li>
                        ))}
                      </ul> */}
                    {installPrompt !== null ? (
                      <Button className="install-button" onClick={installPrompt || undefined} primary>Install</Button>
                    ) : (
                      <>
                        {/* <h5 className="instructions-title" >{instructions.instructions[0]}</h5> */}
                        <ul className="instructions field" >
                          {instructions.instructions.slice(1).map((instruction, index) => (
                            <li key={index}>
                              {instruction}
                            </li>
                          ))}
                        </ul>
                        <div className="instruction-gif">
                          <img className="install-instructions" src={instructions.image} alt="instruction" />
                        </div>
                      </>
                    )}

                  </>
                ) : (
                  <div className="instruction-gif">
                    <img className="install-instructions" src={instructions} alt="instruction" />
                  </div>
                )}

              </Form>
            </Card>
            <Button primary onClick={onContinue}>
              Continue
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Install;
