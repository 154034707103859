import { createContext, useContext, useState } from "react";
import useSWR from "swr";
import loadingStore from "../../stores/loading-store";
import { hermes } from "../../utilities";
import * as api from "../../_services/invites.service";
import { filterFunctions, sortFunctions, parseInvites, parseInvite } from "./helpers";


interface IAdminInvitesContext {
  invites: IInvite[];
  error: any;
  updateInvite: (invite: IInvite) => Promise<void>;
  approveInvite: (_id: string) => Promise<void>;
  deleteInvite: (_id: string) => Promise<void>;
  sort: string;
  setSort: (sort: string) => void;
  filter: string;
  setFilter: (filter: string) => void;
}


export function AdminInvitesContextProvider({ children }: { children: React.ReactNode }) {
  const { data, error, mutate } = useSWR<IInvite[]>("invites", api.getInvites);
  const [sort, setSort] = useState("createdAt");
  const [filter, setFilter] = useState("all");

  const invites = parseInvites(data || [])
    .filter(filterFunctions[filter])
    .sort(sortFunctions[sort]);


  const updateInvite = async (invite: IInvite) => {
    await mutate(async invites => {
      if (!invites)
        return [];

      try {
        loadingStore.setLoading();
        const updated = await api.updateInvite(invite);
        loadingStore.setLoaded();
        hermes.success("Invite updated");
        return [...invites.map((i) => {
          if (i._id === updated._id)
            return parseInvite(updated);
          return i;
        })]
      } catch (error) {
        loadingStore.setLoaded();
        hermes.error(error);
        return invites;
      }
    }, false);
  };

  const approveInvite = async (_id: string) => {
    await mutate(async invites => {
      if (!invites)
        return [];

      try {
        loadingStore.setLoading();
        const updated = await api.approveInvite(_id);
        hermes.success("Invite approved and sent");
        loadingStore.setLoaded();
        return [...invites.map((i) => {
          if (i._id === _id)
            return parseInvite(updated);

          return i;
        })]
      } catch (e) {
        hermes.error(e);
        loadingStore.setLoaded();
        return invites;
      }
    }, false);
  };

  const deleteInvite = async (_id: string) => {
    await mutate(async invites => {
      if (!invites)
        return [];

      try {
        loadingStore.setLoading();
        await api.deleteInvite(_id);
        loadingStore.setLoaded();
        hermes.success("Invite deleted");
        return [...invites.filter(i => i._id !== _id)];
      } catch (e) {
        hermes.error(e);
        loadingStore.setLoaded();
        return invites;
      }
    }, false);
  };

  return (
    <AdminInvitesContext.Provider value={{
      invites,
      error,
      sort,
      setSort,
      filter,
      setFilter,
      approveInvite,
      updateInvite,
      deleteInvite,
    }}>
      {children}
    </AdminInvitesContext.Provider>
  );
}

export default function useAdminInvites() {
  const context = useContext(AdminInvitesContext);
  if (context === null) {
    throw new Error("useAdminInvites must be used within a AdminInvitesContextProvider");
  }
  return context;
}

const AdminInvitesContext = createContext<IAdminInvitesContext | null>(null);
