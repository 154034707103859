import useSWR from 'swr';
import { getFilters, setFilters } from '../_services/filters.service';

interface useFiltersReturn {
  categories: string[];
  timeFilter: TimeFilterType;
  tabFilter: TabFilterType;
  mutate: () => Promise<IFiltersData | undefined>;
  updateTabFilter: (tabFilter: TabFilterType) => Promise<void>;
  updateTimeFilter: (timeFilter: TimeFilterType) => Promise<void>;
  updateCategoryFilter: (categories: string[]) => Promise<void>;
}

export default function useFilters(): useFiltersReturn {
  const { data, error, mutate } = useSWR<IFiltersData>('filters', getFilters, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  if (error) {
    // Hiding it because it seems to be showing up too often, even though it isn't failing
    // hermes.error("Error fetching filters");
    console.error(error);
  }


  const updateTabFilter = async (tabFilter: IFiltersData['tabFilter']) => {
    const data = await mutate(async filters => ({
      ...filters,
      tabFilter
    }), false);

    await setFilters(data as IFiltersData);
  };


  const updateTimeFilter = async (timeFilter: IFilters['timeFilter']) => {
    const data = await mutate(async filters => {
      // const tabFilter = filters?.tabFilter || 'all'
      // Temporarily make time filters the same on both tabs
      const tabFilter = 'all'

      return {
        ...filters,
        [tabFilter]: {
          ...filters?.[tabFilter],
          timeFilter
        }
      }
    }, false);

    await setFilters(data as IFiltersData);
  }

  const updateCategoryFilter = async (categories: string[]) => {
    const data = await mutate(async filters => {
      const tabFilter = filters?.tabFilter || 'all'

      return {
        ...filters,
        [tabFilter]: {
          ...filters?.[tabFilter],
          categories
        }
      }
    }, false);

    await setFilters(data as IFiltersData);
  }

  const utilityFunctions = {
    mutate,
    updateTabFilter,
    updateTimeFilter,
    updateCategoryFilter
  }

  if (!data) {
    return {
      ...utilityFunctions,
      categories: [],
      timeFilter: 'today',
      tabFilter: 'all'
    };
  }

  if (data.tabFilter === 'subscribed') {
    return {
      ...utilityFunctions,
      categories: data.subscribed?.categories || [],
      timeFilter: data.all?.timeFilter || 'today',
      tabFilter: data.tabFilter,
    };
  }

  return {
    ...utilityFunctions,
    categories: data.all?.categories || [],
    timeFilter: data.all?.timeFilter || 'today',
    tabFilter: "all",
  };
}
