import React, { Fragment, Component } from "react";
import IcpLogo from "../../images/icp-logo.svg";
import { withRouter } from "react-router-dom";
import "../../css/navbar.css";
import { observer, inject } from "mobx-react";
import { Container, Popup } from "semantic-ui-react";
import LinkWithLoader from "../link-with-loader";
import authenticationService from "../../_services/authentication.service";
import authStore from '../../stores/auth-store';
import { RandomLocationContext } from "../../contexts/RandomLocationContext";
import config from "../../_helpers/config";
import usePWAInstall from "../../hooks/usePWAInstall";

// for handling the links that are shown under menu when the screen size below 768 px
const MoreSectionPopupItem = ({ link, title, description, onClick, routerLink, type }) => {

  const {installPrompt, showInstallInstructions} = usePWAInstall();

  if (routerLink) {
    return (
      <LinkWithLoader to={link} onClick={onClick}>
        <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M20 9.999v-2h4v12.001h-3v4l-5.333-4h-7.667v-4h12v-6.001zm-2 4.001h-9.667l-5.333 4v-4h-3v-14.001h18v14.001z" />
          </svg>
        </div>
        <span className="label">
          {title}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
          </svg>
        </span>
        <span className="description">{description}</span>
      </LinkWithLoader>
    )
  }
  else if (type === "logout") {
    return (
      <a href={link} onClick={(e) => {
        e.preventDefault();
        onClick();
        authenticationService.logout();
        authStore.logout();
      }}>
        <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M20 9.999v-2h4v12.001h-3v4l-5.333-4h-7.667v-4h12v-6.001zm-2 4.001h-9.667l-5.333 4v-4h-3v-14.001h18v14.001z" />
          </svg>
        </div>
        <span className="label">
          {title}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
          </svg>
        </span>
        <span className="description">{description}</span>
      </a>
    )

  }
  else if (type === "pwa") {
    if (!installPrompt && !showInstallInstructions) {
      return null;
    }

    return (
      <a href={link} onClick={(e) => {
        e.preventDefault();
        onClick();
        if (installPrompt) {
          installPrompt();
        }
        else if (showInstallInstructions) {
          showInstallInstructions();
        }
      }}>
        <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M20 9.999v-2h4v12.001h-3v4l-5.333-4h-7.667v-4h12v-6.001zm-2 4.001h-9.667l-5.333 4v-4h-3v-14.001h18v14.001z" />
          </svg>
        </div>
        <span className="label">
          {title}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
          </svg>
        </span>
        <span className="description">{description}</span>
      </a>
    )
  }
  else if (type === "subscribedEvents") {
    return (
      <a href="/" onClick={(e) => {

        // subscriptionStore.activeView = "feed"
        // subscriptionStore.menuClickedFrom768px = "feed"
        // subscriptionStore.menuPopupOpen = false

        // check if the current page is not the home page. 
        // If it is not the home page store active view on localstorage so that we can keep the state
        if (window.location.pathname !== "/") {
          window.localStorage.setItem("activeView", "feed");
          window.localStorage.setItem("menuClickedFrom768px", "feed")

        }
        else {
          //  prevent from reloading
          e.preventDefault()
        }

      }} >
        <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M20 9.999v-2h4v12.001h-3v4l-5.333-4h-7.667v-4h12v-6.001zm-2 4.001h-9.667l-5.333 4v-4h-3v-14.001h18v14.001z" />
          </svg>
        </div>
        <span className="label">
          {title}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
          </svg>
        </span>
        <span className="description">{description}</span>
      </a>
    )

  }
  else if (type === "allEvents") {
    return (
      <a href="/" onClick={(e) => {
        // subscriptionStore.activeView = "all"
        // subscriptionStore.menuClickedFrom768px = "all"
        // subscriptionStore.menuPopupOpen = false

        // check if the current page is not the home page. 
        // If it is not the home page store active view on localstorage so that we can keep the state
        if (window.location.pathname !== "/") {
          window.localStorage.setItem("activeView", "all");
          window.localStorage.setItem("menuClickedFrom768px", "all")
        }
        else {
          //  prevent from reloading
          e.preventDefault()
        }

      }} >
        <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M20 9.999v-2h4v12.001h-3v4l-5.333-4h-7.667v-4h12v-6.001zm-2 4.001h-9.667l-5.333 4v-4h-3v-14.001h18v14.001z" />
          </svg>
        </div>
        <span className="label">
          {title}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
          </svg>
        </span>
        <span className="description">{description}</span>
      </a>
    )

  }
  return (
    <a href={link} onClick={onClick} target="_blank" rel="noreferrer">
      <div className="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M20 9.999v-2h4v12.001h-3v4l-5.333-4h-7.667v-4h12v-6.001zm-2 4.001h-9.667l-5.333 4v-4h-3v-14.001h18v14.001z" />
        </svg>
      </div>
      <span className="label">
        {title}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
        </svg>
      </span>
      <span className="description">{description}</span>
    </a>
  )

};

class Navbar extends Component {

  static contextType = RandomLocationContext

  MorePopupMobileItems = [
    {
      title: 'Event Feed',
      description: 'Find the events you care about',
      routerLink: true,
      link: '/',
    },
    /*{
      title: 'Filter Events',
      description: 'Filter your event feed by organizers',
      link: '/subscribe',
      routerLink: true
    },*/
    // {
    //   title: 'Notifications',
    //   description: 'Get notified about upcoming events',
    //   link: '/notifications',
    //   routerLink: true
    // },
    /* {
      title: 'Filtered Events',
       description: "View events hosted by organizers you subscribed to",
       routerLink: false,
       type: 'subscribedEvents'
     }, */ 
    {
      title: 'Settings',
      description: 'Change your email, phone, password and notification preferences',
      link: '/settings',
      routerLink: true
    },
 
    {
      title: 'Links',
      description: 'Contact us, share our app, report a bug and view our walkthrough videos',
      link: '/links',
      routerLink: true
    },
    // {
    //   title: 'Share on Whatsapp',
    //   description: 'Share our app on Whatsapp',
    //   link: `https://wa.me/?text=${encodeURIComponent("Leuven Event Planner ")}` + encodeURIComponent(window.location.href),
    //   routerLink: false
    // },
    {
      title: 'Logout',
      description: 'Logout of Leuven Event Planner',
      type: 'logout',
      routerLink: false
    },
    {
      title: 'Install Leuven Event Planner',
      description: 'Install Leuven Event Planner on your phone',
      type: 'pwa',
      routerLink: false
    },


  ];

  constructor(props) {
    super(props);
    this.state = {
      isProfilePopupOpen: false,
      isMorePopupOpen: false,
      isMorePopupMobileOpen: false,
      isOnLegal: false,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onOpen(option) {
    this.setState({ [option]: true });
    // subscriptionStore.menuPopupOpen = true;
  }

  onClose(option) {
    this.setState({
      [option]: false,
    });
    // subscriptionStore.menuPopupOpen = false;
  }

  componentDidMount() {
    const { location } = this.props;

    if (location.pathname === "/privacy" || location.pathname === "/terms" ) {
      this.state.isOnLegal || this.setState({ isOnLegal: true });
    } else {
      !this.state.isOnLegal || this.setState({ isOnLegal: false });
    }
  }

  componentDidUpdate() {
    const { location } = this.props;

    if (location.pathname === "/privacy" || location.pathname === "/terms") {
      this.state.isOnLegal || this.setState({ isOnLegal: true });
    } else {
      !this.state.isOnLegal || this.setState({ isOnLegal: false });
    }
  }

  
  
  render() {
    const { match, history, authStore, location } = this.props;

    if (!authStore.isLoggedIn && (
      history.location.pathname === "/" || 
      history.location.pathname === "/demo" ||
      history.location.pathname === "/emails" ||
      history.location.pathname === this.context.terms ||
      authStore.isPublicOrgPage
    )) {
      return null;
    } else {
      return (
        <Fragment>
          <div
            className={
              "navbar" + ((this.state.isOnLegal && " legal-nav") || "") + ((authStore.isPublicOrgPage && " public-org-nav")  || "")
            }
          >
            <Container fluid>
              <LinkWithLoader to="/" className="icp-logo">
                <img src={IcpLogo} alt="icpLogo" width="24" height="24" />
                <div className="icp-name icp-name-display">
                Leuven Event Planner
                </div>
              </LinkWithLoader>

              <div className="right">
                {match.path === "/" &&
                  authStore.isLoggedIn &&
                  (!authenticationService?.currentUserValue?.isSMSVerified ||
                    !authenticationService?.currentUserValue?.isEmailVerified ||
                    (config.isOrganizerPageRequired() && !authenticationService?.currentUserValue?.isOrganizersPageCompleted)) ? (
                  <Fragment>
                    <LinkWithLoader
                      to="/"
                      role="button"
                      onClick={(e) => {
                        e.preventDefault();
                        authenticationService.logout();
                        authStore.logout();
                      }}
                    >
                      <span className="name">Log Out</span>
                    </LinkWithLoader>
                  </Fragment>
                ) : authStore.isLoggedIn &&
                  (authenticationService?.currentUserValue?.isSMSVerified ===
                    true ||
                    authenticationService?.currentUserValue?.isEmailVerified ===
                    true) ? (
                  <Fragment>
                    <div className="nav-menu ">
                      
                      {location.pathname !== "/onboarding/thanks" && (
                        <>
                          <LinkWithLoader
                              onClick={() => this.onClose("isProfilePopupOpen")}
                              to={`/`}
                              className="tablet-and-up"
                          >
                            Event Feed
                          </LinkWithLoader><LinkWithLoader
                            onClick={() => this.onClose("isProfilePopupOpen")}
                            to={`/settings`}
                            className="tablet-and-up"
                          >
                            Settings
                          </LinkWithLoader>
                        </>
                      )}
                      
                      {/* check the role of the user to render menus based on the role */}
                      {/* {authenticationService.currentUserValue.role == "Admin" ?
                        <LinkWithLoader
                          onClick={() => this.onClose('isProfilePopupOpen')}
                          to={`/all-event`}
                        >
                          View Events
                        </LinkWithLoader> : null} */}
                      {/* check if the user is admin. If it is admin display view events tab */}
                      {/* {authenticationService.currentUserValue.role == "Admin" ?
                        <LinkWithLoader
                          onClick={() => this.onClose('isProfilePopupOpen')}
                          to={`/organizers`}
                        >
                          View Organizers
                        </LinkWithLoader> : null} */}

                      <LinkWithLoader
                        to="/"
                        role="button"
                        onClick={(e) => {
                          e.preventDefault();
                          authenticationService.logout();
                          authStore.logout();
                        }}
                        className="tablet-and-up"
                      >
                        <span className="name">Log Out</span>
                      </LinkWithLoader>
                      {/* genrate drop down menu when the screen size is < 768 */}
                      <Popup
                        on='click'
                        position='bottom right'
                        basic
                        inverted
                        open={this.state.isMorePopupMobileOpen}
                        onOpen={() => this.onOpen('isMorePopupMobileOpen')}
                        onClose={() => this.onClose('isMorePopupMobileOpen')}
                        className="wrapper-mobile-popup"
                        trigger={
                          <div className="more-menu-button mobile-only">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="menu-icon">
                              <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" />
                            </svg>
                          </div>
                        }
                        content={
                          <div className="mobile-popup">
                            <header>
                              <LinkWithLoader to="/" className="icp-logo">
                                <img className="logo" src={IcpLogo} alt="LEP Logo" />
                                <div className="name">Leuven Event Planner</div>
                              </LinkWithLoader>
                              <div className="close" onClick={() => this.onClose('isMorePopupMobileOpen')}>
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                                </svg>
                              </div>
                            </header>

                            {this.MorePopupMobileItems.map((item, i) => (
                              <MoreSectionPopupItem {...item} onClick={() => this.onClose('isMorePopupMobileOpen')} key={i} />
                            ))}
                          </div>
                        }
                      />
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <LinkWithLoader
                      to="/"
                      role="button"
                      onClick={() => this.onClose("isProfilePopupOpen")}
                    >
                      <span className="name">Home</span>
                    </LinkWithLoader>


                  </Fragment>
                )}
              </div>
            </Container>
          </div>
        </Fragment>
      );
    }
  }
}

export default withRouter(
  inject("authStore", "profileStore")(observer(Navbar))
);