import React, { useState, useRef, useEffect, CSSProperties, Fragment, useMemo } from 'react';
import { Dropdown, Image } from 'semantic-ui-react';
import moment from 'moment';
import { googleCalendarEventUrl } from 'google-calendar-url';
import { outlook } from 'calendar-link';
import useSubscriptions from '../../hooks/useSubscriptions';
import { Link } from 'react-router-dom';
import HintPopup from '../shared/HintPopup';
import useWalkThrough from '../../hooks/useWalkThrough';

interface FeedItemProps {
  event: IEvent;
}

const FeedItem = ({ event }: FeedItemProps) => {

  const {
    cancelled,
    categories,
    endDate,
    link,
    organizer,
    startDate,
    title,
    featured,
  } = event;
  

  const hideEndDay = endDate.format("D M YY") === startDate.format("D M YY");
  const { subscriptions, subscribe, unsubscribe } = useSubscriptions();
  const sub_feed_item_ref = useRef<HTMLDivElement>(null);
  const subscribeRef = useRef<HTMLSpanElement>(null);
  const orgNameRef = useRef<HTMLAnchorElement>(null);
  const [featuredDotStyle, setFeaturedDotStyle] = useState<CSSProperties>({});
  const [orgDotStyte, setOrgDotStyte] = useState<CSSProperties>({});
  const [subscribeDotStyle, setSubscribeDotStyle] = useState<CSSProperties>({});
  const { current, goToNext } = useWalkThrough();

  function handleResize() {
    if (sub_feed_item_ref.current && sub_feed_item_ref.current.clientHeight > 30 && sub_feed_item_ref.current.clientHeight <= 60) {
      if (subscribeRef.current && subscribeRef.current.offsetLeft > 100) {
        setSubscribeDotStyle({});
      } else {
        setSubscribeDotStyle({ opacity: '0' });
      }
    } else {
      setSubscribeDotStyle({});
    }

    if (sub_feed_item_ref.current && sub_feed_item_ref.current.clientHeight > 60) {
      setOrgDotStyte({ opacity: '0' });
      if (subscribeRef.current && subscribeRef.current.offsetLeft < 100) {
        setSubscribeDotStyle({ opacity: '0' });
      }

    } else if (subscribeRef.current && subscribeRef.current.offsetLeft > 100 && sub_feed_item_ref.current && sub_feed_item_ref.current.clientHeight > 30) {
      setOrgDotStyte({ opacity: '0' });
    } else {
      setOrgDotStyte({});
    }


    if (orgNameRef.current && orgNameRef.current.offsetLeft < 100) {
      console.log(orgNameRef?.current?.offsetLeft)
      setFeaturedDotStyle({ opacity: '0' });
    } else {
      setFeaturedDotStyle({});
    }
  }

  useEffect(() => {
    handleResize();
  }, [sub_feed_item_ref.current?.clientHeight]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const addToCalRef = useRef<HTMLSpanElement>(null);

  const AddToCallWrapper = useMemo(() => ({ children }: { children: React.ReactNode }): React.ReactElement => {
    if (featured) {
      return (
        <HintPopup
          title='Add to calendar'
          position='bottom center'
          trigger={children}
          name="add to calendar"
          action='Add to calendar'
          closeOnClickAway
          elementRef={addToCalRef}
          onClick={() => {
            addToCalRef.current?.click();
          }}
        >
          {children}
        </HintPopup>
      )
    }

    return <>{children}</>;
  }, [featured]);


  if (!organizer) {
    return null;
  }

  const pad = (num: number) => {
    // Ensure date values are double digits
    return num < 10 ? '0' + num : num;
  };

  const formatDate = (date: moment.Moment) => {
    let dateTime = date.toDate();
    return [
      dateTime.getUTCFullYear(),
      pad(dateTime.getUTCMonth() + 1),
      pad(dateTime.getUTCDate()),
      'T',
      pad(dateTime.getUTCHours()),
      pad(dateTime.getUTCMinutes()) + '00Z',
    ].join('');
  };

  function downloadFile(filePath: string) {
    var link = document.createElement('a');
    link.href = filePath;
    link.download = `summary-of-event`;
    link.click();
  }

  // add to apple calendar
  const addToAppleCalendar = () => {
    // Create the .ics URL
    let url = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'DTSTART:' + formatDate(startDate),
      'DTEND:' + formatDate(endDate),
      'SUMMARY:' + title,
      'DESCRIPTION:Leuven Event Planner Reminder',
      'LOCATION:' + link,
      'BEGIN:VALARM',
      'TRIGGER:-PT15M',
      'REPEAT:1',
      'DURATION:PT15M',
      'ACTION:DISPLAY',
      'DESCRIPTION:Reminder',
      'END:VALARM',
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n');

    let blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });

    if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {

      interface INavigator extends Navigator {
        msSaveBlob: (blob: Blob, filename?: string) => boolean;
      }

      (window.navigator as INavigator)?.msSaveBlob(blob, `summary-of-${title}.ics`);
    } else {
      // Open/Save link in Modern Browsers
      // window.open(encodeURI('data:text/calendar;charset=utf8,' + url));
      downloadFile(encodeURI('data:text/calendar;charset=utf8,' + url));
    }
  };


  const eventToAdd = {
    start: startDate,
    end: endDate,
    title: title,
    // details: 'Leuven Event Planner',
    location: link,
  };


  const addToGoogleCalendarURL = googleCalendarEventUrl({
    start: formatDate(startDate),
    end:
      formatDate(endDate) > formatDate(startDate)
        ? formatDate(endDate)
        : formatDate(startDate),
    title: title,
    details: 'Leuven Event Planner',
    location: link,
  });

  const now = moment();

  const isSubscribed = subscriptions.map(subscription => subscription.organizer?._id).includes(organizer._id);
  const handleSubscription = isSubscribed ? () => unsubscribe(organizer._id) : () => subscribe(organizer._id);


  const croppedImage = event.organizer?.image ? event.organizer?.image.replace('/upload/', '/upload/c_fill,h_256/') : '';



  return (
    <div className={`post-section ${featured ? 'featured' : ''}`}>
      {organizer && (
        <>
          <div className="feed-item-post">
            {/* image */}
            <div className="img_picture">
              {organizer.image && (
                <Image
                  src={croppedImage}
                  alt={organizer.name}
                  size="tiny"
                  circular
                />
              )}
            </div>
            <div style={{ display: 'flex' }}>
              <div className="feed-date">
                <div className="date">
                  <span className="month">
                    {now.isAfter(startDate) ? now.format('MMM') : startDate.format('MMM')}
                  </span>
                  <span className="day">
                    {now.isAfter(startDate) ? now.format('DD') : startDate.format('DD')}
                  </span>
                </div>
              </div>
              {/* organizer title */}
              <div className="feed_item_control">
                <div className="feed_item_title ">
                  <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className={`feed_event_title feed_hover_title ${cancelled ? 'cancel' : ''
                      }`}
                  >
                    {title}
                  </a>
                </div>
                <div className="sub_feed_item" ref={sub_feed_item_ref}>
                  {featured && (
                    <>
                      <span className="feed_atag white">
                        Featured
                      </span>

                      <div className="sub_feed_item_dot" style={featuredDotStyle}>·</div>{' '}
                    </>
                  )}
                  <div className="feed_atag_dot_wrapper">
                    <Link
                      ref={orgNameRef}
                      to={`${organizer.name.split(' ').join('.')}`}
                      className="feed_atag feed_hover"
                    >
                      {organizer.name}
                    </Link>{' '}
                    <div className="sub_feed_item_dot" style={orgDotStyte}>·</div>{' '}
                  </div>

                  <div className="feed_atag_dot_wrapper">
                    <Dropdown
                      icon={null}
                      trigger={
                        <AddToCallWrapper>
                          <span className="feed_atag_ feed_hover" ref={addToCalRef}>
                            Add to calendar
                          </span>
                        </AddToCallWrapper>
                      }
                      onOpen={() => {
                        if (current === 'add to calendar') {
                          goToNext();
                        }
                      }}  
                      direction="right"
                      floating
                    >
                      <Dropdown.Menu
                        className="add_to_cal_drop"
                      // style={{  }}
                      >
                        {/* <div className="sub_feed_item_dot">·</div> */}
                        <Dropdown.Item
                          onClick={addToAppleCalendar}
                        // href={ics(eventToAdd)}
                        // target="_parent"
                        >
                          Apple
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="a"
                          href={addToGoogleCalendarURL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Google
                        </Dropdown.Item>

                        <Dropdown.Item
                          as="a"
                          href={outlook(eventToAdd)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Outlook
                        </Dropdown.Item>

                      </Dropdown.Menu>
                    </Dropdown>


                    <div className="sub_feed_item_dot" style={subscribeDotStyle}>·</div>{' '}
                  </div>


                  <span
                    ref={subscribeRef}
                    onClick={handleSubscription}
                    className="feed_atag_ feed_hover"
                  >
                    {isSubscribed ? 'Unsubscribe' : 'Subscribe'}
                  </span>

                </div>
                <div className="sub_feed_item">
                  {cancelled ? (
                    <span className='sub_feed_item_date'>
                      Cancelled
                    </span>
                  ) : (
                    <span className='sub_feed_item_date'>
                      {moment(startDate).format('ddd Do HH:mm')}{' '}

                      {endDate ? '-' : ''}{' '}
                      {hideEndDay ?
                        moment(endDate).format('HH:mm')
                        :
                        moment(endDate).format('ddd Do HH:mm')

                      }{' '}
                    </span>
                  )}

                  {categories && !!categories.length && (
                    <div className="feed_item_categories">
                      {categories.map((category, index) => (
                        <Fragment key={index}>
                          <span className="feed_item_category feed_hover">
                            {category.name}
                          </span>
                          <div className="sub_feed_item_dot">·</div>
                        </Fragment>
                      ))}

                    </div>
                  )}
                </div>

              </div>
            </div>
            <div className="img_picture_mobile">
              <img
                src={croppedImage}
                alt={organizer.name}
                className="image"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FeedItem;
