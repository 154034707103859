import React, { useState, useEffect } from 'react';
import { getEventsByOrganizer } from '../../_services/event.service';
import { trackOrganizerPageViewed, trackSubscribeHit } from '../../_services/analytics.service';
import '../../css/meetups.css';
import moment from 'moment';
import LinkWithLoader from '../../components/link-with-loader';
import nanobar from '../../components/Nanobar.js';
import { useHistory } from 'react-router';
import loadingStore from '../../stores/loading-store';
import { Helmet } from 'react-helmet';
import authStore from '../../stores/auth-store';
import LandingPageNav from '../../components/shared/LandingPageNav';
import authenticationService from '../../_services/authentication.service';
import hermes from '../../utilities/hermes'
import subscriptionStore from '../../stores/subscription-store';


const ListEventsByOrganizers = ({ match }) => {
  const {
    params: { name },
  } = match;
  let history = useHistory();
  const [events, setEvents] = useState([]);
  const [organizer, setOrganizer] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const isLoggedIn = authenticationService.currentUserValue;
  const showAllEvents = isLoggedIn || organizer?.showAllEvents
  const showAllEventInfo = isLoggedIn ||(organizer?.showAllEventInfo && showAllEvents)

  useEffect(() => {
    const onPageLoad = async () => {
      authStore.setIsPublicOrgPage(true);
      loadingStore.setPageMaskLoading();

      nanobar.go(20);
      const viewedAt = new Date();

      // Similar to MongoDB ObjectId
      function SessionId () {
        const timestamp = (viewedAt.getTime() / 1000 | 0).toString(16);
        const suffix = 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => (Math.random() * 16 | 0).toString(16)).toLowerCase();
        return `${timestamp}${suffix}`;
      }

      const sessionId = sessionStorage.getItem('sessionId') || SessionId();
      const sessionIdLS = localStorage.getItem('sessionId') || SessionId();

      try {
        const res = await getEventsByOrganizer(name);
        if (isLoggedIn) {
          console.log('isLoggedIn');
          
          const subscriptions = await subscriptionStore.getSubscribedOrganizers();
          console.log(subscriptions)
          setIsSubscribed(subscriptions.some(({ key }) => key === res.organizer._id));
        }
        setEvents(res.events);
        setOrganizer(res.organizer);

        trackOrganizerPageViewed({id: res.organizer._id, viewedAt, sessionId, sessionIdLS});
        sessionStorage.setItem('sessionId', sessionId);
        localStorage.setItem('sessionId', sessionIdLS);
        nanobar.go(100);
        loadingStore.setPageMaskLoaded();
      } catch (error) {
        console.log(error);

        loadingStore.setPageMaskLoaded();
        nanobar.go(100);
        history.push('/');
      }
      
      
    }

    onPageLoad();

    return () => {
      // Set organization page to false
      authStore.setIsPublicOrgPage(false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onClickSubscribe = async (e) => {
    if (isLoggedIn) {
      if (isSubscribed) {
        await authenticationService.subscribeOrganizers([], [organizer._id]);
        hermes.success("You are unsubscribed");
        setIsSubscribed(false);
        return;
      }

      await authenticationService.subscribeOrganizers([organizer._id], []);
      hermes.success("You are subscribed");
      setIsSubscribed(true);
      return
    } else {
      trackSubscribeHit({id: organizer._id, hitSubscribeAt: new Date(), sessionId: sessionStorage.getItem('sessionId') });
      history.push("/join")
    }
  }


  // If organizer is loaded, setup helmet's og tags for SEO
  let helmet = null;

  if (organizer) {
    const { name, description, imageUrl } = organizer;
    helmet = (
      <Helmet
        // titleTemplate='%s | Leuven Event Planner'
      >
        {/* <title>{name}</title> */}
        <meta property="og:title" content={name} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
      </Helmet>
    );
  }

  return (
    <div className="meetups">
      {helmet}
      {!isLoggedIn && (<LandingPageNav 
          joinColor={"#1f364d"}
          onJoinClick={() => history.push(`/?action=join`)}
          onLoginClick={() => history.push(`/?action=login`)}
      />)}
      <div className="meetups-index">
        <div id="" className="section-header ember-view">
          <h1 className="section-header__title">{organizer?.name}</h1>

          <p className="section-header__description">
            {organizer?.description}
          </p>

          <div className="section-header__actions">
            <a
              href={organizer?.url}
              target="_blank"
              rel="noreferrer"
              id="ember45"
              className="
              section-header__action section-header__action--secondary
              ember-view" 
            >
              <label className="section-header__action-label">
                Website
              </label>{' '}
            </a>
            <span
              onClick={onClickSubscribe}
              id="ember46"
              className="
                  section-header__action section-header__action--primary
                  ember-view
                "
            >
              <label className="section-header__action-label">{isSubscribed ? "Unsubscribe" : "Subscribe"}</label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                id="ember47"
                className="section-header__action-icon ember-view"
              >
                <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path>
              </svg>
            </span>
          </div>
        </div>
        {/*  */}
        <div className="meetups-index__content section-body">
          <div className="meetups-index__list">
            {/* start */}
            {events && (
                showAllEvents ? events : events.slice(0,2)
              ).map((event) => {
                const checkStartDate = moment(event.startDate).format(
                  'dddd MMMM Do' + (showAllEventInfo ? ' HH:mm' : '')
                );

                const startDay = moment(event.startDate).format('D');
                const startMonth = moment(event.startDate).format('MMM');
                return (
                  <div className={`meetups-index__meetup ${!showAllEvents ? "blur" : ""}`}>
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a
                      target={showAllEventInfo ? "_blank" : undefined}
                      rel={showAllEventInfo ? "noreferrer" : undefined}
                      className="meetups-index__meetup-link"
                      href={showAllEventInfo ? event.link : `/join`}
                    >
                      <div className="meetups-index__date-icon">
                        <span className="meetups-index__month"> {startMonth} </span>
                        <span className="meetups-index__day"> {startDay} </span>
                      </div>
                      <div className="meetups-index__meetup-text">
                        <h3 className="meetups-index__meetup-name">
                          {event.title}
                        </h3>
                        <footer className="meetups-index__meetup-footer">
                          <div
                            id="ember49"
                            className="user-link meetups-index__user-link ember-view"
                          >
                            <div className="user-link__link">
                              <picture
                                id="ember50"
                                className="user-avatar ember-view user-link__avatar"
                              >
                                <source
                                  srcset={cropImage(event.organizer.image)}
                                  type="image/webp"
                                />
                                <source
                                  srcset={cropImage(event.organizer.image)}
                                  type="image/png"
                                />
                                <source
                                  srcset={cropImage(event.organizer.image)}
                                  type="image/jpeg"
                                />

                                <img src="./Meetups for Indie Hackers_files/nonexistent-image.png" alt="" />
                              </picture>

                              <span className="user-link__name">
                                {' '}
                                {event.organizer.name}{' '}
                              </span>
                            </div>
                          </div>
                          <span className="meetups-index__separator">·</span>
                          <span className="meetups-index__meetup-date">
                            {checkStartDate}{' '}
                          </span>
                          {/* <span className="meetups-index__separator">·</span>
                          <span className="meetups-index__meetup-location">
                            Online {'&'} Onsite
                          </span> */}
                        </footer>
                      </div>
                    </a>
                  </div>
                );
              })}
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};


const cropImage = (image) => {
  return (image || '').replace('/upload/', '/upload/c_fill,h_256/');
}


export default ListEventsByOrganizers;
