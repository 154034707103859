import authenticationService from "../_services/authentication.service";
import config from "./config";

// Adds Authorization header to requests that go to event-planner server
export async function jwtInterceptor(url, requestOptions) {
  const user = authenticationService.currentUserValue;
  const token = localStorage.getItem("currentUserToken");
  const isisLoggedIn = user && token;
  const isApiUrl = url.startsWith(config.apiUrl);
  if (isisLoggedIn && isApiUrl) {
    requestOptions = {
      ...requestOptions,
      headers: { ...requestOptions.headers, Authorization: `Bearer ${token}` },
    };
  }
  return requestOptions;
}
