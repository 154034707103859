import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { inject } from "mobx-react";
import {
  Container,
  Header,
  Segment,
  Form,
  Input,
  Button,
} from "semantic-ui-react";
import "../../css/reference.css";
import { hermes } from "../../utilities";
import config from "../../_helpers/config";
import { errors, success } from "../../messages";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyingToken: false,
      password: "",
      confirmpassword: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {}

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // Handles password change requests, checks password length and calls change password api endpoint
  async handleSubmit(e) {
    e.preventDefault();

    if (this.state.password.length > 25 || this.state.password.length < 8) {
      hermes.error(errors.PASSWORD_TOO_SHORT);
      return;
    }

    if (this.state.password === this.state.confirmPassword) {
      const token = this.props.match.params.token;
      let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          password: this.state.password,
        }),
      };
      fetch(`${config.apiUrl}/users/change-password`, requestOptions).then(
        (res) => {
          if (res.ok) {
            hermes.success(success.PASSWORD_DID_RESET);
            this.props.history.push("/");
          } else {
            hermes.error(errors.FAILED_SEND_PASSWORD_EMAIL);
          }
        },
        (err) => {
          hermes.error(err);
        }
      );
    } else {
      hermes.error(errors.PASSWORDS_DONT_MATCH);
    }
  }

  render() {
    return (
      <Container
        fluid
        className="form-template"
        style={{
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <Fragment>
          <Header inverted>Reset Password</Header>
          <Segment>
            <Form onSubmit={this.handleSubmit} autoComplete="off">
              <Form.Field>
                <label>New Password</label>
                <Input
                  value={this.state.password}
                  placeholder="Must be at least 8 characters"
                  type="password"
                  name="password"
                  onChange={this.handleChange}
                  required
                />
              </Form.Field>

              <Form.Field>
                <label>Confirm New Password</label>
                <Input
                  placeholder="Confirm your new password"
                  value={this.state.confirmPassword}
                  type="password"
                  name="confirmPassword"
                  onChange={this.handleChange}
                  required
                />
              </Form.Field>
              <Button primary type="submit">
                Save
              </Button>
            </Form>
          </Segment>
        </Fragment>
      </Container>
    );
  }
}

export default withRouter(inject("profileStore", "authStore")(ResetPassword));
