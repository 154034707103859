import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { errors, success } from "../../messages";
import SignupForm from "../../components/forms/signup-form";
import ForgottenPassword from "../../components/forms/forgotten-password";
import LoginForm from "../../components/forms/login-form";
import hermes from "../../utilities/hermes";
import authenticationService from "../../_services/authentication.service";
import { Button } from "semantic-ui-react";
import "./landing-page.css";
import ReactGA from 'react-ga';

const backgroundImages = [];

const ToggleComponent = ({ text, toggleForm, buttonContent }) => {
  return (
    <p className="toggler">
      {text}
      <a onClick={toggleForm}>{buttonContent}</a>
    </p>
  );
};

class Slide1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socialIconSize: 60,
      bgIndex: 0,
      login: false,
      signup: false,
      isMember: true,
      forgottenPassword: false,
      apply: false,
    };

    this.handleSignup = this.handleSignup.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.sendResetEmail = this.sendResetEmail.bind(this);
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  /**
   *  Register user
   *
   * @param  data -- user data
   *
   */
  async handleSignup(data) {
    const { authStore } = this.props;
    ReactGA.event({
      category: "Sign Up",
      action: "User pressed Sign up button",
    });
    try {
      await authStore.signUp({ ...data, signUpPage: "2" }).then(
        (response) => {
          if (response) {
            hermes.success(success.REGISTERED_SUCCESSFULLY);
            if (!response?.message) {
              this.props.history.push("/onboarding/sms");
              // window.location.reload(true);
            } else {
              hermes.error(response.message);
            }
          }
        },
        (err) => {
          if (err === "TypeError: Failed to fetch") {
            hermes.error("TypeError: Failed to fetch");
          } else {
            hermes.error(err);
          }
        }
      );
    } catch (e) {
      hermes.error(e);
    }
  }

  /**
   *  Logs in user on succesful response,otherwise will throw an error
   *
   * @param  data -- login information like username and password
   *
   */
  async handleLogin(data) {
    const { authStore } = this.props;
    ReactGA.event({
      category: "Log in",
      action: "User pressed Login button",
    });
    try {
      const response = await authStore.loginUser(data);

      if (response) {
        authStore.syncLoggedInStatus(true);
        // window.location.reload(true);
      }
    } catch (e) {
      if (e === "TypeError: Failed to fetch") {
        hermes.error("TypeError: Failed to fetch");
      } else {
        hermes.error(e);
      }
    }
  }

  componentDidMount() {
    this.setState({
      bgInterval: setInterval(this.updateBgIndex, 300000),
    });

    const { authStore } = this.props;

    // check if the current user is looged in or not. if the user did not verify, it will show toast
    if (authenticationService.currentUser && authStore.isLoggedIn) {
    } else if (
      authenticationService.currentUser &&
      (authenticationService.currentUser.isEmailVerified === false ||
        authenticationService.currentUser.isSMSVerified === false)
    ) {
      hermes.success(success.COMPLETE_SIGNUP);
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.bgInterval);
  }

  updateBgIndex = () => {
    this.setState({
      bgIndex: (this.state.bgIndex + 1) % backgroundImages.length,
    });
  };

  /**
   *  sends reset email for forgot password
   */
  async sendResetEmail(e) {
    e.preventDefault();
    const email = e.target.querySelector("input").value;

    try {
      authenticationService.passwordResetInfo(email).then(
        (response) => {
          this.setState({
            login: true,
            signup: false,
            forgottenPassword: false,
          });
          hermes.success(success.SENT_PASSWORD_RESET_EMAIL);
        },
        (err) => {
          hermes.error(err);
        }
      );
    } catch (e) {
      hermes.error(errors.FAILED_SEND_PASSWORD_EMAIL);
    }
    return true;
  }

  render() {
    const { isMember, bgIndex } = this.state;
    const {
      authStore: { inProgress },
      className,
      login,
      signup,
      forgottenPassword,
      apply,
      handleToggle,
    } = this.props;

    console.log(this.props);

    let view;
    if (login) {
      view = (
        <Fragment>
          <LoginForm handleSubmit={this.handleLogin} inProgress={inProgress} />
          <ToggleComponent
            text="Not a member?"
            buttonContent="Sign Up >>"
            toggleForm={handleToggle("apply")}
          />
          <ToggleComponent
            buttonContent="Forgot password?"
            toggleForm={handleToggle("forgottenPassword")}
          />
        </Fragment>
      );
    } else if (signup) {
      view = (
        <Fragment>
          <SignupForm
            submitButtonText="Sign Up"
            handleSubmit={this.handleSignup}
          />
          {!isMember && (
            <div
              style={{
                color: "red",
                textAlign: "center",
                margin: "4px 0 10px",
              }}
            >
              Use your Facebook name as your name.
            </div>
          )}
          <ToggleComponent
            text="Already have an account?"
            buttonContent="Log In >>"
            toggleForm={handleToggle("login")}
          />
          <ToggleComponent
            text="Not a member?"
            buttonContent="Sign Up >>"
            toggleForm={handleToggle("apply")}
          />
        </Fragment>
      );
    } else if (forgottenPassword) {
      view = (
        <Fragment>
          <ForgottenPassword sendResetEmail={this.sendResetEmail} />

          <ToggleComponent
            text="Already have an account?"
            buttonContent="Log In >>"
            toggleForm={handleToggle("login")}
          />
        </Fragment>
      );
    } else if (apply) {
      view = (
        <Fragment>
          <SignupForm
            submitButtonText="Sign Up"
            handleSubmit={this.handleSignup}
          />
          <ToggleComponent
            text="Already have an account?"
            buttonContent="Log In >>"
            toggleForm={handleToggle("login")}
          />
        </Fragment>
      );
    } else {
      view = (
        <Fragment>
          <div className="form-toggler">
            <Button onClick={handleToggle("apply")} primary>
              Sign Up
            </Button>

            <Button
              onClick={handleToggle("login")}
              className="landing-secondary"
            >
              Log In
            </Button>
          </div>
        </Fragment>
      );
    }

    return (
      <div className={className + " landing-page-container"}>
        <div
          className="landing-page-bg"
          style={{ backgroundImage: `url(${backgroundImages[bgIndex]})` }}
        />
        <div className="landing-page">
          <div className="landing-page-wrapper">
            <div className="tagline-container">
              <h2>
                All Leuven's events in a personalised feed
                <br />
              </h2>
            </div>

            <div className="width-limit-wrapper">{view}</div>
          </div>
          <div
        onClick={this.props.handleSlideControl(0)}
        className="scroll-hint scroll-hint-4"
      >
        What is Leuven Event Planner?{' '}
      </div>
        </div>
      </div>
    );
  }
}

export default withRouter(inject("authStore")(observer(Slide1)));
