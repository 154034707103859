import LandingPageNav from "../../components/shared/LandingPageNav";
import { useHistory } from "react-router-dom";
import config from "../../_helpers/config";
import styles from '../../css/Emails.module.css';
import React from "react";
import authenticationService from "../../_services/authentication.service";

export default function Emails() {

  const history = useHistory();
  const iframeRef = React.createRef();
  
  return (
    <>
    {!authenticationService.currentUserValue && (
      <LandingPageNav
        hiddeButtons={false}
        joinColor={"#1f364d"}
        onJoinClick={() => history.push(`/?action=join`)}
        onLoginClick={() => history.push(`/?action=login`)}
      />
    )}
    <div className={styles.container}>
      <div className={styles.limiter}>
        <iframe
          ref={iframeRef}
          src={config.emailsForm}
          title="Form"
          width="100%"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>      
      </div>
    </div>
   </>
  );
}