import { getAsync, postAsync } from "../_helpers/adapters";
import config from "../_helpers/config";


export const getCategories = async (): Promise<ICategory[]> => {
    try {
        const res = await getAsync(`${config.apiUrl}/categories/`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const createCategory = async (name: string) => {
    try {
        const res = await postAsync(`${config.apiUrl}/categories/`, { name });
        return res;
    } catch (err) {
        throw err;
    }
}