export const errors = {

  // Settings 

  // When passwords do not match when you change their password in settings
  PASSWORDS_DONT_MATCH: "Your passwords do not match",

  // When a user does not specify their current password to change their settings
  CURRENT_PASSWORD_REQUIRED: "Enter your password below to save any changes",

  // When a user enters a password that is too short
  PASSWORD_TOO_SHORT: "Your password is too short, enter one with more than 8 characters",

  // When the user has not verified their phone number 
  PHONE_VERIFICATION_REQUIRED: "Please verify your phone number",

  // When the verification code does not have 5 digits
  INVALID_VERIFICATION_CODE_LENGTH: "Please enter a verification code with 5 digits",

  // Forgot password

  // When reset password link fails
  FAILED_SEND_PASSWORD_EMAIL: "Your password reset link has failed",

  // Bug report

  // When the browser info is missing in the bug report section
  MISSING_BROWSER_INFO: "A browser name and version is required",

  // When the user does not enter a description in the bug report section
  MISSING_BUG_DESCRIPTION: "A description is required",

  // When the user does not select an organizer but tries to filter by organizer
  NO_ORGANIZER_SELECTED: "Select an organizer before saving",
};

export const success = {
  // When profile is updated
 // #QUESTION #WARNING Why is this here when we only have settings and not a profile
  PROFILE_UPDATED: "Your profile is successfully updated",

  // When settings is updated 
  SETTINGS_UPDATED: "Your settings are successfully updated",

  // When asking a user to complete signup the signup process
  COMPLETE_SIGNUP: "Please complete the signup process",

  // When a password reset email is sent
  SENT_PASSWORD_RESET_EMAIL: "We sent you an email to reset your password",

  // When a password is updated
  PASSWORD_DID_RESET: "Your new password is successfully saved",

  // When the user verifies their phone number
  PHONE_VERIFIED: "You successfully verified your phone number",

  // Verification code was successfully sent to a phone number or email
  VERIFICATION_CODE_SMS_SENT: "We sent a verification code to your phone",
  VERIFICATION_CODE_EMAIL_SENT: "We sent a verification code to your email",

  // When email is verified
  EMAIL_VERIFIED: "Thank you for verifying your email",

  // Registered successfully
  REGISTERED_SUCCESSFULLY: "You registered successfully",

  //Subscribed successfully
  SUBSCRIPTIONS_SAVED: "Your preferences are saved",

  // Bug report submitted successfully
  BUG_REPORT_SUBMITTED: "You successfully reported a bug",

  // Bug report deleted successfully
  BUG_REPORT_DELETED: "You successfully deleted a bug report",

  // Bug report updated successfully
  BUG_REPORT_UPDATED: "You successfully updated a bug report",
};
