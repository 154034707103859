import React from 'react';
import gif5 from '../../images/lep-gif-5.gif';

const Slide4 = ({ className, handleSlideControl }) => (
  <>
    <div className={className + ' s4'}>
      <div className="image-centered s4-wrapper">
        <img src={gif5} alt="demo"/>
      </div>
      <div className="s4-wrapper">
        <div className="centralize">
          <h1>Sign up. It's free. Get all Leuven's events in one personalised feed.</h1>
          <p>
          Use Leuven Event Planner once a week to check all Leuven's events. We add 300+ events per week from 150+ organisations. Subscribe to organisations like Ekonomika, VRG, VTK, Industria and add events to your calendar.
        
          </p>
        </div>
      </div>
      <div
        onClick={handleSlideControl(1)}
        className="scroll-hint scroll-hint-4"
      >
        Sign Up{' '}
      </div>

    </div>
  </>
);

export default Slide4;
