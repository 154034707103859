import useSWR from "swr";
import { getAsync } from "../../_helpers/adapters";
import config from "../../_helpers/config";
import { setPersonLanguage } from "../../_services/admin.service";

const fetcher = (url: string) => getAsync(url);

interface PersonLanguage {
  _id: string;
  name: string;
  language: "en" | "nl";
}

interface useAdminLanguagesQuery {
  [key: string]: any;
  filterDeliveredScouter: boolean;
}

export default function useAdminLanguages(query: useAdminLanguagesQuery) {
  const url = `${config.apiUrl}/admin/languages?${new URLSearchParams(query)}`;
  const count_url = `${config.apiUrl}/admin/languages/count?filterDeliveredScouter=${query.filterDeliveredScouter}`
  const { data, error, mutate } = useSWR<PersonLanguage[]>(url, fetcher);
  const { data: count } = useSWR<number>(count_url, fetcher);

  const setLanguage = (_id: string, language: "en" | "nl") => { mutate(data => {
      setPersonLanguage({_id, language});
      return data ? data.map(
      (person) => (person._id === _id ? { ...person, language } : person)
    ) : data
    }, false)
  }

  return {
    data,
    count: count || 0,
    error,
    mutate,
    setLanguage,
    isLoading: !data && !error
  };
}