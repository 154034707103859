import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import ReactGA from 'react-ga';
import { REACT_APP_GA, rootId } from '../src/constants';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactGA.initialize(process.env.REACT_APP_GA);

ReactDOM.render(<App />, document.getElementById(rootId));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({ onUpdate: (registration) => {
  console.log("onUpdate", registration);
  registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  registration.waiting.addEventListener('statechange', (event) => {
    if (event.target.state === 'activated') {
      window.location.reload();
    }
  });
}});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

