import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "semantic-ui-react";
import LandingPageNav from '../../components/shared/LandingPageNav';
import IcpLogo from "../../images/icp-logo.svg";

const PREVIOUS = 'previous';
const NEXT = 'next';

export const HORIZONTAL = 'horizontal';
export const VERTICAL = 'vertical';

const DEFAULT_CLASSNAMES = {
  previousButton: 'previousButton',
  nextButton: 'nextButton',
  buttonDisabled: 'disabled',
  track: 'track',
  slide: 'slide',
  hidden: 'hidden',
  previous: 'previous',
  current: 'current',
  next: 'next',
  animateIn: 'animateIn',
  animateOut: 'animateOut',
};
const DEFAULT_DURATION = 350;
const DELAY = 400;

const buttonColors = ['#00cf35', '#001935', '#001935'];

class Slider extends React.PureComponent {
  constructor(props) {
    super(props);
    const { slideIndex = 0, direction = HORIZONTAL } = this.props;
    this.state = {
      currentSlideIndex: slideIndex,
      animating: false,
      animationOffset: 1,
    };
    this.disabled = false;
    this.slideCount = React.Children.count(this.props.children);
    this.direction = VERTICAL;
    this.swipeProperty = direction === HORIZONTAL ? 'left' : 'top';
    this.swipeEventProperty = direction === HORIZONTAL ? 'clientX' : 'clientY';
    this.animating = false;
  }

  componentWillUnmount() {
    if (this.animationTimerId) {
      clearTimeout(this.animationTimerId);
    }
    if (this.disabledTimerId) {
      clearTimeout(this.disabledTimerId);
    }
  }

  componentWillReceiveProps(props) {
    this.slideCount = React.Children.count(props.children);
    if (this.state.currentSlideIndex >= this.slideCount) {
      this.setState({ currentSlideIndex: 0 });
    }
  }

  componentDidMount() {
    this.disabled = false;

    const { history, location }= this.props;

    const params = new URLSearchParams(location.search);
    const action = params.get("action");

    if (action === "login") {
      this.handleNavButtonClick("login")();
    } else if (action === "join") {
      this.handleNavButtonClick("apply")();
    } else if (action === "forgot-password") {
      this.handleNavButtonClick("forgottenPassword")();
    }

    history.replace({
      search: "",
    });

  }

  onAnimationEnd = () => {
    this.animating = false;
    this.setState({
      currentSlideIndex: this.nextSlideIndex,
      animating: false,
      animation: undefined,
      animationOffset: 1,
    });
  };

  onDisabledEnd = () => {
    this.disabled = false;
  };

  isDisabled = () =>
    this.slideCount < 2 ||
    this.state.animating ||
    this.animating ||
    this.disabled;

  canGoPrevious = () => true || this.state.currentSlideIndex > 0;

  canGoNext = () => true || this.state.currentSlideIndex < this.slideCount - 1;

  handleSlideControl = (index) => () => {
    const { currentSlideIndex } = this.state;
    if (index === 0 && currentSlideIndex === this.slideCount - 1) {
      this.next();
    } else if (index === this.slideCount + 1 && currentSlideIndex === 0) {
      this.previous();
    } else if (index > currentSlideIndex) {
      this.goTo(index, NEXT, index - currentSlideIndex);
    } else if (index < currentSlideIndex) {
      this.goTo(index, PREVIOUS, currentSlideIndex - index);
    }
  };

  goTo = (index, animation, animationOffset = 1) => {
    if (this.isDisabled()) return;
    this.nextSlideIndex = index;
    this.animating = false;
    this.disabled = true;
    this.setState({ animating: false, animation, animationOffset });
    const timeout = DEFAULT_DURATION;
    const disableTimout = DELAY + DEFAULT_DURATION;
    this.animationTimerId = setTimeout(this.onAnimationEnd, timeout);
    this.disabledTimerId = setTimeout(this.onDisabledEnd, disableTimout);
  };

  previous = () => {
    if (!this.canGoPrevious()) return;
    const nextSlideIndex =
      this.state.currentSlideIndex >= 1
        ? this.state.currentSlideIndex - 1
        : this.slideCount - 1;
    this.goTo(nextSlideIndex, PREVIOUS);
  };

  next = () => {
    if (!this.canGoNext()) return;
    const nextSlideIndex = (this.state.currentSlideIndex + 1) % this.slideCount;
    this.goTo(nextSlideIndex, NEXT);
  };

  getSlideClass = (index) => {
    const { currentSlideIndex, animation, animationOffset } = this.state;
    const classNames = this.getClassNames();
    const lastSlideIndex = this.slideCount - 1;
    if (index === currentSlideIndex) {
      if (animation) return `${classNames.animateOut} ${classNames[animation]}`;
      return classNames.current;
    }

    if (
      index === lastSlideIndex &&
      currentSlideIndex === 0 &&
      this.nextSlideIndex === lastSlideIndex
    ) {
      return `${classNames.animateIn} ${classNames.previous}`;
    }

    if (
      index === 0 &&
      currentSlideIndex === lastSlideIndex &&
      this.nextSlideIndex === 0
    ) {
      return `${classNames.animateIn} ${classNames.next}`;
    }

    if (index === currentSlideIndex - animationOffset) {
      if (animation === PREVIOUS)
        return `${classNames.animateIn} ${classNames.previous}`;
      if (animation === NEXT) return classNames.hidden;
      return classNames.previous;
    }
    if (index === currentSlideIndex + animationOffset) {
      if (animation === NEXT)
        return `${classNames.animateIn} ${classNames.next}`;
      if (animation === PREVIOUS) return classNames.hidden;
      return classNames.next;
    }

    if (index === lastSlideIndex && currentSlideIndex === 0) {
      return classNames.previous;
    }

    if (index === 0 && currentSlideIndex === lastSlideIndex) {
      return classNames.next;
    }

    if (index > currentSlideIndex) {
      return `${classNames.hidden} ${classNames.next}`;
    } else {
      return `${classNames.hidden} ${classNames.previous}`;
    }

    // return classNames.hidden;
  };

  handleWheel = (e) => {
    if (e.deltaY > 0 && e.deltaY < 0) {
      return this.previous();
    }
    this.next();
  };

  handleTouchStart = (e) => {
    this.startTouchY = e.changedTouches[0].pageY;
    this.startTouchTime = new Date();
  };

  handleTouchEnd = (e) => {
    if (e.target.parentElement.className === 'lp-slide-controller') return;
    const currentTouchY = e.changedTouches[0].pageY;
    if (new Date() - this.startTouchTime > 2000) {
      return;
    }

    if (currentTouchY - this.startTouchY < -15) this.next();
    else if (currentTouchY - this.startTouchY > 15) this.previous();
  };

  getClassNames = () => ({ ...DEFAULT_CLASSNAMES, ...this.props.classNames });

  initTouchEvents = (sliderRef) => {
    if (this.isDisabled() || !sliderRef) return;
    this.sliderRef = sliderRef;
    this.sliderRef.addEventListener('wheel', this.handleWheel);
    this.sliderRef.addEventListener('touchstart', this.handleTouchStart);
    this.sliderRef.addEventListener('touchend', this.handleTouchEnd);
  };

  // handleToggle = (type) => (e) => {
  //   if (type === "login") {
  //     this.setState({
  //       login: true,
  //       apply: false,
  //       forgottenPassword: false,
  //       apply: false,
  //     });
  //   } else if (type === "apply") {
  //     this.setState({
  //       signup: true,
  //       login: false,
  //       forgottenPassword: false,
  //       apply: false,
  //     });
  //   } else if (type === "forgottenPassword") {
  //     this.setState({
  //       forgottenPassword: true,
  //       login: false,
  //       apply: false,
  //       apply: false,
  //     });
  //   } else {
  //     this.setState({
  //       apply: true,
  //       forgottenPassword: false,
  //       login: false,
  //       apply: false,
  //     });
  //   }
  // };

  handleToggle = (type) => (e) => {
    if (type === 'login') {
      window.ga('set', {
        page: '/login',
        title: 'Login',
      });

      window.ga('send', 'pageview');
      this.setState({
        login: true,
        signup: false,
        forgottenPassword: false,
        apply: false,
      });
    } else if (type === 'signup') {
      window.ga('set', {
        page: '/signup',
        title: 'Sign Up',
      });

      window.ga('send', 'pageview');
      this.setState({
        signup: true,
        login: false,
        forgottenPassword: false,
        apply: false,
      });
    } else if (type === 'forgottenPassword') {
      window.ga('set', {
        page: '/forgot',
        title: 'Forgot Password',
      });

      window.ga('send', 'pageview');
      this.setState({
        forgottenPassword: true,
        login: false,
        signup: false,
        apply: false,
      });
    } else {
      window.ga('set', {
        page: '/apply',
        title: 'Apply to join',
      });

      window.ga('send', 'pageview');

      console.log('why not?');
      this.setState({
        apply: true,
        forgottenPassword: false,
        login: false,
        signup: false,
      });
    }
  };

  handleNavButtonClick = (type) => (e) => {
    this.handleToggle(type)();
    this.handleSlideControl(1)();
  };

  render() {
    const { children, className = 'slider', touchDisabled } = this.props;
    const classNames = this.getClassNames();
    // const isDisabled = this.isDisabled();

    return (
      <div
        className={classNames.slider || className}
        {...(!touchDisabled && { ref: this.initTouchEvents })}
      >
        <div className="lp-slide-controller">
          {React.Children.map(children, (_, index) => (
            <div
              onClick={this.handleSlideControl(index)}
              className={
                this.state.currentSlideIndex === index ? 'active' : undefined
              }
            />
          ))}
        </div>

        <LandingPageNav 
          hiddeButtons={this.state.currentSlideIndex > 0}
          joinColor={buttonColors[this.state.currentSlideIndex + 1]}
          onJoinClick={this.handleNavButtonClick('apply')}
          onLoginClick={this.handleNavButtonClick('login')}
        />

        {/* <div className="lpnav-wrapper">
          <nav className="lpnav">
            <a href="/#">
              <img src={IcpLogo} height="34" alt="logo" />
              <span className="icp-name">Leuven Event Planner</span>
            </a>

            {this.state.currentSlideIndex > 0 ? (
  
                <div className="icons-container">
                <a href="https://instagram.com/leuveneventplanner" target="_blank">
                  <i className="fa fa-instagram" />
                </a>

                <a href="https://wa.me/32496608026" target="_blank">
                  <i className="fa fa-whatsapp" />
                </a>

              </div>
            ) : (

             <div className="navbar-buttons">
               <div className="icons-container">
                <a href="https://instagram.com/leuveneventplanner" target="_blank">
                  <i className="fa fa-instagram" />
                </a>

                <a href="https://wa.me/32496608026" target="_blank">
                  <i className="fa fa-whatsapp" />
                </a>

              </div>
                <Button primary onClick={this.handleNavButtonClick("login")}>
                  Log In
                </Button>

                <Button
                  style={{
                    color: buttonColors[this.state.currentSlideIndex + 1],
                    backgroundColor: 'white',
                  }}
                  onClick={this.handleNavButtonClick("apply")}
                >
                  Sign Up
                </Button>
              </div>
            )}
          </nav>
        </div> */}

        <div className={classNames.track}>
          {React.Children.map(children, (item, index) =>
            React.cloneElement(item, {
              key: index,
              className: [
                classNames.slide,
                this.getSlideClass(index),
                item.props.className,
              ]
                .filter((v) => v)
                .join(' '),
              login: this.state.login,
              apply: this.state.apply,
              forgottenPassword: this.state.forgottenPassword,
              apply: this.state.apply,
              handleToggle: this.handleToggle,
              handleSlideControl: this.handleSlideControl,
            })
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(Slider);
