import React, { useState, useEffect } from 'react';
import { errors, success } from '../../messages';
import { useHistory } from 'react-router';
import hermes from '../../utilities/hermes';
import {
  Button,
  Input,
  Form,
  Segment,
  Header,
  Popup,
  Container,
  Select,
  Checkbox,
  Label,
  Dropdown,
} from 'semantic-ui-react';
import {
  createEvent,
  getEvents,
  getOrganizers,
} from '../../_services/event.service';
import '../../css/settings.css';
import subscriptionStore from '../../stores/subscription-store';
import SearchWithAddition from '../../components/forms/SearchWithAddition';
import { capitalize } from '../../utilities';
import { createCategory, getCategories } from '../../_services/categories.service';
import { sub } from 'date-fns';
import Fuse from 'fuse.js';


const RenderEndDate = ({ endDate, onChange }) => {
  const [check, setCheck] = useState(false);
  const onSetCheck = () => {
    setCheck(!check);
  };
  return (
    <>
      <Form className="end-date-render">
        {/* checkbox */}
        <Checkbox
          className="end-date-check"
          label="Enter an End Date"
          onChange={onSetCheck}
        />
        <div className="form-section date-time">
          <Form.Field className="date">
            {check ? (
              <Input
                name="endDate"
                value={endDate}
                type="datetime-local"
                placeholder="End Date"
                onChange={onChange}
              />
            ) : null}
          </Form.Field>
        </div>
      </Form>
    </>
  );
};

const SelectedEntity = ({ id, name, remove }) => {
  return (
    <Label className="backend-ui entity-label" basic>
      {name}
      <div onClick={remove} className="remove">
        <span>x</span>
      </div>
    </Label>
  );
};



const CreateEvent = (data) => {
  let history = useHistory();
  const [eventOrganizer, setEventOrganizer] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    startDate: '',
    endDate: null,
    organizerId: '',
    link: '',
    categories: [],
  });
  const [org, setOrg] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoryFuse, setCategoryFuse] = useState(null);
  const createBtnData = {
    name: capitalize(category.trim()),
    isAddition: true,
    onClick: () => handleCreateCategory(capitalize(category.trim())),
  }
  const [categoryResults, setCategoryResults] = useState([createBtnData]);

  

  const { title, startDate, endDate, organizerId, link } = formData;
  const dropdownRef = React.createRef()
  useEffect(() => {
    subscriptionStore.getOrganizers().then((res) => {
      setEventOrganizer(res);
    });

    getCategories().then((res) => {
      setCategories(res);
      setCategoryFuse(new Fuse(res, {
        keys: ['name'],
        threshold: 0.3,
      }));
    });


  }, []);


  useEffect(() => {
    if (categoryFuse) {;
      setCategoryResults([...categoryFuse.search(category).map(i => (
        i.item
      )), createBtnData]);
    }
  }, [categoryFuse, category]);

  const convertObjectToArray = (obj) => {
    const array = [];
    for (let key in obj) {
      array.push(obj[key]);
    }
    return array;
  };

  const listOrganizer = convertObjectToArray(eventOrganizer);

  const list = listOrganizer.map((organizer) => {
    return organizer;
  });


  const onChange = (e, data) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData);
  };
  // drop down on change function
  const onChangeDrop = (e, data) => {
    setFormData({ ...formData, organizerId: data?.value?._id });
    setOrg(data?.value?.name);
    console.log('formdata', formData);
    console.log('drop down value', data.value._id);
  };
  // remove bubble
  const onRemove = () => {
    setFormData({ ...formData, organizerId: '' });
    setOrg('');
  };

  // creating event
  const onSubmit = async () => {
    try {
      const data = {
        ...formData,
        categories: formData.categories.map((category) => category._id),
      };
      const res = await createEvent(data);
      hermes.success('event created');
      setFormData({
        title: '',
        startDate: '',
        endDate: null,
        organizerId: '',
        link: '',
        categories: [],
      });
      history.push('/all-event');
    } catch (err) {
      hermes.error('Event not created');
    }
  };

  const handleSelectCategory = (e, { result }) => {
    if (result.id !== 'add') {
      if (!formData.categories.includes(result)) {
        setFormData({
          ...formData,
          categories: [...formData.categories, result]
        });
      }
    }
  }


  const handleCreateCategory = async (category) => {
    try {
      const newCategory = await createCategory(category);
      setCategories([...categories, newCategory]);
      setFormData({
        ...formData,
        categories: [...formData.categories, newCategory]
      });
      setCategory('');
    } catch (error) {
      hermes.error("Create category failed");
      console.log(error)
    }
  };

  const removeCategory = async (category) => {
    setFormData({
      ...formData,
      categories:formData.categories.filter(c => c._id !== category._id)
    });
  }


  let sortOrganizers = eventOrganizer.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  sortOrganizers.forEach((organizer) => {
    delete organizer.image
    delete organizer.description;
  });

  // sortOrganizers = [{_id:"default", text:"Search for organizers", "data-id": "default"}, ...sortOrganizers];

  return (
    <>
      <div className="form-template-wrapper">
        <Container fluid className="form-template settings">
          <Header inverted>Add Event</Header>
          <Segment>
            <Form onSubmit={onSubmit}>
              <div className="form-section">
                <Form.Field>
                  <label className="label">Event Title</label>
                  <Input
                    required
                    name="title"
                    value={title}
                    placeholder="Event Title"
                    onChange={(e) => onChange(e)}
                  />
                </Form.Field>
              </div>
              <div className="form-section date-time">
                <Form.Field className="date">
                  <label className="label">Start Date</label>
                  <Input
                    required
                    name="startDate"
                    value={startDate}
                    type="datetime-local"
                    placeholder="Start Date"
                    onChange={(e) => onChange(e)}
                  />
                </Form.Field>
              </div>
              <div className="form-section date-time">
                <Form.Field className="date">
                  <label className="label">End Date</label>
                  <Input
                    required
                    name="endDate"
                    value={endDate}
                    type="datetime-local"
                    placeholder="End Time"
                    onChange={(e) => onChange(e)}
                  />
                </Form.Field>
              </div>
              {/* <div className="form-section">
                <select
                  required
                  name="organizerId"
                  value={organizerId}
                  onChange={(e) => onChange(e)}
                >
                  <option value="">Select an Organizer</option>
                  {eventOrganizer?.map((organizer) => {
                    // console.log(organizer);
                    return (
                      <option value={organizer._id}>{organizer.name}</option>
                    );
                  })}
                </select>
                <span>{''}</span>
              </div> */}

              <div className="form-section" value={organizerId}>
                <Form.Field>
                  <label className="label">Select an Organizer</label>
                  <Dropdown
                    placeholder="Select an Organizer"
                    fluid
                    name="organizerId"
                    search
                    selection
                    options={sortOrganizers}
                    onChange={onChangeDrop}
                  />
                </Form.Field>
                {org && <SelectedEntity name={org} remove={onRemove} />}
              </div>

              <Form.Field>
                <label className="label">Event Link</label>
                <Input
                  required
                  name="link"
                  value={link}
                  type="url"
                  placeholder="https://example.com"
                  onChange={(e) => onChange(e)}
                />
              </Form.Field>

              <Form.Field>
                <label className="label">Categories</label>
                <SearchWithAddition
                  className="search-category"
                  icon=""
                  results={categoryResults}
                  value={category}
                  onChange={(e, { value }) => { setCategory(value) }}
                  onSelect={handleSelectCategory}
                  placeholder="Add categories"
                />
                <div className="category-list">
                  {formData.categories.map((category) => {
                    return (
                      <SelectedEntity
                        key={category._id}
                        name={category.name}
                        remove={() => removeCategory(category)}
                      />
                    );
                  })}
                </div>
              </Form.Field>


              <Button
                primary
                type="submit"
                //   disabled={savingSettingsData || uploadingImage}
              >
                Save
              </Button>
              <Button onClick={history.goBack} type="button" basic>
                Cancel
              </Button>
            </Form>
          </Segment>
        </Container>
      </div>
    </>
  );
};

export default CreateEvent;