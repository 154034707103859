import { action, observable, makeObservable } from 'mobx';
import api from '../_services/authentication.service';
import { handleError, storage } from '../utilities';
import loadingStore from './loading-store';

class UsertimelineStore {
  constructor() {
    makeObservable(this, {
      endOfResults: observable,
      loadMoreResults: action,
      userFeed: observable,
    });
  }

  /* user feed */
  userFeed = [];
  endOfResults = false;
  /* user feed */

  // to load more results. Didnot use it yet
  async loadMoreResults(view) {
    let response;
    if (!this.endOfResults) {
      try {
        loadingStore.setLoading()
        if (view.toLowerCase() == 'feed') {
          response = await api.user.getSubscriptionFeed({
            offset: this.userFeed.length,
          });
        } else if (view.toLowerCase() == 'all') {
          response = await api.user.getApplicationWideFeed({
            offset: this.userFeed.length,
          });
        }
        if (response.data.length < 10) {
          this.endOfResults = true;
        }

        this.userFeed = [...this.userFeed, ...response.data];
        loadingStore.setLoaded();
      } catch (error) {
        loadingStore.setLoaded()
        throw handleError(error);
      }
    }
  }
}



export default new UsertimelineStore();
