import React, { createContext, useContext, useEffect } from "react";
import nanobar from "../../components/Nanobar";
import useEvents from "../../hooks/useEvents";
import useFilters from "../../hooks/useFilters";
import useSubscriptions from "../../hooks/useSubscriptions";
import loadingStore from "../../stores/loading-store";
import { filterEvents, tabFilters, timeFilters } from "./helpers";


interface IFeedContext {
  events: IEvent[];
  featuredEvents: IEvent[];
}

const FeedContext = createContext<IFeedContext | null>(null);

export const useFeedContext = () => {
  const context = useContext(FeedContext)
  if (!context) {
    throw new Error("useFeedContext must be used within a FeedContextProvider")
  }
  return context;
}

export const FeedContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { subscriptions } = useSubscriptions();
  const { events, isLoading } = useEvents();
  let { categories, tabFilter, timeFilter, updateTimeFilter } = useFilters();


  // Ensure valid filters
  if (!tabFilter || !tabFilters[tabFilter]) {
    tabFilter = "all";
  }

  if (!timeFilter || !timeFilters[timeFilter]) {
    timeFilter = "today";
  }


  useEffect(() => {
    if (!isLoading) {
      nanobar.go(100);
      loadingStore.setPageMaskLoaded();
      loadingStore.setLoaded();
    } else {
      nanobar.go(30);
      loadingStore.setPageMaskLoading();
      loadingStore.setLoading();
    }
  }, [isLoading]);

  let filteredEvents = filterEvents({events, tabFilter, timeFilter, categories, subscriptions}); 

  const featuredEvents = events.filter(event => event.featured);

  return (
    <FeedContext.Provider
      value={{
        events: filteredEvents,
        featuredEvents,
      }}
    >
      {children}
    </FeedContext.Provider>
  )  
}