import { createContext, useContext, useEffect, useState } from "react";
import Fuse from "fuse.js";
import {
    approveEventRequest,
    createEvent,
    getAllEvents,
    updateEvent,
    deleteEvent as _deleteEvent,
    deleteEventRequest,
    updateEventRequest,
    approveDeleteEventRequest,
} from "../../_services/event.service";

import { 
    filterFunctions,
    formatDate,
    formatEvent,
    formatEventsToSearch,
    parseEvent,
    parseEvents,
    sortFunctions
} from "./helpers";

import moment from "moment-timezone";
import { hermes } from "../../utilities";
import loadingStore from "../../stores/loading-store";
import _ from "lodash";
import nanobar from "../../components/Nanobar";
import authApi from "../../_services/authentication.service";   
import { getCategories } from "../../_services/categories.service";
import useOrganizers from "../../hooks/useOrganizers";
import useCategories from "../../hooks/useCategories";

const AdminEventsContext = createContext<IAdminEventsContext | null>(null);

export function AdminEventsContextProvider({ children }: { children: React.ReactNode }) {
    const [events, setEvents] = useState<Array<ParsedEvent | ParsedEventRequest>>([]);
    const [_events, _setEvents] = useState<Array<ParsedEvent | ParsedEventRequest>>([]);
    const [sort, setSort] = useState("startDate");
    const [filter, setFilter] = useState("all");
    const [search, setSearch] = useState("");
    const [fuse, setFuse] = useState<Fuse<EventSearchItem> | null>(null);
    const organizers = useOrganizers();
    const categories = useCategories();

    useEffect(() => {
        loadingStore.setPageMaskLoading();
        nanobar.go(30);
        getAllEvents().then(res => {
            const parsed = parseEvents(res);
            _setEvents(parsed);
        }).finally(() => {
            loadingStore.setPageMaskLoaded()
            nanobar.go(100);
        });
    }, []);

    useEffect(() => {
        const searchItems = formatEventsToSearch(_events.map(formatEvent));
        setFuse(new Fuse(searchItems, {
            keys: [
                "title",
                "startDate",
                "endDate",
                "link",
                "_link",
                "featured",
                "_featured",
                "cancelled",
                "updatedAt",
                "status",
                "organizer.name",
                "requests.title",
                "requests.startDate",
                "requests.endDate",
                "requests.link",
                "requests._link",
                "requests.featured",
                "requests._featured",
                "requests.cancelled",
                "requests.organizer.name",
                "requests.updatedAt",
                "requests.status",
            ],
            threshold: 0,
        }));
    }, [_events]);


    useEffect(() => {
        function searchEvents(): Array<ParsedEvent | ParsedEventRequest> {
            if (!fuse || !search) return _events;
            return fuse.search(search).map(
                item => _events.find(event => event._id === item.item._id)
            ).filter(e => e) as Array<ParsedEvent | ParsedEventRequest>;
        }

        const filteredEvents = _.filter(searchEvents(), filterFunctions[filter]);
        const sortedEvents = filteredEvents.sort(sortFunctions[sort]);
        setEvents([...sortedEvents]);

    }, [_events, filter, sort, search, fuse]);


    async function addEvent({ organizer, title, startDate, endDate, link, featured, cancelled, categories }: NewEvent) {
        loadingStore.setLoading();
        try {
            const res = await createEvent({
                title: title.trim(),
                link: link.trim(),
                featured,
                cancelled,
                organizerId: organizer?._id,
                startDate: moment.tz(startDate, 'DD/MM/YYYY HH:mm', true, 'Europe/Brussels'),
                endDate: moment.tz(endDate, 'DD/MM/YYYY HH:mm', true, 'Europe/Brussels'),
                categories: categories.map(c => c._id),
            });
            const event = parseEvent(res);
            _setEvents(events => [...events, event]);
            hermes.success("Event created");
        } catch (e) {
            console.error(e);
            hermes.error("Failed to create event");
        }
        return loadingStore.setLoaded();
    }

    async function editEvent({ _id, organizer, title, startDate, endDate, link, featured, cancelled, categories }: RawEvent): Promise<void> {
        loadingStore.setLoading();
        try {
            const res = await updateEvent({
                title: title.trim(),
                link: link.trim(),
                featured,
                cancelled,
                organizerId: organizer?._id,
                startDate: moment.tz(startDate, 'DD/MM/YYYY HH:mm', true, 'Europe/Brussels'),
                endDate: moment.tz(endDate, 'DD/MM/YYYY HH:mm', true, 'Europe/Brussels'),
                categories: categories.map(c => c._id),
            }, _id);
            const event = parseEvent(res);
            if (event._id === _id) {
                _setEvents(events => events.map(e => e._id === _id ? event : e));
            } else {
                _setEvents(events => events.map(e => e._id === _id ? {
                    ...e,
                    requests: "requests" in e ? [event as ParsedEventRequest, ...e.requests,] : [event as ParsedEventRequest],
                } : e));
            }

            hermes.success("Event updated");
        } catch (e) {
            hermes.error(e);
        }
        return loadingStore.setLoaded();
    }

    async function editEventRequest({ _id, organizer, title, startDate, endDate, link, featured, cancelled, categories }: RawEventRequest): Promise<void> {
        loadingStore.setLoading();
        try {
            const res = await updateEventRequest({
                title: title.trim(),
                link: link.trim(),
                featured,
                cancelled,
                organizerId: organizer?._id,
                startDate: moment.tz(startDate, 'DD/MM/YYYY HH:mm', true, 'Europe/Brussels'),
                endDate: moment.tz(endDate, 'DD/MM/YYYY HH:mm', true, 'Europe/Brussels'),
                categories: categories.map(c => c._id),
            }, _id);
            const event = parseEvent(res) as ParsedEventRequest;
            if (event.type === "ADD") {
                _setEvents(events => events.map(e => e._id === _id ? event : e));
            } else {
                _setEvents(events => events.map(e => e._id === event.event ? {
                    ...e,
                    requests: "requests" in e ? e.requests.map(r => r._id === _id ? event : r) : [event],
                } : e));
            }

            hermes.success("Event updated");
        } catch (e) {
            hermes.error(e);
        }
        return loadingStore.setLoaded();
    }

    async function deleteEvent(_id: string): Promise<void> {
        loadingStore.setLoading();
        try {
            const _event = _events.find(e => e._id === _id) || _events.map(
                e => "requests" in e && e.requests.find(r => r._id === _id)
            ).find(e => e);
            if (!_event) throw new Error("Event not found");
            const isRequest = "type" in _event;

            if (isRequest) {
                await deleteEventRequest(_id);
                if (_event.type === "ADD") {
                    _setEvents(events => events.filter(e => e._id !== _id));
                } else {
                    _setEvents(events => events.map(e => e._id === _event.event ? {
                        ...e,
                        requests: "requests" in e ? e.requests.filter(r => r._id !== _id) : [],
                    } : e));
                }
            } else {
                const res = await _deleteEvent(_id);
                if (res) {
                    const event = parseEvent(res) as ParsedEventRequest;
                    _setEvents(events => events.map(e => e._id === _id ? {
                        ...e,
                        requests: "requests" in e ? [...e.requests, event] : [event],
                    } : e));
                } else {
                    _setEvents(events => events.filter(e => e._id !== _id));
                }

            }

            hermes.success("Event deleted");
        } catch (e) {
            hermes.error(e);
        }
        return loadingStore.setLoaded();
    }

    async function approveRequest(_id: string): Promise<void> {
        loadingStore.setLoading();
        try {

            const _event = await approveEventRequest(_id).then(parseEvent);

            _setEvents(_events => {
                let events = _events.map(e => {
                    if (e._id === _event._id)
                        return {
                            ..._event,
                            requests: "requests" in e ? e.requests.filter(r => r._id !== _id) : [],
                        };
                    else if (e._id === _id) {
                        return _event;
                    }
                    return e;
                });

                return events;
            });
            
            hermes.success("Request approved");
        } catch {
            hermes.error("Failed to approve request");
        }
        return loadingStore.setLoaded();
    }

    async function approveDeleteRequest(_id: string) : Promise<void> {
        loadingStore.setLoading();
        try {
            await approveDeleteEventRequest(_id);
            _setEvents(_events => _events.filter(e => "requests" in e && e.requests.find(r => r._id === _id) === undefined));
            hermes.success("Request approved");
        } catch (e) {
            hermes.error(e);
        }
        return loadingStore.setLoaded();
    }
            


    return (
        <AdminEventsContext.Provider value={{
            _events,
            events,
            addEvent,
            editEvent,
            deleteEvent,
            sort,
            setSort,
            filter,
            setFilter,
            search,
            setSearch,
            approveRequest,
            approveDeleteRequest,
            editEventRequest,
            organizers,
            categories,
        }}>
            {children}
        </AdminEventsContext.Provider>
    )
}

export default function useAdminEvents() {
    const context = useContext(AdminEventsContext);
    if (!context) throw new Error("useAdminEvents must be used within a AdminEventsProvider");
    return context;
}

export function useEventCount() {
    const { _events } = useAdminEvents();
    const [count, setCount] = useState(0);
    const [dateToSearch, setDateToSearch] = useState(formatDate(moment.tz("Europe/Brussels").startOf("day")));


    useEffect(() => {
        const date = moment.tz(dateToSearch, 'DD/MM/YYYY HH:mm', true, 'Europe/Brussels');
        if (date.isValid()) {
            setCount(_events.filter(e => 
                !("type" in e && e.type === "ADD") && e.createdAt.isAfter(date)
            ).length);
        } else {
            setCount(NaN);
        }
    } , [_events, dateToSearch]);

    return {
        count,
        dateToSearch,
        setDateToSearch,
    }
}