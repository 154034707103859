import moment from "moment-timezone";
import authenticationService from "../_services/authentication.service";
import { 
  REACT_APP_API_URL,
  REACT_APP_DEMO_VIDEO,
  REACT_APP_EMAILS_FORM,
  REACT_APP_ORGANIZER_PAGE_REQUIRED,
  REACT_APP_ORGANIZER_PAGE_MIN_DATE,
  REACT_APP_THANK_YOU_VIDEO,
  REACT_APP_INVITES_PAGE_REQUIRED,
  REACT_APP_INVITES_PAGE_MIN_DATE
} from "../constants";

const organizerMinDate = moment.tz(REACT_APP_ORGANIZER_PAGE_MIN_DATE, "DD/MM/YYYY HH:mm:ss.SSS", "Europe/Brussels")
const invitesMinDate = moment.tz(REACT_APP_INVITES_PAGE_MIN_DATE, "DD/MM/YYYY HH:mm:ss.SSS", "Europe/Brussels")

const config = {
  apiUrl: REACT_APP_API_URL,
  demoVideo: REACT_APP_DEMO_VIDEO,
  emailsForm: REACT_APP_EMAILS_FORM,
  thankYouVideo: REACT_APP_THANK_YOU_VIDEO,
  isOrganizerPageRequired: () => {
    const joinDate = moment.tz(authenticationService?.currentUserValue?.joinDate, "Europe/Brussels");

    return (
      REACT_APP_ORGANIZER_PAGE_REQUIRED && 
      organizerMinDate.isBefore(joinDate)
    );
  },
  isInvitePageRequired: () => {
    const joinDate = moment.tz(authenticationService?.currentUserValue?.joinDate, "Europe/Brussels");

    return (
      REACT_APP_INVITES_PAGE_REQUIRED && 
      invitesMinDate.isBefore(joinDate)
    );
  },
};

export default config;