import React, { Component } from "react";
import { Button } from "semantic-ui-react";

class ForgottenPasswordButton extends Component {
  constructor(props) {
    super(props);
    this.state = { currState: 1, emailSent: false };
    this.next = this.next.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  async sendEmail() {
    await this.props.sendEmail();
    this.setState({ emailSent: true });
  }

  next() {
    this.setState({ currState: this.state.currState + 1 });
  }

  render() {
    let { currState, emailSent } = this.state;

    if (currState === 1) {
      return (
        <div className="forgotten-password-btn-wrapper">
          <button
            onClick={this.next}
            type="button"
            className="forgotten-password-btn buttonLink"
          >
            Forgot password
          </button>
        </div>
      );
    } else if (currState === 2 && !this.props.inProgress && !emailSent) {
      return (
        <Button
          primary
          className="reset-button"
          type="button"
          onClick={this.sendEmail}
        >
          Send a password reset email
        </Button>
      );
    } else if (this.props.inProgress) {
      return null;
    } else if (emailSent) {
      return (
        <h5 className="reset-button">
          A password reset link was sent to your email
        </h5>
      );
    } else {
      return null;
    }
  }
}

export default ForgottenPasswordButton;
