import React, { useState } from 'react';
import hermes from '../../utilities/hermes';
import { useHistory } from 'react-router';
import {
  Button,
  Input,
  Form,
  Segment,
  Header,
  Popup,
  Container,
  Select,
} from 'semantic-ui-react';
import { createOrganizer } from '../../_services/organizers.service';
import '../../css/settings.css';

const CreateOrganizer = () => {
  const [formData, setFormData] = useState({
    name: '',
  });

  const { name } = formData;

  let history = useHistory();

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async () => {
    try {
      await createOrganizer(formData);
      hermes.success('Added organizer');
      setFormData({
        name: '',
        image: [],
      });
      history.push('/organizers');  
    } catch (error) {
      hermes.error(error);
    }
  };
  return (
    <>
      <div className="form-template-wrapper">
        <Container fluid className="form-template settings">
          <Header inverted>Add Organizer</Header>
          <Segment>
            <Form onSubmit={onSubmit}>
              <div className="form-section">
                <Form.Field>
                  <label className="label">Name</label>
                  <Input
                    required
                    name="name"
                    value={name}
                    placeholder="Organizer name"
                    onChange={(e) => onChange(e)}
                  />
                </Form.Field>
              </div>
              {/* <div className="form-section">
                <Form.Field>
                  <label>Image</label>
                  <Input
                    required
                    name="image"
                    value={image}
                    type="file"
                    placeholder="Organizer Image"
                    onChange={(e) => onChange(e)}
                  />
                </Form.Field>
              </div> */}

              <Button primary type="submit">
                Save
              </Button>
              <Button onClick={history.goBack} type="button" basic>
                Cancel
              </Button>
            </Form>
          </Segment>
        </Container>
      </div>
    </>
  );
};

export default CreateOrganizer;
