import { useRef, useState } from "react";
import { Button, Image, Input, Table } from "semantic-ui-react";
import { hermes } from "../../utilities";
import { IOrganizer, EditOrganizerRowProps } from "./types";
import useAdminOrganizers from "./useAdminOrganizers";
import styles from "./AdminOrganizers.module.css";

const placeholder = "https://www.e-designeroutlet.com/media/catalog/product/cache/88693066e778cee687b8a895a940c49c/3/Z/3Z1M71.1J36Z_MAG-41yC51acioL..jpg"


export function EditOrganizerRow({ organizer, exit, add }: EditOrganizerRowProps) {
  const { addOrganizer, editOrganizer } = useAdminOrganizers();
  const ImageRef = useRef<HTMLInputElement>(null);
  const { validate, errors, clearError } = useValidation();

  if (add || !organizer) {
    organizer = { _id: "add", name: "", url: "", image: "", description: "", showAllEvents: false, showAllEventInfo: false };
  }

  const [newOrganizer, setNewOrganizer] = useState<IOrganizer>(organizer);

  const saveFn = add ? addOrganizer : editOrganizer;

  let onSave = async () => {
    if (validate(newOrganizer)) {
      await saveFn(newOrganizer)
      exit();
    }
  }

  const onClickImage = () => ImageRef.current!.click();

  return (
    <Table.Row key={organizer._id}>
      <Table.Cell>
        <Image 
          onClick={onClickImage}
          src={newOrganizer.image || placeholder}
          size="mini"
          circular
          className={errors.image ? styles.error : ""}
        />
        <input hidden ref={ImageRef} type="file" accept="image/*" onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setNewOrganizer({ ...newOrganizer, image: URL.createObjectURL(e.target.files[0]) })
            clearError("image");
          }
        }} />
      </Table.Cell>
      <Table.Cell>
        <Input
          value={newOrganizer.name}
          onChange={(e, { value }) => setNewOrganizer({ ...newOrganizer, name: value })}
          className={errors.name ? styles.error : ""}
          onBlur={() => clearError("name")}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          value={newOrganizer.url}
          onChange={(e, { value }) => setNewOrganizer({ ...newOrganizer, url: value })}
          className={errors.url ? styles.error : ""}
          onBlur={() => clearError("url")}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          value={newOrganizer.description}
          onChange={(e, { value }) => setNewOrganizer({ ...newOrganizer, description: value })}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          basic
          onClick={() => setNewOrganizer({ ...newOrganizer, showAllEvents: !newOrganizer.showAllEvents })}
          content={newOrganizer.showAllEvents ? "Yes" : "No"}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          basic
          onClick={() => setNewOrganizer({ ...newOrganizer, showAllEventInfo: !newOrganizer.showAllEventInfo })}
          content={newOrganizer.showAllEventInfo ? "Yes" : "No"}
        />
      </Table.Cell>
      <Table.Cell collapsing>
        <Button primary onClick={onSave}>Save</Button>
      </Table.Cell>
      <Table.Cell collapsing>
        <Button basic onClick={exit}>Cancel</Button>
      </Table.Cell>

    </Table.Row>

  );
}


function useValidation() {
  const [errors, setErrors] = useState({
    image: false,
    name: false,
    url: false,
  });

  function validate(organizer: IOrganizer) {
    let hasErrors = false;

    if (!organizer.image) {
      hermes.error("Please add an image");
      setErrors(errors => ({ ...errors, image: true }));
      hasErrors = true;
    }

    if (!organizer.name) {
      hermes.error("Please input a name");
      setErrors(errors => ({ ...errors, name: true }));
      hasErrors = true;
    }

    if (!organizer.url) {
      hermes.error("Please input an url");
      setErrors(errors => ({ ...errors, url: true }));
      hasErrors = true;
    }

    return !hasErrors;
  }

  function clearError(field: string) {
    setErrors(errors => ({ ...errors, [field]: false }));
  }

  return { errors, validate, clearError };
}
