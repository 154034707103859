import { Button, Dropdown, Header, Table, TableCell } from "semantic-ui-react";
import useAdminInvites, { AdminInvitesContextProvider } from "./useAdminInvites";
import styles from "./AdminInvites.module.css";
import { filterOptions, sortOptions, formatDate } from "./helpers";
import { useState } from "react";

export default function AdminInvites() {
  return (
    <AdminInvitesContextProvider>
      <AdminInvitesPage />
    </AdminInvitesContextProvider>
  );
}

function AdminInvitesPage() {
  const {
    invites,
    sort,
    setSort,
    filter,
    setFilter,
  } = useAdminInvites();

  return (
    <div className={styles.container}>
      <Header>Invites</Header>

      <div className={styles.filters}>

        <div className={styles.dropdownWrapper}>
          <span>Sort by:</span>
          <Dropdown
            className={styles.sortDropdown}
            options={sortOptions}
            placeholder="Sort by"
            search
            selection
            value={sort}
            onChange={(e, { value }) => setSort(value as string)}
          />
        </div>

        <div className={styles.dropdownWrapper}>
          <span>Filter:</span>
          <Dropdown
            className={styles.sortDropdown}
            options={filterOptions}
            placeholder="Filter by"
            search
            selection
            value={filter}
            onChange={(e, { value }) => setFilter(value as string)}
          />
        </div>
      </div>
      <Table celled inverted singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date Invited</Table.HeaderCell>
            <Table.HeaderCell>Date Joined</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell colSpan={3}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {invites.map((invite) => (
            <InviteRow key={invite._id} invite={invite} />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

interface InviteRowProps {
  invite: IInvite;
}

function InviteRow({ invite }: InviteRowProps) {
  const [editing, setEditing] = useState(false);
  const { approveInvite, deleteInvite } = useAdminInvites();

  if (editing) {
    return <EditInviteRow invite={invite} setEditing={setEditing} />;
  }

  return (
    <Table.Row>
      <Table.Cell>{formatDate(invite.createdAt)}</Table.Cell>
      <Table.Cell>{formatDate(invite.inviter.createdAt)}</Table.Cell>
      <Table.Cell>{invite.email}</Table.Cell>
      <Table.Cell>{invite.firstName}</Table.Cell>
      <Table.Cell>{invite.lastName}</Table.Cell>
      {invite.status === "Submitted" ? (
        <>
          <TableCell collapsing>
            <Button
              onClick={() => setEditing(true)}
              primary
            >
              Edit
            </Button>
          </TableCell>
          <Table.Cell collapsing>
            <Button
              primary
              onClick={() => approveInvite(invite._id)}
            >
              Approve
            </Button>
          </Table.Cell>
          <Table.Cell collapsing>
            <Button
              negative
              onClick={() => deleteInvite(invite._id)}
            >
              Delete
            </Button>
          </Table.Cell>
        </>
      ): (
        <Table.Cell collapsing>
          <Button
            primary
            disabled
          >
            Approved
          </Button>
        </Table.Cell>
      )}
    </Table.Row>
  );
}

interface EditInviteRowProps {
  invite: IInvite;
  setEditing: (editing: boolean) => void;
}

function EditInviteRow({ invite, setEditing }: EditInviteRowProps) {
  const [newInvite, setNewInvite] = useState(invite);
  const { updateInvite } = useAdminInvites();

  return (
    <Table.Row className={styles.active}>
      <Table.Cell>{formatDate(invite.createdAt)}</Table.Cell>
      <Table.Cell>{formatDate(invite.inviter.createdAt)}</Table.Cell>
      <Table.Cell className={styles.edit}>
        <span className="mobile-only">Email:</span>
        <input
          type="text"
          value={newInvite.email}
          placeholder="Email"
          onChange={e => setNewInvite({ ...newInvite, email: e.target.value })}
        />
      </Table.Cell>
      <Table.Cell className={styles.edit}>
        <span className="mobile-only">First Name:</span>
        <input
          type="text"
          value={newInvite.firstName}
          placeholder="First Name"
          onChange={e => setNewInvite({ ...newInvite, firstName: e.target.value })}
        />
      </Table.Cell>
      <Table.Cell className={styles.edit}>
        <span className="mobile-only">Last Name:</span>
        <input
          type="text"
          value={newInvite.lastName}
          placeholder="Last Name"
          onChange={e => setNewInvite({ ...newInvite, lastName: e.target.value })}
        />
      </Table.Cell>
      <Table.Cell collapsing>
        <Button
          primary
          onClick={() => updateInvite(newInvite).then(() => setEditing(false))}
        >
          Save
        </Button>
      </Table.Cell>
      <Table.Cell collapsing>
        <Button
          primary
          onClick={() => setEditing(false)}
        >
          Cancel
        </Button>
      </Table.Cell>
    </Table.Row>
  );
}

