import { Dropdown, Table } from "semantic-ui-react";
import { actionOptions, sortOptions, formatObject } from "./helpers";
import useAuditLogs from "./useAuditLogs";

import styles from './AuditLogs.module.css';

export default function AuditLogs() {
  const {
    logs,
    sort,
    setSort,
    actionFilter,
    setActionFilter,
    organizerFilter,
    setOrganizerFilter,
    organizerOptions,
    userFilter,
    setUserFilter,
    userOptions
  } = useAuditLogs();

  return (
    <div className={styles.container}>
      <h1>Audit Logs</h1>

      <div className={styles.cardWrapper}>
        <div className={styles.card}>
          <div className={styles.dropdownWrapper}>
            <span>Sort by:</span>
            <Dropdown
              className={styles.sortDropdown}
              options={sortOptions}
              placeholder="Sort by"
              search
              selection
              value={sort}
              onChange={(e, { value }) => setSort(value as string)}
            />
          </div>

          <div className={styles.dropdownWrapper}>
            <span>Filter actions:</span>
            <Dropdown
              className={styles.sortDropdown}
              options={actionOptions}
              placeholder="Filter by"
              search
              selection
              value={actionFilter}
              onChange={(e, { value }) => setActionFilter(value as string)}
            />
          </div>

          <div className={styles.dropdownWrapper}>
            <span>Filter organizer:</span>
            <Dropdown
              className={styles.sortDropdown}
              options={organizerOptions}
              placeholder="Filter by"
              search
              selection
              value={organizerFilter}
              onChange={(e, { value }) => setOrganizerFilter(value as string)}
            />
          </div>


          <div className={styles.dropdownWrapper}>
            <span>Filter user:</span>
            <Dropdown
              className={styles.sortDropdown}
              options={userOptions}
              placeholder="Filter by"
              search
              selection
              value={userFilter}
              onChange={(e, { value }) => setUserFilter(value as string)}
            />
          </div>
        </div>
      </div>

      <Table inverted striped unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Action</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Object</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>User</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {logs.map(({ _id, action, object, objectType, date, user }) => (
            <Table.Row key={_id}>
              <Table.Cell>{action}</Table.Cell>
              <Table.Cell>{formatObject(object)}</Table.Cell>
              <Table.Cell>{objectType}</Table.Cell>
              <Table.Cell>{date.format("DD/MM/YY HH:mm:ss")}</Table.Cell>
              <Table.Cell>{user.fullName}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}



