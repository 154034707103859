import React, { Component } from "react";
import PropTypes from "prop-types";

// Handles error. Added on app.js
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, info: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ info });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {this.state.info && this.state.info.componentStack}
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
