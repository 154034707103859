import React, { Fragment } from "react";
import { Card, Header, Container } from "semantic-ui-react";
import profileStore from "../../stores/profile-store";

export default class Statistics extends React.Component {
  stats;
  constructor(props) {
    super(props);
    this.state = {
      stats: null,
    };
    this.getStatistics = this.getStatistics.bind(this);
    this.getStatistics();
  }

  async getStatistics() {
    var stat = await profileStore.getUserStatistics();
    var data = {
      stage1: stat.stage1,
      stage2: stat.stage2,
      stage3: stat.stage3,
      stage4: stat.stage4,
    };

    this.stats = stat;
    this.setState({ stats: data });
  }

  render() {
    return (
      <Fragment>
        <Container fluid>
          <div className="feed">
            <div className="main">
              <Card>
                <React.Fragment>
                  <Header inverted>
                    <Header.Content>
                      <Header.Subheader>
                        {" "}
                        Account Created - {this.state.stats?.stage1}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>

                  <Header inverted>
                    <Header.Content>
                      <Header.Subheader>
                        {" "}
                        Phone Inputted - {this.state.stats?.stage2}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>

                  <Header inverted>
                    <Header.Content>
                      <Header.Subheader>
                        {" "}
                        Phone Verified - {this.state.stats?.stage3}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>

                  <Header inverted>
                    <Header.Content>
                      <Header.Subheader>
                        {" "}
                        Email Verified - {this.state.stats?.stage4}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </React.Fragment>
              </Card>
            </div>
          </div>
        </Container>
      </Fragment>
    );
  }
}
