import loginSchema from '../../schemas/login-schema';
import React, { Fragment, Component } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { withFormik } from 'formik';
import { hermes } from '../../utilities';
import ReactGA from 'react-ga';

const { Input, Field } = Form;

// component for rendering login form ui
class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //when the component mount set page view for googleAnalytics
  componentDidMount() {
    ReactGA.pageview('/login');
  }

  // when there is un update, set page view for googleAnalytics
  componentDidUpdate() {
    ReactGA.pageview('/login');
  }

  render() {
    const { values, handleChange, handleBlur, handleSubmit } = this.props;
    let { email, password } = values;

    return (
      <Fragment>
        <div className="login form" data-testid="form-div">
          <form onSubmit={handleSubmit} autoComplete="off" data-testid="form">
            {/* <Field>
              <Input
                required
                placeholder="Enter your email"
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Field> */}
            <div className="form-input-field-all">
              <input
                className="form-input-all"
                placeholder="Enter your email"
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-input-field-all">
              <input
                className="form-input-all"
                placeholder="Enter your password"
                type="password"
                name="password"
                value={password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <Field className="center">
              <Button type="submit" primary>
                Login
              </Button>
            </Field>
          </form>
        </div>
      </Fragment>
    );
  }
}

export const MyEnhancedForm = withFormik({
  mapPropsToValues: (props) => {
    return {
      email: '',
      password: '',
    };
  },
  validate: async (values) => {
    const error = await loginSchema
      .validate(values)
      .then(() => false)
      .catch((err) => {
        hermes.error(err.errors[0]);
        return err;
      });
    if (error) {
      throw error;
    }

    return {};
  },

  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, bag) => {
    bag.props.handleSubmit(values);
  },

  displayName: 'LoginForm',
})(LoginForm);

export default inject('authStore')(observer(MyEnhancedForm));
