import React, { Component } from "react";
import { Container } from "semantic-ui-react";

class Privacy extends Component {
  render() {
    return (
      <Container className="legal-page">
        <h1>Privacy Policy</h1>
        <p>See terms</p>
      </Container>
    );
  }
}

export default Privacy;
