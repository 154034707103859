import signupSchema from '../../schemas/signup-schema';
import React, { Fragment, Component } from 'react';
import { Form, Button, Checkbox } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { hermes } from '../../utilities';
import { withFormik } from 'formik';
import ReactGA from 'react-ga';
import { RandomLocationContext } from '../../contexts/RandomLocationContext';
import { Link } from 'react-router-dom';

const { Input, Field } = Form;

// component for generating sign up form ui
class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = RandomLocationContext

  //when the component mount set page view for googleAnalytics
  componentDidMount() {
    ReactGA.pageview('/signup');
  }

  // when there is un update, set page view for googleAnalytics
  componentDidUpdate() {
    ReactGA.pageview('/signup');
  }

  handleCheckboxChange = (e, { name, checked }) => {
    this.props.setFieldValue(name, checked);
  }

  linkPatch = (e) => {
    const { agreedToTerms } = this.props.values;
    this.props.setFieldValue('agreedToTerms', !agreedToTerms);
  }

  render() {
    const { values, handleChange, handleBlur, handleSubmit, submitButtonText } =
      this.props;
    let { email, password, fullName } = values;

    return (
      <Fragment>
        <div className="form signup" data-testid="form-div">
          <form onSubmit={handleSubmit} autoComplete="off" data-testid="form">
            <div className="form-input-field-all">
              <input
                className="form-input-all"
                placeholder="Full name"
                autoComplete="new-password"
                type="text"
                name="fullName"
                value={fullName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {/* <Field>
              <Input
                required
                placeholder="Email"
                type="email"
                name="email"
                autoComplete="new-password"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{border:'0.5px solid #3f88d3'}}
              />
            </Field> */}
            <div className="form-input-field-all">
              <input
                className="form-input-all"
                placeholder="Email"
                autoComplete="new-password"
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {/* <Field>
              <Input
                required
                autoComplete="new-password"
                placeholder="Password (min 8 characters)"
                type="password"
                name="password"
                value={password}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{border:'0.5px solid #3f88d3'}}
              />
            </Field> */}
            <div className="form-input-field-all">
              <input
                className="form-input-all"
                autoComplete="new-password"
                placeholder="Password (min 8 characters)"
                type="password"
                name="password"
                value={password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {/* <div>
              <Checkbox 
                inverted
                checked={agreedToTerms}
                name="agreedToTerms"
                onChange={this.handleCheckboxChange}
                label={
                  <label>
                    By signing up you agree to our{' '}
                    <Link as="a" to={this.context.terms}>
                      Terms
                    </Link>
                    </label>
                }
              />

            </div> */}
            <span className='terms-warning'>
              By continuing you accept our{' '}
              {/* <a href="/terms" target="_blank" rel="noopener noreferrer">
                terms
              </a>{' '} */}
              <Link as="a" to={this.context.terms}>
                terms
              </Link>{' '}
              and{' '}
              {/* <a href="/terms" target="_blank" rel="noopener noreferrer">
                privacy policy
              </a>{' '} */}
              <Link as="a" to={this.context.terms}>
                privacy policy
              </Link>

            </span>
              
            <Field className="button">
              <Button type="submit" primary>
                {submitButtonText}
              </Button>
            </Field>
          </form>
        </div>
      </Fragment>
    );
  }
}

export const MyEnhancedForm = withFormik({
  mapPropsToValues: (props) => {
    return {
      fullName: '',
      email: '',
      password: '',
      // agreedToTerms: false
    };
  },
  validate: async (values) => {
    const error = await signupSchema
      .validate(values)
      .then(() => false)
      .catch((err) => {
        hermes.error(err.errors[0]);
        return err;
      });
    if (error) {
      throw error;
    }

    return {};
  },

  validateOnBlur: false,
  validateOnChange: false,

  handleSubmit: (values, bag) => {
    bag.props.handleSubmit(values);
    console.log(values)
  },

  displayName: 'SignupForm',
})(SignupForm);

export default inject('authStore')(observer(MyEnhancedForm));
