import * as yup from "yup";
import { errors } from "../messages";

const schema = yup.object({
  fullName: yup.string().required().test(
    "is-full-name",
    "Type in your full name",
    (value) => value.split(" ").filter((name) => name.length > 0).length >= 2,
  ),
  email: yup.string().email().required(),
  password: yup.string().required().min(8, errors.PASSWORD_TOO_SHORT),
  // agreedToTerms: yup.boolean().oneOf([true], "To sign up, agree to our Terms")
});

export default schema;
