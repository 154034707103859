import React, { useEffect, useState } from "react";
import "../../css/onboarding.css";
import { Progress, Container, Button, Checkbox } from "semantic-ui-react";
import {
  WhatsappShareButton,
} from 'react-share';
import { useHistory } from 'react-router';
import loadingStore from "../../stores/loading-store";
import api from "../../_services/authentication.service";
import { hermes } from "../../utilities";
import ReactPlayer from "react-player/youtube";
import config from "../../_helpers/config";
import { mutate } from "swr";


const ThankYou = () => {
  // const [newsletter, setNewsletter] = useState(true);
  const [personalEmails, setPersonalEmails] = useState(true);
  const [whatsapp, setWhatsapp] = useState(true);
  const [browserNotificationsSwitch, setBrowserNotificationsSwitch] = useState(true);


  let history = useHistory();

  useEffect(() => {
    // loadingStore.setPageMaskLoaded();
    return history.push("/");

    try {
      const seenThankYou = localStorage.getItem('seenThankYou');
      if (seenThankYou === 'true') {
        history.push('/');
      } else {
        localStorage.setItem('seenThankYou', 'true');
      }
    } catch (error) {
      console.log(error);
    }
  // eslint-disable-next-line
  }, []);


  const onContinue = async () => {
    loadingStore.setLoading();
    try {
      await api.updateUserPreference({
        // newsletter,
        newsletter: true,
        personalEmails,
        whatsapp,
        browserNotificationsSwitch
      });

      await mutate('preferences');

      loadingStore.setLoaded();
      history.push('/');
    }
    catch (error) {
      hermes.error("Error updating user preferences");
      loadingStore.setLoaded();
    }
  }


  return (
    <>
      <Progress percent={100} className="onboarding-progress-bar" />
      <Container fluid>
        <div className="onboarding-wrapper thank-you">
          <div className="onboarding-heading ">Learn how to use Leuven Event Planner</div>
          <div className="onboarding-sub-heading">
            {/* <p>
              <WhatsappShareButton
                title="Leuven Event Planner"
                separator=" "
                url="https://www.leuveneventplanner.com/"
              >
                <span className="insta-share">Click here</span>
              </WhatsappShareButton>{' '}
              to tell your friends on{' '}
              <WhatsappShareButton
                title="Leuven Event Planner"
                separator=" "
                url="https://www.leuveneventplanner.com/"
              >
                <span className="insta-share">WhatsApp</span>
              </WhatsappShareButton>{' '}about us
            </p> */}
            <p>
              <a
                className="insta-share"
                href="https://www.instagram.com/leuveneventplanner/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </a>{' '}
              to follow us on{' '}
              <a
                className="insta-share"
                href="https://www.instagram.com/leuveneventplanner/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>{' '}
            </p>

            {/* Telegram and contact us here */}
            {/* <br /> */}

            {/* <div className="onboarding-toggle">
              <Checkbox toggle checked={newsletter} onChange={()=>setNewsletter(!newsletter)} label="Receive emails from us" className="toggle"/>
            </div> */}
            <div className="onboarding-toggle">
              <Checkbox toggle checked={personalEmails} onChange={()=>setPersonalEmails(!personalEmails)} label="Can we email you personally?" className="toggle"/>
            </div>

            {/* <div className="onboarding-toggle">
              <Checkbox
                toggle
                checked={browserNotificationsSwitch}
                onChange={() => setBrowserNotificationsSwitch(!browserNotificationsSwitch)}
                label="Get web browser notifications when an event is cancelled"
                className="toggle"
              />
            </div> */}

            <div className="onboarding-toggle">
              <Checkbox
                toggle
                checked={whatsapp}
                onChange={() => setWhatsapp(!whatsapp)}
                label="Stay in touch with our team on Whatsapp"
                className="toggle"
              />
            </div>

            
            {config.thankYouVideo && (
              <div className="player-limiter">
                <div className="player-wrapper">
                  <ReactPlayer
                    url={config.thankYouVideo}
                    width='100%'
                    height='100%'
                    autoplay
                    controls
                    muted
                    playsinline
                  />
                </div>
              </div>
            )}



            <Button primary onClick={onContinue}>
              Continue
            </Button>


          </div>


        </div>
      </Container>
    </>
  );
}

export default ThankYou;
