import React from "react";
import { Route, Redirect } from "react-router-dom";

import authenticationService from "../../_services/authentication.service";

const AdminPrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = authenticationService.currentUserValue;
      if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      }
      // Checks if route is restricted by role
      if (roles && roles.indexOf(currentUser.role) === -1) {
        // role not authorized so redirect to home page
        return <Redirect to={{ pathname: "/" }} />;
      }

      // check if route is restricted by role
      if (!currentUser.isSMSVerified) {
        // role not authorized so redirect to home page
        return <Redirect to={{ pathname: "/onboarding/sms" }} />;
      }
      // check if route is restricted by role
      if (!currentUser.isEmailVerified) {
        // role not authorized so redirect to home page
        return <Redirect to={{ pathname: "/onboarding/email" }} />;
      }

      if(currentUser && currentUser.role !== 'Admin'){
        return <Redirect to={{ pathname: "/" }} />
      }
      // authorized so return component
      return <Component {...props} />;
    }}
  />
);

export default AdminPrivateRoute;
