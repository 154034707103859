import { getAsync, putAsync } from "../_helpers/adapters";
import config from "../_helpers/config";

export async function getFilters(): Promise<IFiltersData> {
  try {
    var res = await getAsync(`${config.apiUrl}/user-preferences/filters/`);
    return res;
  } catch (err) {
    throw err;
  }
}


export async function setFilters(filters: IFiltersData): Promise<void> {
  try {
    await putAsync(`${config.apiUrl}/user-preferences/filters/`, filters);
  } catch (err) {
    throw err;
  }
}