import { useEffect, useState } from "react";
import { Container, Header, Table } from "semantic-ui-react";
import loadingStore from "../../stores/loading-store";
import { getOrganizerSessionPageViewsAnalytics } from "../../_services/analytics.service";
import _ from "lodash";

const OrgAnalytics8 = () => {
    const [organizers, setOrganizers] = useState([]);

    useEffect(() => {
        loadingStore.setPageMaskLoading();
        getOrganizerSessionPageViewsAnalytics().then(data => {
            setOrganizers(_.reverse(_.sortBy(data, 'invites')));
        }).finally(() => {
            loadingStore.setPageMaskLoaded();
        });
    }, []);

    return (
        <Container className="analytics-container">
            <Header as="h1">
                <Header.Content>
                    Invites count for each organizer.
                </Header.Content>
            </Header>
            <Table className="analytics-table" inverted celled singleLine unstackable striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Organizer
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Invites
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Joined
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {organizers.map(({name, _id, invites, joined}) =>
                        <Table.Row key={_id}>
                            <Table.Cell>{name}</Table.Cell>
                            <Table.Cell>{invites.toFixed(2)}</Table.Cell>
                            <Table.Cell>{joined.toFixed(2)}</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </Container>
    );
}

export default OrgAnalytics8;