import { notify } from 'react-notify-toast';

export default {
  error(text, opts = {}) {
    const type = opts.type || 'error';
    const timeout = opts.timeout || 3000;
    const color = opts.color || { background: 'red', text: 'white' };
    notify.show(text, type, timeout, color);
  },
  success(text, opts = {}) {
    const type = opts.type || 'success';
    const timeout = opts.timeout || 3000;
    const color = opts.color || { background: 'green', text: 'white' };
    notify.show(text, type, timeout, color);
  },
};
