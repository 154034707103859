import runValidation from "./validation";
import storage from "./storage";
import history from "./history";
import hermes from "./hermes";
import _slugify from "slugify";
// browser detector
import Bowser from "bowser";
import moment from "moment";

const isDesktop =
  Bowser.parse(window.navigator.userAgent).platform.type === "desktop";

// to capitalize
function capitalize(s) {
  if (s) {
    return s
      .split(" ")
      .map((s) => (s[0] ? s[0].toUpperCase() : "") + s.slice(1))
      .join(" ");
  }
  return s;
}

function parseError(err) {
  return err;
}

function handleError(err) {
  return parseError(err);
}

function createBlob(file) {
  return URL.createObjectURL(file);
}

function slugify(name) {
  return (
    _slugify(name).toLowerCase() || name.toLowerCase().replace(/\s+/g, "-")
  );
}

// to use it under user feed item to generate date
function getOrdinalNum(n) {
  return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
}

// to extract domin url by removing unnessary parts
function domain_from_url(url) {
  var result;
  var match;
  if (
    (match = url.match(
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im
    ))
  ) {
    result = match[1];
    if ((match = result.match(/^[^\.]+\.(.+\..+)$/))) {
      result = match[1];
    }
  }
  return result;
}

// trim char
function trimChar(string, charToRemove) {
  while (string.charAt(0) === charToRemove) {
    string = string.substring(1);
  }

  while (string.charAt(string.length - 1) === charToRemove) {
    string = string.substring(0, string.length - 1);
  }

  return string;
}

const formatDate = date => date ? moment(date).format("MMM Do YYYY hh:mm:ss A") : "-";

function isValidUrl(string) {
  const regex = /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i;
  return regex.test(string);
}

export {
  hermes,
  history,
  storage,
  domain_from_url,
  runValidation,
  handleError,
  slugify,
  capitalize,
  trimChar,
  getOrdinalNum,
  createBlob,
  formatDate,
  isValidUrl
};
