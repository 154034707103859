import { action, observable, makeObservable } from "mobx";
import profileStore from "./profile-store";
import api from "../_services/authentication.service";
import loadingStore from "./loading-store";
import { handleError } from "../utilities";
import * as constants from "../constants";
class SubscriptionStore {
  constructor() {
    makeObservable(this, {
      entities: observable,
      activeView: observable,
      selectedSubMenuItem: observable,
      menuPopupOpen: observable,
      menuClickedFrom768px: observable,
      subscribedToEvents: observable,
      getSubscriptions: action,
      toggleValue: action
    });
  }

  // NOTE: This is never used.
  subscribedToEvents = false;

  loadingSubscriptions = false;
  subscriptions = [];

  // SUGGESTION: rename this property to organizerSubscriptions
  subscribedOrganizers = [];

  // SUGGESTION: rename this property to organizerUnSubscriptions
  unSubscribedOrganizers = [];
  activeView = window.localStorage.getItem("activeView")
    ? window.localStorage.getItem("activeView")
    : "all";
  selectedSubMenuItem = '';
  menuClickedFrom768px = window.localStorage.getItem("menuClickedFrom768px")
    ? window.localStorage.getItem("menuClickedFrom768px")
    : "all";
  menuPopupOpen = false;
  organizers = [];
  events = [];
  entities = {
    filterOrganizers: {
      id: 2,
      title: "Organizers",
      type: "toggle",
      subtype: "filterOrganizers",
      value: true,
    },
    organizers: {
      id: 1,
      title: "Current Organizers",
      type: "organizer",
      subtype: "current",
      hidden: false,
      subscriptions: [],
      placeholder: "Ex: Palo Alto",
      refers: "filterOrganizers",
    },
  };

  // toggle check box boolean value
  toggleValue(type) {
    if (this.entities[type]) {
      this.entities[type].value = !this.entities[type].value;
    }
  }

  // for fetching organizers list for populatin listen page drop down
  async getOrganizers() {
    try {
      loadingStore.setLoading();
      const response = await api.getOrganizers();
      var organizers = response?.organizers;
      if (organizers.length > 0) {
        this.organizers = [];
        for (var i = 0; i < organizers.length; i++) {
          var organizer = organizers[i];
          organizer["key"] = organizer._id;
          organizer["text"] = organizer.name;
          organizer["value"] = organizer;
          //if organizer image is null, use default image
          if (!organizer.image) {
            organizer.image = constants.image;
          }
          this.organizers.push(organizer);
        }
      }
      loadingStore.setLoaded()
      return this.organizers;
    } catch (error) {
      loadingStore.setLoaded();
      throw handleError(error);
    }
  }

  // for getting user subscriptions. List of subscribed events/organizers
  async getSubscriptions() {
    await profileStore.getUserData();
    this.populateState(profileStore.userData.subscriptions);
  }

  /**
   * SUGGESTION: getEvents() and getSubscribedEvents() should be combined into
   * one function and return normal events or subscribed events depending on
   * the user being subscribed to events or not.
   * 
   * That will save one API call. That is made but not used.
   */

  //  fetch all events with organizers
  async getEvents() {
    try {
      loadingStore.setLoading();
      const response = await api.getEvents();
      this.events = response?.events;
      loadingStore.setLoaded();
      return this.events;
    } catch (error) {
      loadingStore.setLoaded();
      throw handleError(error);
    }
  }

  //  fetch all events with organizers
  async getSubscribedEvents() {
    try {
      loadingStore.setLoading();
      const response = await api.getSubscribedEvents();
      this.events = response?.events;
      loadingStore.setLoaded();
      return this.events;
    } catch (error) {
      loadingStore.setLoaded();
      throw handleError(error);
    }
  }

  // fetch all organizers user subscribed to
  // WARNING: the user is only really subscribed to organizers if
  // the 'filterOrganizers' property from user preferences is true
  async getSubscribedOrganizers() {
    try {
      loadingStore.setLoading();
      this.subscribedOrganizers = [];

      //  fetch filter organizers boolean value
      const userPreference = await api.getUserPreference();
      //  fetch subscribed organizers
      var organizers = await api.getSubscribedOrganizers();
      
      var filterOrganizer = userPreference?.filterOrganizer;
      this.entities["filterOrganizers"].value = filterOrganizer;

      if (organizers.length > 0) {
        this.organizers = [];
        for (var i = 0; i < organizers.length; i++) {
          var organizer = organizers[i].organizer;
          organizer["key"] = organizer._id;
          organizer["text"] = organizer.name;
          organizer["value"] = organizer;

          //if organizer image is null, use default image
          if (!organizer.image) {
            organizer.image = constants.image;
          }
          this.subscribedOrganizers.push(organizer);
        }
      }
      loadingStore.setLoaded()
      return this.subscribedOrganizers;
    } catch (error) {
      loadingStore.setLoaded();
      throw handleError(error);
    }
  }

  //  subscribe and unsubscribe all events with organizers
  // SUGGESTION: rename this to saveSubscriptions()
  async subscribeOrganizers() {
    // SUGGESTION: rename these to organizerSubscriptions and organizerUnSubscriptions respectively
    var subscribedOrganizers = [];
    var unSubscribedOrganizers = [];

    // populate organizer id's
    for (var i = 0; i < this.subscribedOrganizers.length; i++) {
      subscribedOrganizers.push(this.subscribedOrganizers[i]?._id);
    }
    for (var i = 0; i < this.unSubscribedOrganizers.length; i++) {
      unSubscribedOrganizers.push(this.unSubscribedOrganizers[i]?._id);
    }
    try {
      loadingStore.setLoading();

      //  update filter organizers boolean value
      const userPreference = await api.updateUserPreference({
        filterOrganizer: this.entities["filterOrganizers"].value,
      });

      // update subscribed organizers
      const response = await api.subscribeOrganizers(
        subscribedOrganizers,
        unSubscribedOrganizers
      );

      loadingStore.setLoaded();
      return response;
    } catch (error) {
      loadingStore.setLoaded();
      throw handleError(error);
    }
  }
}

export default new SubscriptionStore();
