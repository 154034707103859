import { Link } from "react-router-dom";
import { WhatsappShareButton } from "react-share";
import { Container, Header } from "semantic-ui-react";
import LinkWithLoader from "../../components/link-with-loader";
import FeaturedEvent from "../../components/subscription/featured-event";
import { WHATSAPP_MESSAGE } from "../../constants";

import featured from "../../featured"
const featuredEvents = featured.events;


const LinksPage = () => (
    <Container className="links-container">

        <div className="links">
            <section>
                <span className="title">
                    <span>Contact Us</span>
                </span>
            </section>

            <div className="links-list">

                <div
                    className="site-info-row"
                >
                    <a
                        className="site-info"
                        href="https://wa.me/32496608026"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-whatsapp" />
                        <span>Whatsapp</span>
                    </a>
                </div>

                <div className="site-info-row">

                    <a
                        className="site-info"
                        href="https://instagram.com/leuveneventplanner"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fa fa-instagram" />
                        <span>Instagram</span>
                    </a>
                </div>

            </div>


            {/* {featuredEvents.length > 0 && (
              <div className={FEATURED_ON_TOP ? "hidden" : ""}>
                <section>
                  <span className="title">
                    <span>Featured</span>
                  </span>
                </section>

                <div className="links-list">
                  {featuredEvents.map(event => (
                    <FeaturedEvent key={event._id} {...event} />
                  ))}
                </div>
              </div>
            )} */}

            <section>
              <span className="title">
                <span>Help</span>
              </span>
            </section>

            <div className="links-list">
              {/* <div className="site-info-row">
                <Link to="/walkthrough" className="site-info" >
                  <i className="fa fa-info-circle" />
                  <span>Walkthrough</span>
                </Link>
              </div> */}

              <div className="site-info-row">
                <Link to="/report-bug" className="site-info">
                  <i className="fa fa-pencil" />
                  <span>Report Bug</span>
                </Link>
              </div>
            </div>


            <section>
                <span className="title">
                    <span>About</span>
                </span>
            </section>

            <div className="links-list">

                <div className="site-info-row">
                    <a
                        className="site-info"
                    >
                        <i className="fab fa-whatsapp" />
                        <WhatsappShareButton
                            className="site-info"
                            title={WHATSAPP_MESSAGE}
                            separator=" "
                            url="https://www.leuveneventplanner.com"
                        ><span>Share On Whatsapp</span>
                        </WhatsappShareButton>
                    </a>
                </div>


                <div className="site-info-row">
                    <Link to="/terms" className="site-info">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z" />
                        </svg>
                        <span>Terms</span>
                    </Link>

                </div>

            </div>

        </div>
    </Container>
)

export default LinksPage;