import React, { useState, useEffect } from 'react';
import { withFormik } from 'formik';
import { withRouter } from 'react-router';
import { Container, Header, Form, Input, Segment, Button, TextArea } from 'semantic-ui-react';
import { hermes } from '../../utilities';
import { errors, success } from '../../messages';
import LinkWithLoader from '../../components/link-with-loader';
import { reportBug } from '../../_services/bugs.service';
import { goTo } from '../../routing';


const ReportBug = ({ history }) => {
  const [browser, setBrowser] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = async (e) => {
    
    if (!browser) {
      hermes.error(errors.MISSING_BROWSER_INFO);
      return;
    }

    if (!description) {
      hermes.error(errors.MISSING_BUG_DESCRIPTION);
      return;
    }

    try {
      await reportBug({
        browser,
        description
      });
      hermes.success(success.BUG_REPORT_SUBMITTED);
      goTo('/', history);
    }
    catch (error) {
      hermes.error(error);
    }
  };

  return (
    <div className="form-template-wrapper">
      <Container className="form-template report-bug">
        <Header as="h2">Report Bug</Header>

        <Segment>
          <Form onSubmit={handleSubmit}>
            <div className="form-section">
              <Form.Field>
                <label>Browser name and version</label>
                <Input
                  name="browser"
                  value={browser}
                  onChange={(e) => setBrowser(e.target.value)}
                  placeholder="Ex: Safari Version 12.1"
                />
              </Form.Field>
              <Form.Field>
                <label>Describe bug</label>
                <TextArea
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Description"
                />
              </Form.Field>
            </div>
            <Button primary type="submit">Save</Button>
            <LinkWithLoader to='/' basic button>Cancel</LinkWithLoader>
          </Form>
        </Segment>
      </Container>
    </div>
  );
}

export default ReportBug;