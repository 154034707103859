import { useState } from 'react';
import { Container, Header, Table, Button, Checkbox } from 'semantic-ui-react';
import { EditOrganizerRow } from './EditOrganizerRow';
import OrganizerRow from './OrganizerRow';
import useAdminOrganizers, { AdminOrganizersContextProvider } from './useAdminOrganizers';
import style from './AdminOrganizers.module.css';
import styles from './AdminOrganizers.module.css';
import useOrganizerSuggestions from './useOrganizerSuggestions';
import SuggestionRow from './SuggestionRow';

function AdminOrganizersPage() {
  const { organizers, suggestions } = useAdminOrganizers();

  const [adding, setAdding] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);

  console.log(suggestions);
  
  return (
    <div className={styles.container}>
      <Header>
        <h1>Organizers</h1>
        <Button primary onClick={() => setAdding(true)}>Add Organizer</Button>
        {!!suggestions.length && <Checkbox toggle label="Show Suggestions" checked={showSuggestions} onChange={() => setShowSuggestions(!showSuggestions)} />}
      </Header>
      <Table inverted striped>
        {showSuggestions && !!suggestions.length && (
          <>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colspan={2}>Organizer Name</Table.HeaderCell>
                <Table.HeaderCell colspan={6}>Suggested By</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {suggestions.map(suggestion => (
                <SuggestionRow key={suggestion._id} suggestion={suggestion} />
              ))}
            </Table.Body>
          </>
        )}
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>Image</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Link</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell singleLine>Show All Events</Table.HeaderCell>
            <Table.HeaderCell singleLine colspan={3}>Show All Event Info</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {adding && <EditOrganizerRow exit={() => setAdding(false)} add/>}
          {organizers.map(organizer => (
            <OrganizerRow key={organizer._id} organizer={organizer} />
          ))}
        </Table.Body>
      </Table>

    </div>
  );
}

export default function AdminOrganizers() {
  return (
    <AdminOrganizersContextProvider>
      <AdminOrganizersPage />
    </AdminOrganizersContextProvider>
  );
}; 