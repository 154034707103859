import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Progress, Container, Form, Card, Icon } from 'semantic-ui-react'
import { MIN_INVITES } from '../../constants'
import loadingStore from '../../stores/loading-store'
import { hermes } from '../../utilities'
import config from '../../_helpers/config'
import authenticationService from '../../_services/authentication.service'
import { createInvites } from '../../_services/invites.service'

const initialInvite: IRawInvite = {
  firstName: '',
  lastName: '',
}

const initialInviteState: IRawInvite[] = [];

for (let i = 0; i < MIN_INVITES; i++) {
  initialInviteState.push({...initialInvite})
}

export default function OnboardingInvites() {
  const [invites, setInvites] = useState(initialInviteState)
  const history = useHistory();

  useEffect(() => {
    loadingStore.setLoaded()
    loadingStore.setPageMaskLoaded()
  }, [])

  const setInviteFirstName = (index: number, name: string) => {
    setInvites(invites => {
      invites[index].firstName = name
      return [...invites]
    })
  }

  const setInviteLastName = (index: number, name: string) => {
    setInvites(invites => {
      invites[index].lastName = name
      return [...invites]
    })
  }
  
  const addInvite = () => {
    setInvites(invites => [...invites, {...initialInvite}])
  }

  const removeInvite = (index: number) => {
    if (invites.length <= MIN_INVITES) {
      hermes.error(`You must have at least ${MIN_INVITES} invites`)
      return;
    }

    setInvites(invites => {
      invites.splice(index, 1)
      return [...invites]
    })
  } 

  const handleContinue = async () => {
    
    // Check if any invite is half filled
    if (invites.some(invite => !!invite.firstName !== !!invite.lastName)) {
      hermes.error('You must fill out all invites');
      return
    }
    
    const filledInvites = invites.filter(invite => !!invite.firstName && !!invite.lastName)
    // Check if the number of filled invites is less than the minimum
    if (filledInvites.length < MIN_INVITES) {
      hermes.error(`You must invite at least ${MIN_INVITES} people`);
      return;
    }
    
    // Check for duplicates
    const invitesSet = new Set(filledInvites.map(invite => invite.firstName + invite.lastName))
    if (invitesSet.size !== filledInvites.length) {
      hermes.error('You cannot invite the same person twice');
      return;
    }

    loadingStore.setLoading()
    
    await createInvites(filledInvites);
    authenticationService.updateUserSubject({ invites: filledInvites.length })
    hermes.success("Invites submitted!");
    loadingStore.setLoaded()

    loadingStore.setPageMaskLoading()
    let nextPage = config.isOrganizerPageRequired() ? "/onboarding/subscribe" : "/onboarding/install";
    history.push(nextPage);
  }

  return (
    <>
      <Progress percent={50} className="onboarding-progress-bar" />
      <Container fluid className='invites'>
        <div>
          <div className="onboarding-5-container">
            <div className="onboarding-heading">Invite some friends</div>
            <div className="onboarding-sub-heading ">
              Invite your friends to join you on the platform
            </div>

            <div className="onboarding-column">
              <Form onSubmit={handleContinue}>
                <Card className="onboarding-form-wrapper">
                  {invites.map((invite, index) => (
                    <Form.Field
                      key={index}
                      className="onboarding-form-field"
                    >
                      {/* [firstname].[lastname]@student.kuleuven.be */}
                      {/* <label>Invite {index + 1}</label> */}

                      <div className="onboarding-form-field-inputs-wrapper">
                      <input
                        placeholder="First name"
                        value={invite.firstName}
                        onChange={(e) => setInviteFirstName(index, e.target.value)}
                        onBlur={(e) => setInviteFirstName(index, e.target.value.trim())}
                      />
                      <span>.</span>
                      <input
                        placeholder="Last name"
                        value={invite.lastName}
                        onChange={(e) => setInviteLastName(index, e.target.value)}
                        onBlur={(e) => setInviteLastName(index, e.target.value.trim())}
                      />
                      <span>
                        @student.kuleuven.be
                      </span>
                    
                      <span
                        className={
                          `remove-btn ${invites.length <= MIN_INVITES? 'hidden' : ''}`
                        }
                        // color='red'
                        // name="close"
                        onClick={
                          invites.length > MIN_INVITES ? 
                          () => removeInvite(index) :
                          undefined
                        }
                        >
                          {'×'}
                        </span>
                      </div>
                    </Form.Field>
                  ))}
                </Card>
                <Button primary type="button" onClick={addInvite}>Add invite</Button>
                <Button primary type="submit">
                  Continue
                </Button>
              </Form>
            </div>
          </div>
        </div>

      </Container>
    </>
  )
}