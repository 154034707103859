import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { getSeenHints, trackHint } from "../_services/walkthrough.service";

interface IWalkThroughContext {
  current: string;
  goToNext: () => void;
  text: React.ReactNode;
}

const WalkThroughContext = createContext<IWalkThroughContext | null>(null);

const useWalkThrough = () => {
  const context = useContext(WalkThroughContext);
  if (context === null) {
    throw new Error("useWalkThrough must be used within a WalkThroughProvider");
  }
  return context;
}

const hints = [
  {
    name: 'subscribe button',
    text: "Find and subscribe to organisations you like",
    path: '/'
  },
  {
    name: 'subscriptions organizer',
    text: 'Click on an organisation then click save',
    path: '/subscribe'
  },
  {
    name: 'subscribed tab',
    text: "Only events from organisations you like here",
    path: '/'
  },
  {
    name: 'discover tab',
    text: "See every event here",
    path: '/'
  },
  {
    name: 'later',
    text: "See events happening after next week",
    path: '/'
  },
  {
    name: 'add to calendar',
    text: 'Add events to your Google, Apple and Outlook calendar',
    path: '/'
  },
]

export const WalkThroughProvider: React.FC = ({ children }) => {
  const [current, setCurrent] = useState('');
  const [hintsLeft, setHintsLeft] = useState(hints);
  const seenHints = useRef<string[]>(['INITIAL']);
  const { pathname } = useLocation();

  const checkHintsLeft = () => {
    setCurrent('');

    const left = hints.filter(hint => !seenHints.current.some(
      seen => seen === hint.name) && hint.path === pathname
    );

    const next = left.shift();
    if (next) {
      setCurrent(next.name);
    } else {
      setCurrent('');
    }
    setHintsLeft(left);
  }


  useEffect(() => {
    if (seenHints.current[0] !== 'INITIAL') {
      setTimeout(() => {
        checkHintsLeft();
      }, 1000);
    }
  }, [pathname]);

  useEffect(() => {
    const setupHints = async () => {
      const _seenHints = await getSeenHints();
      seenHints.current = _seenHints || [];

      checkHintsLeft();
    }

    setupHints();
  }, []);


  const goToNext = () => {
    trackHint(current);
    if (!seenHints.current.includes(current)) {
      seenHints.current.push(current);
    }
    setCurrent('')
    const nextHint = hintsLeft.shift();
    if (nextHint) {
      setHintsLeft([...hintsLeft]);
      setTimeout(() => {
        // Use location from window object to get updated pathname if redirected
        if (nextHint.path === window.location.pathname) {
          setCurrent(nextHint.name);
        }
      }, 500);
    }
  }

  const text = current ? hints.find(h => h.name === current)?.text || '' : '';

  return (
    <WalkThroughContext.Provider value={{
      current,
      text,
      goToNext,
    }}>
      {children}
    </WalkThroughContext.Provider>
  )
}

export default useWalkThrough;