import { deleteAsync, getAsync, postAsync, putAsync } from "../_helpers/adapters";
import config from "../_helpers/config";

const baseUrl = `${config.apiUrl}/invites`;

export const createInvites = (data: IRawInvite[]) =>
  postAsync(baseUrl, data);

export const getInvites = () =>
  getAsync(baseUrl);

export const getInviteLogs = () =>
  getAsync(`${baseUrl}/logs`);

export const updateInvite = ({ _id, firstName, lastName, email }: IInvite) =>
  putAsync(`${baseUrl}/${_id}`, { firstName, lastName, email });

export const approveInvite = (id: string) =>
  putAsync(`${baseUrl}/${id}/approve`);

export const deleteInvite = (id: string) =>
  deleteAsync(`${baseUrl}/${id}`);


