import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { Component, KeyboardEvent, SyntheticEvent, useRef } from "react";
import {  DropdownProps, Form, Label } from "semantic-ui-react";
import useWalkThrough from "../../hooks/useWalkThrough";
import DropdownWithSuggestion from "../shared/DropdownWithSuggestion";
import HintPopup from "../shared/HintPopup";

// Add others if any
type Entity = IOrganizer;

const cropImage = (image: string) => {
  return (image || '').replace('/upload/', '/upload/c_fill,h_256/');
}

interface SelectedEntityProps {
  id: string;
  name: string;
  type: string;
  remove: (type: string, id: string) => void;
}

const SelectedEntity = ({ id, name, type, remove }: SelectedEntityProps) => {
  return (
    <Label className="entity-label" basic>
      {name}
      <div
        onClick={() => {
          remove(type, id);
        }}
        className="remove"
      >
        <span>x</span>
      </div>
    </Label>
  );
};

interface EntitySubscriptionItemProps {
  entityData: {
    entities: Entity[];
    selectedEntities: Entity[];
    placeholder: string;
    type: string;
  };
  suggestAddition?: (type: string, id: string) => void;
  addEntity: (type: string, id: string) => void;
  removeEntity: (type: string, id: string) => void;
}

const EntitySubscriptionItem = ({ entityData, addEntity, removeEntity, suggestAddition }: EntitySubscriptionItemProps) => {
  const { entities, selectedEntities, type, placeholder } = entityData;
  const { current, goToNext } = useWalkThrough()

  const dropdownRef = useRef<Component<DropdownProps, any, any>>(null);
  const resetIndex = () => {
    dropdownRef.current?.setState({
      selectedIndex: 0,
    });
  };

  // const dropdownInnerRef = useRef<Component<DropdownProps

  type Handler = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  const handleChange: Handler = (e, { value: option }) => {

    console.log('handleChange', option);
    const { key } = e as KeyboardEvent<HTMLElement>;    

    if (e.type === 'keydown' && key !== 'Enter') {
      return;
    }

    if (!option) {
      return;
    }

    if (type === 'blur') {
      return;
    }

    addEntity(type, option as string);

    resetIndex();
  };

  console.log(entities);

  const options = entities.map(entity => ({
    key: entity._id,
    text: entity.name,
    value: entity._id,
    "data-id": entity._id,
    image: cropImage(entity.image) || "https://via.placeholder.com/150",
    description: entity.description,
  }));

  console.log(`subscriptions ${type}`);

  return (
      <>
        <Form.Field required>
          <HintPopup
            name={`subscriptions ${type}`}
            title="Later events"
            action="Next"
            containerFitWidth
            closeOnClickAway
            onClick={() => {}}
            verticalOffset={0}
            position="bottom center"
          >
            <DropdownWithSuggestion
              options={options}
              // inputRef={dropdownRef}
              placeholder={placeholder}
              onChange={handleChange}
              onFocus={() => { 
                if (current === `subscriptions ${type}`) {
                  goToNext();
                }
               }}
              additionLabel={`Suggest `}
              onAddItem={(e, { value }) => {
                suggestAddition?.(type, value as string);
              }}
            />
          </HintPopup>
        </Form.Field>

        {/* show list of subscribed organizers */}
        <div className="entities-wrapper">
          {selectedEntities.map(entity => (
            <SelectedEntity
              id={entity._id}
              name={entity.name}
              key={entity._id}
              type={type}
              remove={removeEntity}
            />
          ))}
        </div>
      </>
  )
}

export default inject(
  'subscriptionStore',
  'loadingStore'
)(observer(EntitySubscriptionItem));