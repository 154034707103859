import { action, observable, makeObservable } from "mobx";
import { storage, history, handleError } from "../utilities";
import api from "../_services/authentication.service";
import profileStore from "./profile-store";
import loadingStore from "./loading-store";
import * as constants from "../constants";
import { goTo } from "../routing";
import jwtDecode from "jwt-decode";

class AuthStore {
  constructor() {
    this.load();
    this.logout = this.logout.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.syncLoggedInStatus = this.syncLoggedInStatus.bind(this);

    makeObservable(this, {
      isLoggedIn: observable,
      isPublicOrgPage: observable,
      setIsPublicOrgPage: action,
      syncLoggedInStatus: action,
      eventPlannerCurrentUser: observable,
      signUp: action,
      loginUser: action,
      logout: action,
      resetValues: action,
      syncUser: action,
      syncToken: action,
    });
  }

  isLoggedIn = false;
  isPublicOrgPage = false;
  eventPlannerAuthenticationToken = null;
  facebookConfirmationDone = true;
  facebookDetails = null;
  eventPlannerCurrentUser = null;

  // called when auth store initiate
  load() {
    const user = storage.loadState(constants.eventPlannerCurrentUser);
    const token = storage.loadState(constants.token);
    const isLoggedIn = storage.loadState(constants.isLoggedIn);
    this.syncLoggedInStatus(isLoggedIn);
    this.syncToken(token);
    this.syncUser(user);
  }

  // Resets populated values
  resetValues() {
    this.isLoggedIn = false;
    this.eventPlannerAuthenticationToken= null;
    this.facebookConfirmationDone = true;
    this.facebookDetails = null;
    this.eventPlannerCurrentUser = null;
    loadingStore.setLoaded();
  }

  // Sets isPublicOrgPage
  setIsPublicOrgPage = (value) => {
    this.isPublicOrgPage = value;
  }

  // Syncs taken when an api responded with token
  syncToken(token) {
    if (token) {
      storage.updateState(constants.token, token);
      this.eventPlannerAuthenticationToken = token;
    }
  }

  // Syncs user data when an api responded with user data
  syncUser(user) {
    if (user) {
      this.eventPlannerCurrentUser = user;
      storage.updateState(constants.eventPlannerCurrentUser, user);
    }
  }

  // Syncs logged in status when user logs in or out
  syncLoggedInStatus(status) {
    status = status || false;
    storage.updateState(constants.isLoggedIn, status);
    this.isLoggedIn = status;
    console.log(this.isLoggedIn);
  }

  // Sign up user data, calls endpoint with user detail and sync user data and token
  async signUp(details) {
    const sessionId = sessionStorage.getItem("sessionId") || localStorage.getItem("sessionId") || undefined;

    try {
      loadingStore.setLoading();
      const response = await api.register(
        details.fullName,
        details.email,
        details.password,
        // details.agreedToTerms,
        sessionId
      );

      var user = jwtDecode(response);
      this.syncUser(user);
      this.syncToken(response);
      this.syncLoggedInStatus(true);
      loadingStore.setLoaded();
      return response;
    } catch (error) {
      loadingStore.setLoaded();
      throw handleError(error);
    }
  }

  // Logs in user, calls an api endpoint with user detail and on success, syncs user data and token
  async loginUser(details) {
    try {
      loadingStore.setLoading();
      const response = await api.login(details.email, details.password);

      var user = jwtDecode(response);
      this.syncUser(user);
      this.syncToken(response);

      loadingStore.setLoaded();
      return user;
    } catch (error) {
      loadingStore.setLoaded();
      throw handleError(error);
    }
  }

  // Logs out user
  logout() {
    loadingStore.setLoading();
    this.resetValues();
    profileStore.userData = null;
    storage.clearStorage();
    window.location.href = "/";
    loadingStore.setLoaded();
    loadingStore.setPageMaskLoaded()
  }
}

export default new AuthStore();
