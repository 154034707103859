import useSWR from "swr";
import { getInviteLogs } from "../../_services/invites.service";
import { Dropdown, Input, Table } from "semantic-ui-react";
import styles from "./AdminInvites.module.css";
import { parseInviteLogs, formatDate, sortFunctions, sortOptions, logsFilterFunctions, logsFilterOptions } from "./helpers";
import { useState } from "react";


export default function AdminInviteLogs() {
  const { data, error } = useSWR<IInviteLog[]>("invites-log", getInviteLogs);
  const [filter, setFilter] = useState("all");
  const [sort, setSort] = useState("createdAt");
  const [userSearch, setUserSearch] = useState("");

  const logs = parseInviteLogs(data || [])
    .filter(logsFilterFunctions[filter])
    .filter(log => log.user.fullName.toLowerCase().includes(userSearch.toLowerCase()))
    .sort(sortFunctions[sort]);
 
  return (
    <div className={styles.container}>
      <h1>Audit Logs</h1>

      <div className={styles.filters}>
        <div className={styles.dropdownWrapper}>
          <span>Sort by:</span>
          <Dropdown
            className={styles.sortDropdown}
            options={sortOptions}
            placeholder="Sort by"
            search
            selection
            value={sort}
            onChange={(e, { value }) => setSort(value as string)}
          />
        </div>

        <div className={styles.dropdownWrapper}>
          <span>Filter:</span>
          <Dropdown
            className={styles.sortDropdown}
            options={logsFilterOptions}
            placeholder="Filter by"
            search
            selection
            value={filter}
            onChange={(e, { value }) => setFilter(value as string)}
          />
        </div>
      
        <div className={styles.searchWrapper}>
            <span>Search:</span>
            <Input
                className={styles.searchInput}
                placeholder="Search"
                value={userSearch}
                onChange={(e) => setUserSearch(e.target.value)}
            />
        </div>

      </div>

      <Table inverted striped unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Action</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Invited</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {logs.map(({ _id, action, user, email, createdAt}) => (
            <Table.Row key={_id}>
              <Table.Cell>{action}</Table.Cell>
              <Table.Cell>{user.fullName}</Table.Cell>
              <Table.Cell>{email}</Table.Cell>
              <Table.Cell>{formatDate(createdAt)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>

  );
}


