import { useEffect, useState } from "react";
import { DropdownItemProps } from "semantic-ui-react";
import { getAuditLogs } from "../../_services/audit-logs.service";
import { FilterFn, IAuditLog, IOrganizer, IUser } from "./types";
import authApi from "../../_services/authentication.service";
import { isEvent, isOrganizer, parseLogs, sortFunctions } from "./helpers";

export default function useAuditLogs() {
  const [logs, setLogs] = useState<IAuditLog[]>([]);
  const [_logs, _setLogs] = useState<IAuditLog[]>([]);
  const [sort, setSort] = useState('date');
  const [actionFilter, setActionFilter] = useState('all');
  const [organizerFilter, setOrganizerFilter] = useState('all');
  const [organizerOptions, setOrganizerOptions] = useState<DropdownItemProps[]>([]);
  const [userFilter, setUserFilter] = useState('all');
  const [userOptions, setUserOptions] = useState<DropdownItemProps[]>([]);

  useEffect(() => {
    Promise.all([
      getAuditLogs(),
      authApi.getOrganizers().then(
        ({ organizers }: { organizers: IOrganizer[] }) => organizers.map(
          ({ _id, name }) => ({ name, _id })
        )
      ),
    ])
      .then(([logs, allOrganizers]) => {
        const parsedLogs = parseLogs(logs, allOrganizers);
        const _organizers: Set<string> = new Set();
        const _users: Set<string> = new Set();

        parsedLogs.forEach(log => {
          _users.add(JSON.stringify(log.user));
          if (isEvent(log.object)) {
            _organizers.add(JSON.stringify(log.object.organizer));
          } else if (isOrganizer(log.object)) {
            _organizers.add(JSON.stringify(log.object));
          }
        });

        const organizers: IOrganizer[] = [..._organizers].map(o => JSON.parse(o));
        const users: IUser[] = [..._users].map(u => JSON.parse(u));

        console.log(organizers);

        setOrganizerOptions([
          { text: 'All', value: 'all' },
          ...organizers.map(o => ({ text: o.name, value: o._id }))
        ])

        setUserOptions([
          { text: 'All', value: 'all' },
          ...users.map(u => ({ text: u.fullName, value: u._id }))
        ]);
        _setLogs(parsedLogs);
      });
  }, []);

  function filterCombined(...filterFn: FilterFn[]): FilterFn {
    return (log: IAuditLog) => filterFn.every(fn => fn(log));
  }

  useEffect(() => {
    const filters = [];
    if (actionFilter !== 'all')
      filters.push((log: IAuditLog) => log.action === actionFilter);
    if (organizerFilter !== 'all')
      filters.push(
        (log: IAuditLog) => {
          if (isEvent(log.object))
            return log.object.organizer._id === organizerFilter;
          else if (isOrganizer(log.object))
            return log.object._id === organizerFilter;
          return false;
        }
      );
    if (userFilter !== 'all')
      filters.push((log: IAuditLog) => log.user._id === userFilter);

    const filtered = _logs.filter(filterCombined(...filters));
    const sorted = filtered.sort(sortFunctions[sort]);
    setLogs(sorted);
  }, [_logs, sort, actionFilter, organizerFilter, userFilter]);

  return {
    logs,
    sort,
    setSort,
    actionFilter,
    setActionFilter,
    organizerFilter,
    setOrganizerFilter,
    organizerOptions,
    userFilter,
    setUserFilter,
    userOptions,
  }
}
