import moment from 'moment';
import useSWR from 'swr';
import { hermes } from '../utilities';
import api from '../_services/authentication.service';


interface useEventsReturn {
  events: IEvent[];
  isLoading: boolean;
}

export default function useEvents(): useEventsReturn {
  const { data, error } = useSWR('events', api.getEvents);

  if (error) {
    hermes.error("Error fetching events");
    return {
      events: [],
      isLoading: false
    }

  } else if (!data) {
    return {
      events: [],
      isLoading: true,
    }
  }

  const events = data.events as IEvent[];

  return {
    events: events.map((event) => ({
      ...event,
      startDate: moment(event.startDate),
      endDate: moment(event.endDate),
      updatedAt: moment(event.updatedAt),
      createdAt: moment(event.createdAt),
    })),
    isLoading: false,
  }
}