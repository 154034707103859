import config from "../_helpers/config";
import { getAsync, putAsync } from "../_helpers/adapters";


export async function setPersonLanguage({_id, language}) {
  return putAsync(`${config.apiUrl}/admin/language`, {
    _id,
    language,
  });
}
