import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { hermes } from "../../utilities";
import authenticationService from "../../_services/authentication.service";
import { Container } from "semantic-ui-react";
import SettingsForm from "./settings-form";
import "../../css/settings.css";
import { errors, success } from "../../messages";
import nanobar from "../../components/Nanobar";
import loadingStore from "../../stores/loading-store";
import { mutate } from "swr";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      uploadingImage: false,
      sendingResetLink: false,
      newsletter: false,
      personalEmails: false,
      whatsapp: false,
      loading: true,
    };
    this.sendResetEmail = this.sendResetEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleE = this.handleSubmit.bind(this);
    this.getPreferences = this.getPreferences.bind(this);
  }

  componentDidMount() {
    this.props.profileStore.getUserData();
    this.getPreferences();
  }

  // Setting update form submission. Checks password length, send the profile data to api endpoint, and fetch updated profile data
  async handleSubmit(data) {
    const { profileStore } = this.props;
    const { history } = this.props;
    
    if (data.currentPassword) {
      if (data.currentPassword.trim().length < 8) {
        hermes.error(errors.PASSWORD_TOO_SHORT);
        return;
      }
    }

    try {
      let profile = {
        fullName: data.fullName,
        email: data.email,
        emailVerificationCode: data.emailVerificationCode
          ? data.emailVerificationCode
          : undefined,
        phoneNumber: data.phoneNumber,
        instagram: data.instagram,
        phoneNumberVerificationCode: data.verificationCode
          ? data.verificationCode
          : undefined,
        currentPassword: data.currentPassword,
        newPassword: data.newPassword ? data.newPassword : undefined,
      };
      await profileStore.updateProfile(profile);
      await authenticationService.updateUserPreference({
        newsletter: data.newsletter,
        whatsapp: data.whatsapp,
        personalEmails: data.personalEmails,
        browserNotificationsSwitch: data.browserNotificationsSwitch,
      });
      await mutate("preferences");
      await profileStore.getUserData();
    } catch (error) {
      return hermes.error(error);
    }

    // take note of the return in the catch block
    hermes.success(success.SETTINGS_UPDATED);
    history.push("/");
  }

  handleImageChange = (e) => {
    const fileblob = e.target.files[0];
    this.setState({
      selectedFile: fileblob,
    });
  };

  handleCancel = (e) => {
    const { history } = this.props;
    history.push("/");
  };

  /**
   *  handle forgot password send reset password  link to the specified email
    
   */
  async sendResetEmail() {
    this.setState({ sendingResetLink: true });
    try {
      await authenticationService.passwordResetInfo(
        this.props.profileStore.userData.email
      );
      await this.setState({ sendingResetLink: false });
    } catch (e) {
      await this.setState({ sendingResetLink: false });
      hermes.error(errors.FAILED_SEND_PASSWORD_EMAIL);
    }
    return true;
  }


  async getPreferences() {
    nanobar.go(30);
    try {
      loadingStore.setLoading();
      const data = await authenticationService.getUserPreference();
      this.setState({
        newsletter: data.newsletter,
        personalEmails: data.personalEmails,
        whatsapp: data.whatsapp,
        browserNotificationsSwitch: data.browserNotificationsSwitch,
        loading: false,
      })
      loadingStore.setLoaded();
    } catch (error) {
      hermes.error("Error loading user preferences");
      console.error(error);
    }
    nanobar.go(100);
  }

  render() {
    const { profileStore, authStore } = this.props;
    const { selectedFile, sendingResetLink, uploadingImage, newsletter, personalEmails, whatsapp, browserNotificationsSwitch, loading } = this.state;

    if (loading) {
      return null;
    }

    if (!profileStore.userData) {
      return null;
    } else {
      const { inProgress: profileInProgress } = profileStore;
      const { inProgress: authInProgress } = authStore;
      let savingSettingsData = profileInProgress || authInProgress;
      return (
        <div className="form-template-wrapper">
          <Container fluid className="form-template settings">
            <SettingsForm
              uploadingImage={uploadingImage}
              submitData={this.handleSubmit}
              sendingResetLink={sendingResetLink}
              sendResetEmail={this.sendResetEmail}
              selectedImage={selectedFile}
              handleImageChange={this.handleImageChange}
              handleCancel={this.handleCancel}
              sendVerificationCode={profileStore.sendVerificationCode}
              sendEmailVerificationCode={profileStore.sendEmailVerificationCode}
              verifyCode={profileStore.verifyCode}
              verifyEmailCode={profileStore.verifyEmailCode}
              savingSettingsData={savingSettingsData}
              {...profileStore.userData}
              {...{ newsletter, whatsapp, personalEmails, browserNotificationsSwitch }}
            />
          </Container>
        </div>
      );
    }
  }
}

export default inject("profileStore", "authStore")(observer(Settings));
