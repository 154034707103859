import {
  LoadProfile,
  LoadSettings,
  LoadResetPassword,
  LoadVerifyEmail,
} from "./loaders";

const singlePathRoutesMap = {
  settings: LoadSettings,
};

const doublePathRoutesMap = {
  "verify-email": LoadVerifyEmail,
};

const locationRE = /^(\/(?:[\S]+)?)(\?(?:[\S]+=[\S]+)+)?(#[\S])?/;

/**
 * Custom Location Object
 *
 * @typedef {Object} Location
 * @property {String} pathname Location path
 * @property {Object} query Parsed location query
 * @property {String} hash Location's hash
 */
/**
 * @param {String} location Location url to be parsed.
 * @return {Location} Parsed location object.
 */
export function parseLocationURL(location) {
  const match = locationRE.exec(location);

  if (match !== null) {
    const [pathname, query, hash] = match;

    return {
      pathname,
      query: query ? parseQueryString(query) : {},
      hash,
    };
  }
}

/**
 * Parses a query string
 *
 * @param {String} query Query string to be parsed
 * @return {Object} Parsed query
 */
function parseQueryString(query) {
  const parsedQuery = {};

  if (query.startsWith("?")) {
    query = query.slice(1);
  }

  const parameters = query.split("&");

  for (const parameter of parameters) {
    const sp = parameter.split("=");

    // Make sure you only add parameters with name and value
    if (sp.length === 2 && sp[0] && sp[1]) {
      const [name, value] = sp.map((s) => decodeURIComponent(s));
      parsedQuery[name] = value;
    }
  }

  return parsedQuery;
}

/**
 * Get loader function for single path routes
 *
 * @param {*} sp
 */
export function getSinglePathRoute(sp) {
  return singlePathRoutesMap[sp[1]] || LoadProfile;
}

/**
 * Get loader function for double path routes
 *
 * @param {[String]} sp Splited path
 */
export function getDoublePathRoute(sp) {
  if (!sp[2]) {
    return () => {};
  }

  return doublePathRoutesMap[sp[1]] || (() => {});
}

/**
 * Get loader function for triple path routes
 *
 * @param {[String]} sp Splited path
 */
export function getTriplePathRoute(sp) {
  if (sp[1] === "password" && sp[2] === "new" && sp[3]) {
    return LoadResetPassword;
  }

  return () => {};
}
