import config from "../_helpers/config";
import { deleteAsync, getAsync, postAsync, putAsync } from "../_helpers/adapters";

export const createEvent = async (formData) => {
    try {
        const res = await postAsync(`${config.apiUrl}/events/`, formData);
        console.log(res);
        return res
    } catch (err) {
        throw err;
    }
}

export const updateEvent = async (formData, id) => {
    try {
        const res = await putAsync(`${config.apiUrl}/events/${id}`, formData);
        return res
    } catch (err) {
        throw err;
    }
}

export const getEvents = async (page) => {
    try {
        const res = await getAsync(`${config.apiUrl}/events/all-events?page=${page || 1}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const getAllEvents = async () => {
    try {
        const res = await getAsync(`${config.apiUrl}/events/all`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const getEvent = async (id) => {
    try {
        const res = await getAsync(`${config.apiUrl}/events/${id}`);
        return res.event;
    } catch (err) {
        throw err;
    }
}

export const numberOfEvents = async () => {
    try {
        const res = await getAsync(`${config.apiUrl}/events/events-total`);
        return res;;
    } catch (err) {
        throw err;
    }
}

export const getOrganizers = async () => {
    try {
        const res = await getAsync(`${config.apiUrl}/organizers/`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const deleteEvent = async (id) => {
    try {
        const res = await deleteAsync(`${config.apiUrl}/events/${id}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const getEventsByOrganizer = async (id) => {
    try {
        const res = await getAsync(`${config.apiUrl}/events/event-by-organizers/${id}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const approveEventRequest = async (id) => {
    try {
        const res = await postAsync(`${config.apiUrl}/events/request/${id}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const approveDeleteEventRequest = async (id) => {
    try {
        const res = await postAsync(`${config.apiUrl}/events/delete-request/${id}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const updateEventRequest = async (formData, id) => {
    try {
        const res = await putAsync(`${config.apiUrl}/events/request/${id}`, formData);
        return res
    } catch (err) {
        throw err;
    }
}

export const deleteEventRequest = async (id) => {
    try {
        const res = await deleteAsync(`${config.apiUrl}/events/request/${id}`);
        return res;
    } catch (err) {
        throw err;
    }
}