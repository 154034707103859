import { createContext, useContext, useEffect, useState } from "react";
import { IOrganizer } from "./types";
import authApi from "../../_services/authentication.service";
import {
  createOrganizerWithImage,
  updateOrganizer,
  updateOrganizerWithImage,
  deleteOrganizer as _deleteOrganizer,
  approveOrganizer,
  rejectOrganizer
} from "../../_services/organizers.service";
import { hermes } from "../../utilities";
import _ from "lodash";
import useOrganizerSuggestions from "./useOrganizerSuggestions";
import { mutate } from "swr";

const AdminOrganizersContext = createContext<{
  organizers: IOrganizer[];
  suggestions: IOrganizerSuggestion[];
  addOrganizer: (organizer: IOrganizer) => Promise<void>;
  editOrganizer: (organizer: IOrganizer) => Promise<void>;
  deleteOrganizer: (organizer: string) => Promise<void>;
  approveSuggestion: (id: string) => Promise<void>;
  deleteSuggestion: (id: string) => Promise<void>;
}>({
  organizers: [],
  suggestions: [],
  addOrganizer: async () => {},
  deleteOrganizer: async () => {},
  editOrganizer: async () => {},
  approveSuggestion: async () => {},
  deleteSuggestion: async () => {}
});


async function getFileFromBlobURL(url: string, name: string): Promise<File> {
  return fetch(url).then(res => res.blob()).then(blob => new File([blob], `${name}.jpg`));
}

export const AdminOrganizersContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [organizers, setOrganizers] = useState<IOrganizer[]>([]);
  const organizerSuggestions = useOrganizerSuggestions();

  async function addOrganizer(organizer: IOrganizer) {
    try {
      const image = organizer.image.startsWith("blob:") ? await getFileFromBlobURL(organizer.image, organizer.name) : "";
      const payload = new FormData();
      payload.set("name", organizer.name);
      payload.set("url", organizer.url);
      payload.set("image", image);
      payload.set("description", organizer.description);
      payload.set("showAllEvents", (!!organizer.showAllEvents).toString());
      payload.set("showAllEventInfo", (!!organizer.showAllEventInfo).toString());


      const newOrganizer: IOrganizer =  await createOrganizerWithImage(payload);
      setOrganizers([...organizers, newOrganizer]);

      hermes.success(`${newOrganizer.name} has been added`);
    } catch (error) {
      hermes.error("Error creating organizer");
      console.error(error);
    }
  }

  async function editOrganizer(organizer: IOrganizer) {
    try {
      const image = organizer.image.startsWith("blob:") ? await getFileFromBlobURL(organizer.image, organizer.name) : null;
      if (image) {
        const payload = new FormData();
        payload.set("name", organizer.name);
        payload.set("url", organizer.url);
        payload.set("image", image);
        payload.set("description", organizer.description);
        payload.set("showAllEvents", (!!organizer.showAllEvents).toString());
        payload.set("showAllEventInfo", (!!organizer.showAllEventInfo).toString());
        const _organizer = await updateOrganizerWithImage(payload, organizer._id);

        setOrganizers(organizers.map(o => o._id === organizer._id ? _organizer : o));
      } else {
        const _organizer = await updateOrganizer({ 
          name: organizer.name,
          url: organizer.url, 
          description: organizer.description,
          showAllEvents: organizer.showAllEvents,
          showAllEventInfo: organizer.showAllEventInfo
         }, organizer._id);
        setOrganizers(organizers.map(o => o._id === organizer._id ? _organizer : o));
      }

      hermes.success(`${organizer.name} has been updated`);
    } catch (error) {
      hermes.error("Error editing organizer");
      console.error(error);
    }
  }

  useEffect(() => {
    async function fetchOrganizers() {
      const res = await authApi.getOrganizers();
      setOrganizers(res.organizers);
    }

    fetchOrganizers();
  }, [])
  
  
  async function deleteOrganizer(id: string) {
    try {
      await _deleteOrganizer(id);
      setOrganizers(organizers.filter(o => o._id !== id));
      hermes.success(`Organizer has been deleted`);
    } catch (error) {
      hermes.error("Error deleting organizer");
      console.error(error);
    }

  }

  async function approveSuggestion(id: string) {
    try {
      const organizer = await approveOrganizer(id);
      mutate("organizerSuggestions", organizerSuggestions.filter(o => o._id !== id));
      if (organizer) {
        setOrganizers([...organizers, organizer]);
      }
    } catch (error) {
      hermes.error("Error approving organizer");
      console.error(error);
    }
  }

  async function deleteSuggestion(id: string) {
    try {
      await rejectOrganizer(id);
      mutate("organizerSuggestions", organizerSuggestions.filter(o => o._id !== id));
    } catch (error) {
      hermes.error("Error deleting organizer");
      console.error(error);
    }
  }

    

  return (
    <AdminOrganizersContext.Provider
      value={{
        organizers: _.sortBy(organizers, organizer => organizer.name.toLowerCase()),
        suggestions: organizerSuggestions,
        addOrganizer,
        deleteOrganizer,
        editOrganizer,
        approveSuggestion,
        deleteSuggestion
      }}
    >
      {children}
    </AdminOrganizersContext.Provider>
  );
}

export default function useAdminOrganizers() {
  const context = useContext(AdminOrganizersContext);
  return context;
};