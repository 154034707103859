import { useState } from "react";
import { Button, Checkbox, Header, Table, Container, Input } from "semantic-ui-react";
import useAdminLanguages from "./useAdminLanguages";
import styles from './AdminLanguages.module.css';


export default function AdminLanguages() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);

  const [filterDeliveredScouter, setFilterDeliveredScouter] = useState(false);

  const { data: people, error, isLoading, setLanguage, count } = useAdminLanguages({
    page,
    limit,
    filterDeliveredScouter,
  });

  const handleLimitChange = (e: any, { value }: any) => {
    const newLimit = +value;

    if (newLimit < 10) {
      setLimit(10)
    } else if (newLimit > count) {
      setLimit(count)
    } else {
      setLimit(newLimit)
    }

    setPage(1);

  };

  const handlePageChange = (e: any, { value }: any) => {
    const newPage = +value;
    if (newPage < 1) {
      setPage(1);
    } else if (newPage > Math.ceil(count / limit)) {
      setPage(Math.ceil(count / limit));
    } else {
      setPage(newPage);
    }
  };

  const handleFilterDeliveredScouterChange = () => {
    setFilterDeliveredScouter(filter => !filter);
    setPage(1);
  };

  return (
    <Container className={styles.container}>
      <Header>Languages</Header>

      <div className={styles.filters}>
        <Input
          className={styles.limit}
          label="Limit"
          type="number"
          value={limit}
          onChange={handleLimitChange}
        />

        <Checkbox
          toggle
          className={styles.filterDeliveredScouter}
          label="Filter delivered scouter"
          checked={filterDeliveredScouter}
          onChange={handleFilterDeliveredScouterChange}
        />

        <Input
          className={styles.pageInput}
          placeholder="Page"
          type="number"
          value={page}
          onChange={handlePageChange}
        >
          <Button
            primary
            className={styles.previous}
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
          >
            Previous
          </Button>
          <input />
          <Button
            primary
            className={styles.next}
            onClick={() => setPage(page + 1)}
            disabled={page === Math.ceil(count / limit)}
          >
            Next
          </Button>
        </Input>
      </div>


      <Table celled inverted unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Language</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {isLoading && <Table.Row>
            <Table.Cell colSpan={2}>Loading...</Table.Cell>
          </Table.Row>}

          {error && <Table.Row>
            <Table.Cell colSpan={2}>Error: {error.message}</Table.Cell>
          </Table.Row>}

          {people && people.map((person) => (
            <Table.Row key={person._id}>
              <Table.Cell>{person.name}</Table.Cell>
              <Table.Cell>
                <Button
                  primary
                  onClick={() => setLanguage(person._id, person.language === "en" ? "nl" : "en")}
                >
                  {person.language === "en" ? "English" : "Dutch"}
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}

        </Table.Body>
      </Table>
    </Container>
  );
}