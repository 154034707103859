import { Button, Table } from "semantic-ui-react";
import useAdminOrganizers from "./useAdminOrganizers";

export default function SuggestionRow({ suggestion }: { suggestion: IOrganizerSuggestion }) {
  const { approveSuggestion, deleteSuggestion } = useAdminOrganizers();

  return (
    <Table.Row key={suggestion._id}>
      <Table.Cell colspan={2}>{suggestion.name}</Table.Cell>
      <Table.Cell colspan={4}>{suggestion.user.fullName}</Table.Cell>
      <Table.Cell collapsing>
        <Button primary onClick={() => approveSuggestion(suggestion._id)}>Approve</Button>
      </Table.Cell>
      <Table.Cell collapsing>
        <Button basic onClick={() => deleteSuggestion(suggestion._id)}>Reject</Button>
      </Table.Cell>
    </Table.Row>
  );
}
