import moment from "moment";
import type { DropdownItemProps } from "semantic-ui-react";
import type { IAuditLog, IEvent, IOrganizer } from "./types";


export const sortOptions: DropdownItemProps[] = [
  { text: 'Date', value: 'date' },
  { text: 'Date (descending)', value: '-date' }
];

export const sortFunctions: { [key: string]: (a: IAuditLog, b: IAuditLog) => number } = {
  date: (a, b) => a.date.diff(b.date),
  '-date': (a, b) => b.date.diff(a.date)
}

export const actionOptions: DropdownItemProps[] = [
  { text: 'All', value: 'all' },
  { text: 'Add', value: 'ADD' },
  { text: 'Update', value: 'UPDATE' },
  { text: 'Delete', value: 'DELETE' },
  { text: 'Submit', value: 'SUBMIT' },
  { text: 'Approve', value: 'APPROVE' },
  { text: 'Reject', value: 'REJECT' },
];

export const formatDate = (date: moment.Moment) => date.format("DD/MM/YY HH:mm");
export const formatEvent = (event: IEvent) => 
  `${event.title} ${formatDate(event.startDate)} - ${formatDate(event.endDate)} by ${event.organizer.name}`;
export const formatOrganizer = (organizer: IOrganizer) => organizer.name;



export function isEvent(object: IEvent | IOrganizer): object is IEvent {
  return "title" in object;
}

export function isOrganizer(object: IEvent | IOrganizer): object is IOrganizer {
  return "name" in object;
}



export const formatObject = (object: any) => {
  if (isEvent(object))
    return formatEvent(object);

  else if (isOrganizer(object))
    return formatOrganizer(object);

  return "Unknown Type";
}

export const parseLogs = (logs: any[], allOrganizers: IOrganizer[]): IAuditLog[] => {
  return logs.map(log => {
    if (isEvent(log.object)) {
      return {
        ...log,
        date: moment(log.date),
        objectType: 'Event',
        object: {
          ...log.object,
          startDate: moment(log.object.startDate),
          endDate: moment(log.object.endDate),
          organizer: allOrganizers.find(o => o._id === log.object.organizer) || { _id: "?", name: "Unknown" },
        }
      }
    } else if (isOrganizer(log.object)) {
      return {
        ...log,
        date: moment(log.date),
        object: {
          name: log.object.name,
          _id: log.object._id,
        },
      }
    }
    
    return log;
  });
}