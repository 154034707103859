import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Card, Header, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

function Bug({_id, description, user, browser, fixed, createdAt, onDeleteBug, onToggleFixed}) {
  return (
    <Card>
      <Header inverted>
        {fixed ? '[FIXED] ' : null}
        {user.fullName} at {new Date(createdAt).toString()} using {browser} :
      </Header>
      <pre style={{whiteSpace: 'pre-wrap'}}>{description}</pre>
      <div style={{textAlign: 'center'}}>
        <Button onClick={() => onToggleFixed(_id)} secondary>{fixed ? 'Mark as not fixed' : 'Mark as fixed'}</Button>
        <Button onClick={() => onDeleteBug(_id)} secondary>Delete</Button>
      </div>
    </Card>
  )
}

export default withRouter(inject('loadingStore')(observer(Bug)));
