import React from "react";
import "../../css/loader.css";
import { observer, inject } from "mobx-react";

function Loader({ loadingStore }) {
  const { loading, pb_loading } = loadingStore;

  return (
    <div className={"loader" + (loading || pb_loading ? " loading" : "")}></div>
  );
}

export default inject("loadingStore")(observer(Loader));
