import { Button } from "semantic-ui-react";
import React from "react";

export default function ({ location, history }) {
  const handleClick = () => history.goBack();
  return (
    <div className="app-card app-card-border">
      <h1>404</h1>
      <h3>Page Not Found.</h3>
      <p>This page does not exist - {location.pathname}</p>
      <Button onClick={handleClick}>Go Back</Button>
    </div>
  );
}
