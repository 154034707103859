import Bowser from "bowser";
import { createContext, useContext, useEffect, useState } from "react";
import authenticationService from "../_services/authentication.service";

type Callback = () => void;
type InstallPrompt = () => Promise<void>
type ShowInstructions = (callback?: Callback) => Promise<void>

interface IPWAInstallContext {
  installPrompt: InstallPrompt | null;
  showInstallInstructions: ShowInstructions | null;
  showingInstructions: boolean;
  setShowingInstructions: (showingInstructions: boolean) => void;
  callback: Callback;
};
const PWAInstallContext = createContext<IPWAInstallContext | null>(null);


function PWAInstallProvider({ children }: { children: React.ReactNode }) {
  const [installPrompt, setInstallPrompt] = useState<InstallPrompt | null>(null);
  const [showInstallInstructions, setShowInstallInstructions] = useState<ShowInstructions | null>(null);
  const [showingInstructions, setShowingInstructions] = useState(false);
  const [callback, setCallback] = useState<Callback>(() => { });
 
  useEffect(() => {
    function handleBeforeInstallPrompt(e: Event) {
      e.preventDefault();

      setInstallPrompt(() => (async () => {
        const event = e as BeforeInstallPromptEvent

        event.prompt();
        const { outcome } = await event.userChoice

        const parser = Bowser.getParser(window.navigator.userAgent);
        authenticationService.trackAppInstall({
          browser: parser.getBrowserName(),
          browserVersion: parser.getBrowserVersion(),
          platform: parser.getPlatformType(),
          os: parser.getOSName(),
          osVersion: parser.getOSVersion(),
          outcome
        })
      }))

    };

    function handleAppInstalled(e: Event) {
      setInstallPrompt(null);

      const parser = Bowser.getParser(window.navigator.userAgent);
      authenticationService.trackAppInstall({
        browser: parser.getBrowserName(),
        browserVersion: parser.getBrowserVersion(),
        platform: parser.getPlatformType(),
        os: parser.getOSName(),
        osVersion: parser.getOSVersion(),
        outcome: "accepted"
      })
    }

    const parser = Bowser.getParser(window.navigator.userAgent);
    const isIOSSafari = parser.getOSName() === "iOS" && parser.getBrowserName() === "Safari";
    // @ts-ignore - navigator.standalone is not typed
    const isOnInstalledPWA = window.navigator.standalone || window.matchMedia("(display-mode: standalone)").matches;

    if (isIOSSafari && !isOnInstalledPWA) {
      console.log("Showing install instructions");
      setShowInstallInstructions(() => (async (callback?: Callback) => {
        setShowingInstructions(true);
        await authenticationService.trackAppInstall({
          browser: parser.getBrowserName(),
          browserVersion: parser.getBrowserVersion(),
          platform: parser.getPlatformType(),
          os: parser.getOSName(),
          osVersion: parser.getOSVersion(),
          outcome: "accepted"
        })

        if (callback) {
          setCallback(callback);
        }
      }))

    } else {
      console.log("Not showing install instructions");
      setShowInstallInstructions(null);
    }


    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    window.addEventListener("appinstalled", handleAppInstalled);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
      window.removeEventListener("appinstalled", handleAppInstalled);
    }
  }, []);

  return (
    <PWAInstallContext.Provider
      value={{
        installPrompt,
        showInstallInstructions,
        showingInstructions,
        setShowingInstructions,
        callback
      }}
    >
      {children}
    </PWAInstallContext.Provider>
  );
}





export default function usePWAInstall(): IPWAInstallContext {
  const context = useContext(PWAInstallContext);
  if (!context) {
    throw new Error("usePWAInstall must be used within a PWAInstallProvider");
  }
  return context;
}


export function withPWAInstallProvider(Component: React.ComponentType<any>) {
  return function PWAInstallProviderWrapper(props: any) {
    return (
      <PWAInstallProvider>
        <Component {...props} />
      </PWAInstallProvider>
    );
  };
}