import { Component, KeyboardEvent, SyntheticEvent, useRef, useState } from "react";
import { Button, Dropdown, DropdownItemProps, DropdownProps } from "semantic-ui-react"


interface DropdownWithSuggestionProps extends DropdownProps {
  options: DropdownWithSuggestionItemProps[];
}

interface DropdownWithSuggestionItemProps {
  value: string;
  text: string;
  image?: string;
  description?: string;
  [key: string]: any;
}

const SuggestionButton = ({ name }: { name: string }) => {

  return (
    <Button primary>
      Suggest {name}
    </Button>
  )
}

function DropdownWithSuggestion(props: DropdownWithSuggestionProps) {
  const [search, setSearch] = useState("");
  const dropdownRef = useRef<Component<DropdownProps, any, any>>(null);

  const reset = () => {
    setSearch("");
    dropdownRef.current?.setState({
      selectedIndex: 0,
    });
  };

  const {
    options,
    onChange,
    onAddItem,
    ...otherProps
  } = props;

  const showSuggestion = search && !options.some(
    option => option.text.toLowerCase() === search.toLowerCase()
  );


  let _options: DropdownItemProps[];
  if (showSuggestion) {
    _options = [{
      text: search,
      children: <SuggestionButton name={capitalize(search)} />,
      value: "SUGGESTION",
      className: "suggestion",
    }, ...options];
  } else {
    _options = options;
  }

  const handleChange = (
    e: SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps) => {

    const { key } = e as KeyboardEvent<HTMLElement>;
    if (
      (e.type === 'keydown' && key !== 'Enter') ||
      !value ||
      e.type === 'blur'
    ) { 
      return;
    }

    if (value === 'SUGGESTION') {
      onAddItem?.(e as KeyboardEvent<HTMLElement>, { value: capitalize(search) });
      return reset();
    }

    onChange?.(e, { value });
    reset();
  };

  return (
    <Dropdown
      ref={dropdownRef}
      search
      selection
      value=""
      searchQuery={search}
      onSearchChange={(e, { searchQuery }) => setSearch(searchQuery)}
      options={_options}
      onBlur={reset}
      onFocus={reset}
      noResultsMessage={null}
      setSearchResultsMessage={null}
      onChange={handleChange}
      {...otherProps}
    >
    </Dropdown>
  );
}

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


export default DropdownWithSuggestion;