import "./semantic/src/semantic.min.css";
import Notifications from "react-notify-toast";
import MainPage from "./pages/main_page/index";
import React, { Component } from "react";
import { Provider } from "mobx-react";
import stores from "./stores";
import { Router } from "react-router-dom";
import { ErrorBoundary } from "./components/utils";
import "./App.css";
import "./css/form-template.css";
import "./css/fonts.css";
import "./css/legal.css";
import "./css/report-bug.css";
import "./css/analytics.css";
import "./css/links.css"
import "react-phone-number-input/style.css";
import { loadCurrentRoute } from "./routing";
import authenticationService from "./_services/authentication.service";
import { createBrowserHistory } from "history";
import { RandomLocationProvider } from "./contexts/RandomLocationContext";
import { withPWAInstallProvider } from "./hooks/usePWAInstall";
import { PopupManagerProvider } from "./contexts/PopupManagerContext";

class App extends Component {
  user = null;

  // Gets current user value if user is logged in and gets profile data of the user
  componentDidMount() {
    this.user = authenticationService.currentUserValue;

    if (stores.authStore.isLoggedIn) {
      if (this.user) {
        if (
          this.user.isSMSVerified === true ||
          this.user.isEmailVerified === true
        ) {
          stores.profileStore.getUserData();
        }
      }
    }

    if (!("Notification" in window)) {
      console.log("This browser does not support notification");
      return;
    }


    window.addEventListener("popstate", function (e) {
      loadCurrentRoute();
    });

    loadCurrentRoute();
  }

  render() {
    let history = createBrowserHistory();
    return (
      <Provider {...stores}>
        <Router history={history}>
          <ErrorBoundary>
            <Notifications />
              <PopupManagerProvider>
                <RandomLocationProvider>
                  <MainPage />
                </RandomLocationProvider>
              </PopupManagerProvider>
          </ErrorBoundary>
        </Router>
      </Provider>
    );
  }
}

export default withPWAInstallProvider(App);
