import useSWR, { KeyedMutator } from 'swr';
import loadingStore from '../stores/loading-store';
import { hermes } from '../utilities';
import api from '../_services/authentication.service';
import useFilters from './useFilters';
import useOrganizers from './useOrganizers';

interface useSubscriptionsReturn {
  subscribe: (organizerId: string) => void;
  unsubscribe: (organizerId: string) => void;
  mutate: KeyedMutator<ISubscription[]>;
  subscriptions: ISubscription[];
}

export default function useSubscriptions(): useSubscriptionsReturn {
  const { data, mutate, error } = useSWR<ISubscription[]>('subscriptions', api.getSubscribedOrganizers);
  const organizers = useOrganizers();
  const { tabFilter } = useFilters();

  const subscribe = async (organizerId: string) => {
    try {
      // loadingStore.setLoading()
      // if (tabFilter === 'subscribed') {
      //   loadingStore.setPageMaskLoading()
      // }

      await api.subscribeOrganizers([organizerId], []);

      // if (tabFilter !== 'subscribed') {
      loadingStore.setLoaded()
      // }

      hermes.success("You are subscribed");


      await mutate(subscrptions => {
        return [...(subscrptions || []), {
          organizer: organizers.find(organizer => organizer._id === organizerId) as IOrganizer,
          user: "",
          _id: ""
        }];
      });

      // if (tabFilter === 'subscribed') {
      //   window.location.reload();
      // }

    } catch (err) {
      hermes.error(err);
    }
  };

  const unsubscribe = async (organizerId: string) => {
    try {
      loadingStore.setLoading()
      // if (tabFilter === 'subscribed') {
      //   loadingStore.setPageMaskLoading();
      // }

      await api.subscribeOrganizers([], [organizerId]);

      // if (tabFilter !== 'subscribed') {
      loadingStore.setLoaded()
      // }

      hermes.success("You are unsubscribed");


      await mutate(subscrptions => {
        return subscrptions?.filter(subscription => subscription.organizer?._id !== organizerId);
      });

      // if (tabFilter === 'subscribed') {
      //   window.location.reload();
      // }
    } catch (err) {
      hermes.error("Error unsubscribing");
    }
  };

  const utilityFunctions = {
    subscribe,
    unsubscribe,
    mutate
  }

  if (error) {
    hermes.error("Error fetching subscriptions");
    return {
      ...utilityFunctions,
      subscriptions: [],
    }
  }

  return {
    ...utilityFunctions,
    subscriptions: data || [],
  };
}