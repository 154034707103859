import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import NotCompatiblePage from '../not-compatible-page';
import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Navbar } from '../../components/shared';
import Loader from '../../components/loader';
import ProgressBar from '../../components/progress-bar';
import NotFound from '../../components/routes/not-found';
import Bowser from 'bowser';
import ResetPasswordPage from '../reset-password-page/reset-password-page';
import SMSVerificationPage from '../verification-page/sms-verification-page';
import VerificationPrivateRoute from '../../components/private-routes/verification-private-route';
import PrivateRoute from '../../components/private-routes/private-route';
import EmailVerificationPage from '../verification-page/email-verification-page';
import Settings from '../settings/index';
import LandingPage from '../landing-page/landing-page';
import CreateEvent from '../event/create-event';
import authenticationService from '../../_services/authentication.service';
import ThankYou from '../thank-you/thank-you';
import faq from '../faq';
import { Terms, ProtectedTerms } from '../terms';
import Privacy from '../privacy';
import ReactGA from 'react-ga';
import authStore from '../../stores/auth-store';
import ListEvent from '../event/list-event';
import EditEvent from '../event/edit-event';
import ListOrganizers from '../organizer/list-organizer';
import EditOrganizer from '../organizer/edit-organizer';
import CreateOrganizer from '../organizer/create-organizer';
import subscribePage from '../subscribe-page';
import Statistics from '../statistics';
import PageMask from '../../components/PageMask';
import AdminPrivateRoute from '../../components/private-routes/admin-private-route';
import ReportBug from "../report-bug-page";
import ReportedBugs from "../reported-bugs-page";
import { RandomLocationContext } from '../../contexts/RandomLocationContext';
import NotificationPreferences from '../notification-preferences';
import AdminNotificationPreferences from '../notification-preferences/admin';

import ListEventsByOrganizers from '../public-pages/list-events-by-organizers';
import OrgAnalytics1 from '../analytics/org-analytics-1';
import OrgAnalytics2 from '../analytics/org-analytics-2';
import OrgAnalytics3 from '../analytics/org-analytics-3';
import OrgAnalytics4 from '../analytics/org-analytics-4';
import OrgAnalytics5 from '../analytics/org-analytics-5';
import OrgAnalytics6 from '../analytics/org-analytics-6';
import OrgAnalytics7 from '../analytics/org-analytics-7';
import OrgAnalytics8 from '../analytics/org-analytics-8';
import OrganizersPage from '../organizer';
import LinksPage from '../links';
import AdminEvents from '../event/AdminEvents';
import AuditLogs from '../AuditLogs';
import AdminOrganizers from '../organizer/AdminOrganizers';
import Demo from '../Demo';
import Emails from '../Emails';
import Feed from '../Feed';
import config from '../../_helpers/config';
import Walkthrough from '../walkthrough';
import NotificationBanner from '../../components/feed/NotificationBanner';
import InstallPopup from '../../components/feed/InstallPopup';
import InstallInstructionModal from '../../components/shared/InstallInstructionModal';
import usePWAInstall from '../../hooks/usePWAInstall';
import { Install } from '../Install';
import { OnboardingInvites } from '../OnboardingInvites';
import { AdminInvites, AdminInviteLogs } from '../AdminInvites';
import RequestEmailChange from '../verification-page/request-email-change.tsx';

import { SendNewsletter } from '../SendNewsletter';
import { WalkThroughProvider } from '../../hooks/useWalkThrough';

let AdminLanguages = () => <Redirect to="/" />;
if (process.env.REACT_APP_REACT_APP_INCLUDE_ADMIN_PAGES === 'true') {
  AdminLanguages = require('../AdminLanguages').default;
}



const InstallInstructionModalWrapper = () => {
  const { showingInstructions, setShowingInstructions  } = usePWAInstall();
  return (
    <InstallInstructionModal
      open={showingInstructions}
      onClose={() => setShowingInstructions(false)}  
    />
  );
}


class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = { sendingEmail: false, emailSent: false };

    // load pagemask first
    this.props.loadingStore.setPageMaskLoading();

    const supportedBrowsers = [
      'Chrome',
      'Chromium',
      'Opera',
      'Opera Touch',
      'Safari',
      'Firefox',
    ];
    const browserName = Bowser.getParser(
      window.navigator.userAgent
    ).getBrowserName();
    // this.isSupported = supportedBrowsers.includes(browserName);
    this.isSupported = true;
    if (authenticationService.currentUserValue) {
      // Tracks unique users that are SMS and email verified by their Ids
      if (
        authenticationService.currentUserValue.isSMSVerified === true &&
        authenticationService.currentUserValue.isEmailVerified == true
      ) {
        ReactGA.set({ userId: authenticationService.currentUserValue?._id });
      }
    }
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
    authStore: PropTypes.object.isRequired,
  };

  static contextType = RandomLocationContext;

  componentDidMount() {
    if (!this.isSupported) {
      // Redirect if not-supported page
    }

    // if the current pathname  is "/" and if the user is logged in set it to home
    if (window.location.pathname == '/') {
      if (authStore.isLoggedIn) {
        ReactGA.pageview('/home');
      } else {
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.props.loadingStore.setPageMaskLoaded();
      }
    } else {
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.props.loadingStore.setPageMaskLoaded();
    }
    if (authenticationService.currentUserValue) {
      // Tracks unique users that are SMS and email verified by their Ids
      if (
        authenticationService.currentUserValue.isSMSVerified === true &&
        authenticationService.currentUserValue.isEmailVerified == true
      ) {
        ReactGA.set({ userId: authenticationService.currentUserValue?._id });
      }
    }
  }
  componentDidUpdate() {
    // if the current pathname  is "/" and if the user is logged in set it to home
    if (window.location.pathname == '/') {
      if (authStore.isLoggedIn) {
        ReactGA.pageview('/home');
      } else {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    } else {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    // Tracks unique users that are SMS and email verified by their Ids
    if (authenticationService.currentUserValue) {
      ReactGA.set({ userId: authenticationService.currentUserValue?._id });
    }
  }

  render() {
    const { authStore } = this.props;
    let view = null;
    const { terms: protectedTermsPath } = this.context;

    const isSignedUp = (
      authStore.isLoggedIn &&
      authenticationService.currentUserValue &&
      authenticationService.currentUserValue.isSMSVerified &&
      authenticationService.currentUserValue.isEmailVerified
    );



    // Checks if the user is logged in or not. Based on this, it will define routes
    if (authStore.isLoggedIn && authenticationService.currentUserValue) {
      if (
        !authenticationService.currentUserValue.isSMSVerified ||
        !authenticationService.currentUserValue.isEmailVerified ||
        (config.isOrganizerPageRequired() && !authenticationService.currentUserValue.isOrganizersPageCompleted) ||
        (config.isInvitePageRequired() && !authenticationService.currentUserValue.invites)
      ) {
        view = (
          <Switch>
            <Route
              exact
              path="/reset-password/:token"
              component={ResetPasswordPage}
            ></Route>
            <VerificationPrivateRoute
              exact
              path="/onboarding/sms"
              component={SMSVerificationPage}
            ></VerificationPrivateRoute>
            <VerificationPrivateRoute
              exact
              path="/onboarding/email/change"
              component={RequestEmailChange}
            ></VerificationPrivateRoute>
            <VerificationPrivateRoute
              exact
              path="/onboarding/email/:code?"
              component={EmailVerificationPage}
            ></VerificationPrivateRoute>
            <VerificationPrivateRoute
              exact
              path="/onboarding/invites"
              component={OnboardingInvites}
            />
           <VerificationPrivateRoute
              exact
              path="/onboarding/subscribe"
              component={OrganizersPage}
            ></VerificationPrivateRoute>
            <PrivateRoute
              exact
              path="/onboarding/thanks"
              component={ThankYou}
            ></PrivateRoute>
            <Route exact path="/emails" component={Emails}></Route> 
            <PrivateRoute exact path="/faq" component={faq}></PrivateRoute>
            <Route exact path="/privacy" component={Privacy}></Route>
            <Route exact path={protectedTermsPath} component={ProtectedTerms}></Route>
            <Route exact path="/terms" component={Terms}></Route>
            {/* <Redirect to="/onboarding/sms" /> */}
            <PrivateRoute component={null} />
          </Switch>
        );
      } else {
        view = (
          <Switch>
            <Route exact path="/" component={Feed}></Route>
            <Route
              exact
              path="/reset-password/:token"
              component={ResetPasswordPage}
            ></Route>
            <PrivateRoute
              exact
              path="/settings"
              component={Settings}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/statistics"
              component={Statistics}
            ></PrivateRoute>
            <AdminPrivateRoute
              exact
              path="/all-event"
              component={ListEvent}
            ></AdminPrivateRoute>
            <AdminPrivateRoute
              exact
              path="/organizers"
              component={ListOrganizers}
            ></AdminPrivateRoute>
            <AdminPrivateRoute
              exact
              path="/add-organizer"
              component={CreateOrganizer}
            ></AdminPrivateRoute>
            <AdminPrivateRoute
              exact
              path="/add-event"
              component={CreateEvent}
            ></AdminPrivateRoute>
            <AdminPrivateRoute
              exact
              path="/edit-organizer/:id"
              component={EditOrganizer}
            ></AdminPrivateRoute>
            <AdminPrivateRoute
              exact
              path="/edit/:id"
              component={EditEvent}
            ></AdminPrivateRoute>
            <PrivateRoute
              exact
              path="/subscribe"
              component={subscribePage}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/onboarding/thanks"
              component={ThankYou}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/onboarding/install"
              component={Install}
            ></PrivateRoute>
            <PrivateRoute exact path="/links" component={LinksPage}></PrivateRoute>
            <Route exact path="/emails" component={Emails}></Route> 
            <Route exact path="/terms" component={Terms}></Route>
            <PrivateRoute exact path="/report-bug" component={ReportBug}></PrivateRoute>
            <AdminPrivateRoute exact path="/reported-bugs" component={ReportedBugs}></AdminPrivateRoute>
            <PrivateRoute exact path="/faq" component={faq}></PrivateRoute>
            <PrivateRoute exact path="/notifications" component={NotificationPreferences}></PrivateRoute>
            <AdminPrivateRoute exact path="/admin-notifications" component={AdminNotificationPreferences}></AdminPrivateRoute>
            <AdminPrivateRoute exact path="/org-analytics-1" component={OrgAnalytics1}></AdminPrivateRoute>
            <AdminPrivateRoute exact path="/org-analytics-2" component={OrgAnalytics2}></AdminPrivateRoute>
            <AdminPrivateRoute exact path="/org-analytics-3" component={OrgAnalytics3}></AdminPrivateRoute>
            <AdminPrivateRoute exact path="/org-analytics-4" component={OrgAnalytics4}></AdminPrivateRoute>
            <AdminPrivateRoute exact path="/org-analytics-5" component={OrgAnalytics5}></AdminPrivateRoute>
            <AdminPrivateRoute exact path="/org-analytics-6" component={OrgAnalytics6}></AdminPrivateRoute>
            <AdminPrivateRoute exact path="/org-analytics-7" component={OrgAnalytics7}></AdminPrivateRoute>
            <AdminPrivateRoute exact path="/org-analytics-8" component={OrgAnalytics8}></AdminPrivateRoute>
            <PrivateRoute roles={["Admin", "EventSubmitter"]} exact path="/admin-events" component={AdminEvents} />
            <AdminPrivateRoute exact path="/audit-logs" component={AuditLogs}></AdminPrivateRoute>
            <AdminPrivateRoute exact path="/admin-organizers" component={AdminOrganizers}></AdminPrivateRoute>
            <AdminPrivateRoute exact path="/admin-languages" component={AdminLanguages}></AdminPrivateRoute>
            <AdminPrivateRoute exact path="/admin-invites" component={AdminInvites}></AdminPrivateRoute>
            <AdminPrivateRoute exact path="/admin-invite-logs" component={AdminInviteLogs}></AdminPrivateRoute>
            <AdminPrivateRoute exact path="/admin-send-newsletter" component={SendNewsletter}></AdminPrivateRoute>
            <PrivateRoute exact path="/walkthrough" component={Walkthrough}></PrivateRoute>
            <Route
              
              path="/:name"
              component={ListEventsByOrganizers}
            ></Route>
            <Redirect to="/" />
            <Route component={NotFound} />
          </Switch>
        );
      }
    } else {
      view = (
        <Switch>
          <Route exact path="/" component={LandingPage}></Route>
          <Route exact path="/join">
            <Redirect to="/?action=join" />
          </Route>
          <Route exact path="/login">
          <Redirect to="/?action=login" />
          </Route>
          <Route exact path="/forgot-password">
            <Redirect to="/?action=forgot-password" />
          </Route>
          
          <Route exact path="/demo" component={Demo}></Route>
          <Route exact path="/emails" component={Emails}></Route> 
          <Route
            exact
            path="/reset-password/:token"
            component={ResetPasswordPage}
          ></Route>

          <VerificationPrivateRoute
            exact
            path="/onboarding/sms"
            component={SMSVerificationPage}
          ></VerificationPrivateRoute>
          <VerificationPrivateRoute
            exact
            path="/onboarding/email/:code?"
            component={EmailVerificationPage}
          ></VerificationPrivateRoute>
          <PrivateRoute
            exact
            path="/onboarding/thanks"
            component={ThankYou}
          ></PrivateRoute>
          <Route exact path={protectedTermsPath} component={ProtectedTerms}></Route>
          <PrivateRoute exact path="/faq" component={faq}></PrivateRoute>
          <Route
              exact
              path="/:name"
              component={ListEventsByOrganizers}
          ></Route>
          <Redirect to="/" />
          <Route component={NotFound} />
        </Switch>
      );
    }

    const Wrapper = isSignedUp ? WalkThroughProvider : Fragment;

    // Render content, if browser is supported.
    if (this.isSupported) {
      return (
        <Wrapper>
          <PageMask />
          {isSignedUp && <NotificationBanner />}
          <InstallPopup />
          <InstallInstructionModalWrapper />
          <Navbar />
          <ProgressBar />
          {view}
          <Loader />
        </Wrapper>
      );
    } else {
      return <NotCompatiblePage />;
    }
  }
}

export default withRouter(
  inject('authStore', 'profileStore', 'loadingStore')(observer(MainPage))
);
