import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Form, Button, Card } from "semantic-ui-react";
import PhoneInput from "react-phone-number-input";
import { hermes } from "../../utilities";
import { withRouter } from "react-router-dom";
import { Container } from "semantic-ui-react";
import "../../css/onboarding.css";
import { errors, success } from "../../messages";
import { Progress } from "semantic-ui-react";

class SMSVerificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "ENTER_NUMBER",
      cooode: "",
      requestSent: false,
      time: 60,
      phoneNumber: "+",
    };
    this.NUMBER_VIEW = "ENTER_NUMBER";
    this.CODE_VIEW = "ENTER_CODE";
  }
  computedProgressValue = 25;

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  /**
   *  send phone number verification code
   *  - checks phone number validity
   *  - send the phone number to api endpoint
   *
   */
  sendVerificationCode = async (e) => {
    e.preventDefault();
    this.setState({ cooode: "" });

    const { profileStore } = this.props;

    try {
      //Send the verification code to phone number
      await profileStore.sendVerificationCode(this.state.phone);
      hermes.success(success.VERIFICATION_CODE_SMS_SENT);

      //Set the view to the verify code view
      this.setState({
        view: this.CODE_VIEW,
      });
    } catch (e) {
      if (e === "TypeError: Failed to fetch") {
        hermes.error("TypeError: Failed to fetch");
      } else {
        hermes.error(e);
      }

      return;
    }
  };

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  // to set time out for showing and hiding resend button
  tick() {
    if (this.state.time !== 0)
      this.setState({
        time: this.state.time - 1,
      });
  }

  /**
   *  verify phone number verification code
   *  - checks verification code length
   *  - send the verification code with phone number to api endpoint
   *
   */
  verifyPhoneNumber = async (e) => {
    e.preventDefault();
    const { phone, cooode } = this.state;
    const { profileStore } = this.props;

    if (cooode.length !== 5) {
      hermes.error(errors.INVALID_VERIFICATION_CODE_LENGTH);
      return;
    }
    try {
      await profileStore.verifyCode(phone, cooode);
      hermes.success(success.PHONE_VERIFIED);
      this.props.history.push("/onboarding/email");
    } catch (e) {
      console.log("under catch error sms");
      console.log(e);
      if(e?.message)
      {
        hermes.error(e.message);

      }
      else{
        hermes.error(e);
      }
      
    }
  };

  render() {
    const state = this.state;
    let view;

    if (state.view === this.NUMBER_VIEW) {
      view = (
        <div className="onboarding-column">
          <Form onSubmit={this.sendVerificationCode} autoComplete="off">
            <Card className="onboarding-form-wrapper">
              <Form.Field style={{ marginBottom: 0 }}>
                <label>Phone number</label>
                <PhoneInput
                  value={this.state.phoneNumber}
                  autoComplete="new-password"
                  placeholder="Enter your phone number"
                  onChange={(phone) => this.setState({ phone })}
                />
              </Form.Field>
            </Card>
            <Button type="submit" primary>
              Send
            </Button>
          </Form>
        </div>
      );
    } else if (state.view === this.CODE_VIEW) {
      view = (
        <div className="onboarding-column">
          <Form onSubmit={this.verifyPhoneNumber}>
            <Card className="onboarding-form-wrapper">
              <Form.Field style={{ marginBottom: 0 }}>
                <label>Enter verification code</label>
                <input
                  name="cooode"
                  autoComplete="new-password"
                  value={state.cooode}
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Card>
            <Button
              primary
              type="button"
              style={{ marginTop: "0.25em" }}
              onClick={this.sendVerificationCode}
            >
              Resend
            </Button>
            <Button
              primary
              type="button"
              onClick={() =>
                this.setState({
                  view: this.NUMBER_VIEW,
                  requestSent: false,
                  time: 60,
                })
              }
            >
              Change
            </Button>
            <Button primary type="submit">
              Verify
            </Button>
          </Form>
        </div>
      );
    }
    return (
      <Fragment>
        <Progress
          percent={this.computedProgressValue}
          className="onboarding-progress-bar"
        />
        <Container fluid>
          <div>
            <div className="onboarding-5-container">
              <div className="onboarding-heading">Confirm your phone</div>
              <div className="onboarding-sub-heading ">
                Let's keep your account secure
              </div>
              {view}
            </div>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default withRouter(
  inject("profileStore", "authStore")(observer(SMSVerificationPage))
);
