import moment from "moment";

export function formatDate(date: moment.Moment | undefined): string {
  if (!date) return "";
  return date.format("DD/MM/YYYY HH:mm");
}

export const sortFunctions: { [key: string]: SortFn<IInvite|IInviteLog> } = {
  "createdAt": (a, b) => {
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    return 0;
  },
  "-createdAt": (a, b) => {
    if (a.createdAt < b.createdAt) return 1;
    if (a.createdAt > b.createdAt) return -1;
    return 0;
  },
};

export const sortOptions = [
  { key: "createdAt", text: "Date", value: "createdAt" },
  { key: "-createdAt", text: "Date (descending)", value: "-createdAt" },
]

export const filterOptions = [
  { key: "all", text: "All", value: "all" },
  { key: "Submitted", text: "Submitted", value: "Submitted" },
  { key: "Approved", text: "Approved", value: "Approved" },
]

export const filterFunctions: { [key: string]: FilterFn<IInvite> } = {
  "all": (invite) => true,
  "Submitted": (invite) => invite.status === "Submitted",
  "Approved": (invite) => invite.status === "Approved",
}


export const logsFilterOptions = [
  { key: "all", text: "All", value: "all" },
  { key: "Invited", text: "Invited", value: "Invited" },
  { key: "Approved", text: "Approved", value: "Approved" },
  { key: "Deleted", text: "Deleted", value: "Deleted" },
]

export const logsFilterFunctions: { [key: string]: FilterFn<IInviteLog> } = {
  "all": (invite) => true,
  "Invited": (invite) => invite.action === "Invited",
  "Approved": (invite) => invite.action === "Approved",
  "Deleted": (invite) => invite.action === "Deleted",
}

export function parseInvite(invite: any): IInvite {
  return {
    ...invite,
    inviter: {
      ...invite.inviter,
      createdAt: moment(invite.inviter.createdAt),
    },
    createdAt: moment(invite.createdAt),
    updatedAt: moment(invite.updatedAt),
  };
}

export function parseInvites(invites: any[]) {
  return invites.map(parseInvite);
}

export function parseInviteLog(inviteLog: any): IInviteLog {
  return {
    ...inviteLog,
    createdAt: moment(inviteLog.createdAt),
  };
}

export function parseInviteLogs(inviteLogs: any[]) {
  return inviteLogs.map(parseInviteLog);
}