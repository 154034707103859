import { useEffect, useState, useRef } from "react";
import { Popup, PopupProps } from "semantic-ui-react";
import { rootId } from "../../../constants";
import usePopupManager from "../../../contexts/PopupManagerContext";
import useWalkThrough from "../../../hooks/useWalkThrough";
import classes from "./HintPopup.module.css";

interface HintPopupProps extends PopupProps {
  children: React.ReactNode;
  title: string;  
  action: string;
  onClick: () => any;
  name: string;
  closeOnClickAway?: boolean;
  elementRef?: React.RefObject<HTMLElement>;
  containerFitWidth?: boolean;
}

const HintPopup = (props:HintPopupProps)  => {
  const { current, goToNext, text } = useWalkThrough();
  const [open, setOpen] = useState(false);
  
  const {
    children,
    // title,
    // action,
    onClick,
    name,
    elementRef,
    className,
    closeOnClickAway,
    verticalOffset = 0,
    horizontalOffset = 0,
    containerFitWidth,
    ...extraProps
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);


  const { showedAllPopups } = usePopupManager()

  useEffect(() => {
    if (current === name) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [current, name]);


  useEffect(() => {
    function closeOnTouchOutside(e: TouchEvent | MouseEvent) {
      if (closeOnClickAway && open) {
        console.log(e.target, current, name, window.location.pathname, e);
        if (!containerRef.current?.contains(e.target as Node)) {
          goToNext();
        }
      }
    }

    window.addEventListener("click", closeOnTouchOutside);
    window.addEventListener("touchstart", closeOnTouchOutside);

    return () => {
      window.removeEventListener("click", closeOnTouchOutside);
      window.removeEventListener("touchstart", closeOnTouchOutside);
    }
  }, [open]);

  const mountNode = document.getElementById(rootId);

  return (
    <div className={classes.container} ref={containerRef} style={{
      width: containerFitWidth ? 'fit-content' : 'auto',
    }}> 
      <Popup
        className={`${classes.popup} ${className || ''}`}
        mountNode={containerRef.current || mountNode}
        open={showedAllPopups && open}
        trigger={children}
        style={{
          transform: `translate(${horizontalOffset}px, ${verticalOffset}px)`,
        }}
        {...extraProps}
      >
        {/* <Popup.Header className={classes.title}>{title}</Popup.Header> */}
        <Popup.Content className={classes.content}>
          <p className={classes.text}>{text} </p>
          {/* <span role='button' onClick={handleAction} className={classes.action}></span> */}
        </Popup.Content>
      </Popup>
    </div>
  )
}


export default HintPopup;