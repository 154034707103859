import ReactPlayer from "react-player/youtube";
import { Container } from "semantic-ui-react";
import slugify from "slugify";
import styles from "../../css/Walktrough.module.css";

export default function Walkthrough() {
  var questions = [
    {
      question: "Question1 title",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
      video: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
    },
    {
      question: "Question2 title",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
    },
  ];

  return (
    <Container className="legal-page">
      <h1>FAQ</h1>
      <ol>
        {questions.map((q, i) => (
          <li key={i}>
            <a href={`#${slugify(q.question)}`}>{q.question}</a>
          </li>
        ))}
      </ol>
      {questions.map((q, i) => (
        <div key={i}>
          <h2 id={slugify(q.question)}>{q.question}</h2>
          <p>{q.answer}</p>
          <div className={styles.container}>
          <div className={styles.limiter}>
            <div className={styles.wrapper}>
              <ReactPlayer
                url={q.video}
                width='100%'
                height='100%'
                autoplay
                controls
                muted
                playsinline
                
              />
            </div>
          </div>
        </div>
        </div>
      ))}
    </Container>
  );
}