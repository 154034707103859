import useSWR from 'swr';
import { hermes } from '../utilities';
import api from '../_services/authentication.service';

const fetcher = async () => {
  const res = await api.getOrganizers();
  return res.organizers;
}

export default function useOrganizers(): IOrganizer[] {
  const { data, error } = useSWR('organizers', fetcher);
  
  if (error) {
    hermes.error("Error fetching organizers");
  }

  return data || [];
}