import { useState } from "react";
import { Button, Table } from "semantic-ui-react";
import Role from "../../models/role";
import authenticationService from "../../_services/authentication.service";
import EditEventRow from "./EditEventRow";
import { isRequest } from "./helpers";
import useAdminEvents from "./useAdminEvents";

export default function EventRow({ parentEvent, event }: EventRowProps) {
    const [editing, setEditing] = useState(false);
    const isAdmin = authenticationService.currentUserValue.role === Role.Admin;
    const userId = authenticationService.currentUserValue._id;

    const { deleteEvent, approveDeleteRequest, approveRequest } = useAdminEvents();

    let main;
    if (editing)
        main = <EditEventRow event={event} exit={() => setEditing(false)} />;
    else if (!parentEvent)
        main = (
            <Table.Row>
                <Table.Cell collapsing>
                    <span className="mobile-only">Organizer:{' '}</span>
                    {event.organizer?.name}</Table.Cell>
                <Table.Cell>
                    <span className="mobile-only">Title:{' '}</span>
                    {event.title}
                </Table.Cell>
                <Table.Cell collapsing>
                    <span className="mobile-only">Start Date:{' '}</span>
                    {event.startDate}</Table.Cell>
                <Table.Cell collapsing>
                    <span className="mobile-only">End Date:{' '}</span>
                    {event.endDate}</Table.Cell>
                <Table.Cell>
                    <span className="mobile-only">Link:{' '}</span>
                    <a href={event.link} target="_blank" rel="noopener noreferrer">
                        {formatLink(event.link)}
                    </a>
                </Table.Cell>
                <Table.Cell>
                    <span className="mobile-only">Categories:{' '}</span>
                    {event.categories.map(c => c.name).join(", ")}
                </Table.Cell>
                <Table.Cell collapsing>
                    <span className="mobile-only">Featured:{' '}</span>
                    {event.featured ? "Yes" : "No"}
                </Table.Cell>
                <Table.Cell collapsing>
                    <span className="mobile-only">Cancelled:{' '}</span>
                    {event.cancelled ? "Yes" : "No"}</Table.Cell>
                <Table.Cell collapsing>
                    <span className="mobile-only">Status:{' '}</span>
                    {"type" in event && event.type === "ADD" ? "Submitted" : event.status}</Table.Cell>
                <Table.Cell collapsing>
                    <span className="mobile-only">Last updated:{' '}</span>
                    {event.updatedAt}</Table.Cell>
                <Table.Cell collapsing>
                    {(isAdmin || !("type" in event && event.type === "ADD" && event.user !== userId)) && (
                        <Button primary onClick={() => { setEditing(true) }}>Edit</Button>
                    )}
                </Table.Cell>
                <Table.Cell collapsing>
                    {!("type" in event && event.type === "ADD" && !isAdmin && event.user !== userId) && (
                        <Button basic onClick={() => deleteEvent(event._id)}>Delete</Button>
                    )}
                </Table.Cell>
                <Table.Cell collapsing>
                    {("type" in event && event.type === "ADD" && isAdmin) && (
                        <Button color="green" onClick={() => approveRequest(event._id)}>Approve</Button>
                    )}
                </Table.Cell>
            </Table.Row>
        );
    else if (isRequest(event)) {
        const { _id,
            user,
            organizer,
            title,
            startDate,
            endDate,
            link,
            featured,
            categories,
            cancelled,
            updatedAt,
            type,
        } = event;

        main = (
            <Table.Row key={_id}>
                <Table.Cell>
                    <span className="mobile-only">Organizer:{' '}</span>
                    {organizer?.name !== parentEvent.organizer?.name ? organizer?.name : '-'}
                </Table.Cell>
                <Table.Cell>
                    <span className="mobile-only">Title:{' '}</span>
                    {title !== parentEvent.title ? title : '-'}
                </Table.Cell>
                <Table.Cell>
                    <span className="mobile-only">Start Date:{' '}</span>
                    {startDate !== parentEvent.startDate ? startDate : '-'}
                </Table.Cell>
                <Table.Cell>
                    <span className="mobile-only">End Date:{' '}</span>
                    {endDate !== parentEvent.endDate ? endDate : '-'}
                </Table.Cell>
                <Table.Cell>
                    <span className="mobile-only">Link:{' '}</span>
                    {link !== parentEvent.link ? link : '-'}
                </Table.Cell>
                <Table.Cell>
                    <span className="mobile-only">Categories:{' '}</span>
                    {
                        categories.length === parentEvent.categories.length && 
                        categories.map(c => c._id).every(_id =>
                            parentEvent.categories.some(c => c._id === _id)) ?
                                '-' :
                                categories.map(c => c.name).join(', ')
                    }
                </Table.Cell>
                <Table.Cell collapsing>
                    <span className="mobile-only">Featured:{' '}</span>
                    {featured !== parentEvent.featured ? featured ? "Yes" : "No" : '-'}
                </Table.Cell>
                <Table.Cell collapsing>
                    <span className="mobile-only">Cancelled:{' '}</span>
                    {cancelled !== parentEvent.cancelled ? cancelled ? 'Yes' : 'No' : '-'}
                </Table.Cell>
                <Table.Cell collapsing>
                    <span className="mobile-only">Status:{' '}</span>
                    {type === 'EDIT' ? 'Edit Submitted' : type === 'DELETE' ? 'Delete Submitted' : '-'}
                </Table.Cell>
                <Table.Cell>
                    <span className="mobile-only">Last updated:{' '}</span>
                    {updatedAt}
                </Table.Cell>
                <Table.Cell collapsing>
                    {((isAdmin || user === userId) && type === 'EDIT') && (
                        <Button primary onClick={() => setEditing(true)}>Edit</Button>
                    )}
                </Table.Cell>
                <Table.Cell collapsing>
                    {(isAdmin || user === userId) && (
                        <Button basic onClick={() => deleteEvent(_id)}>Delete</Button>
                    )}
                </Table.Cell>
                <Table.Cell collapsing>
                    {isAdmin && (type === "EDIT" ? (
                        <Button color="green" onClick={() => approveRequest(_id)}>Approve</Button>
                    ): type === "DELETE" && (
                        <Button color="green" onClick={() => approveDeleteRequest(_id)}>Approve</Button>
                    ))}
                </Table.Cell>
            </Table.Row>
        );
    }


    return (
        <>
            {main}
            {"requests" in event && event.requests.map((
                request => <EventRow parentEvent={event} event={request} key={request._id} />

            ))}
        </>
    );
}

/**
 * Format the links to be displayed in a short form.
 */
function formatLink(link: string) {
    if (!link) {
        return "";
    }

    if (link.startsWith("http://")) {
        link = link.replace("http://", "");
    } else if (link.startsWith("https://")) {
        link = link.replace("https://", "");
    }

    if (link.startsWith("www.")) {
        link = link.replace("www.", "");
    }

    if (link.length > 20) {
        return `${link.substring(0, 20)}...`;
    }

    return link;
}