import { useState } from "react";
import { Button, Image, Table } from "semantic-ui-react";
import { EditOrganizerRow } from "./EditOrganizerRow";
import { IOrganizer } from "./types";
import useAdminOrganizers from "./useAdminOrganizers";

const placeholder = "https://www.e-designeroutlet.com/media/catalog/product/cache/88693066e778cee687b8a895a940c49c/3/Z/3Z1M71.1J36Z_MAG-41yC51acioL..jpg"

export default function OrganizerRow({ organizer }: { organizer: IOrganizer }) {
  const { deleteOrganizer } = useAdminOrganizers();
  const [editing, setEditing] = useState(false);

  if (editing)
    return <EditOrganizerRow organizer={organizer} exit={() => setEditing(false)} />;

  return (
    <Table.Row key={organizer._id}>
      <Table.Cell>
        <Image size="mini" src={cropImage(organizer.image) || placeholder} circular />
      </Table.Cell>
      <Table.Cell>{organizer.name}</Table.Cell>
      <Table.Cell>{organizer.url}</Table.Cell>
      <Table.Cell>{organizer.description}</Table.Cell>
      <Table.Cell>{organizer.showAllEvents ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>{organizer.showAllEventInfo ? "Yes" : "No"}</Table.Cell>
      <Table.Cell collapsing>
        <Button primary onClick={() => setEditing(true)}>Edit</Button>
      </Table.Cell>
      <Table.Cell collapsing>
        <Button basic onClick={() => deleteOrganizer(organizer._id)}>Delete</Button>
      </Table.Cell>
    </Table.Row>
  );
}

const cropImage = (image?: string) => {
  return (image || '').replace('/upload/', '/upload/c_fill,h_256/');
}
