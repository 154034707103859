import { BehaviorSubject } from "rxjs";
import config from "../_helpers/config";
import jwtDecode from "jwt-decode";
import { getAsync, postAsync, putAsync } from "../_helpers/adapters";
import authStore from "../stores/auth-store";

export const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("authServCurrentUser"))
);

const authenticationService = {
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },

  // A method for removing user details and token from local storage to logout user
  logout() {
    localStorage.removeItem("authServCurrentUser");
    localStorage.removeItem("currentUserToken");
    localStorage.removeItem("currentUserRefreshToken");
    currentUserSubject.next(null);
  },

  // A method for logging in a user
  async login(email, password) {
    try {
      var res = await postAsync(`${config.apiUrl}/users/login`, {
        email,
        password,
      });

      // Store user details and JWT token in local storage to keep a user logged in between page refreshes
      var user = jwtDecode(res.token);
      localStorage.setItem("authServCurrentUser", JSON.stringify(user));
      localStorage.setItem("currentUserToken", res.token);
      localStorage.setItem("currentUserRefreshToken", res.refreshToken);
      currentUserSubject.next(user);
      return res.token;
    } catch (err) {
      throw err;
    }
  },

  // Method for refreshing token
  async updateToken() {
    try {
      var refreshToken = localStorage.getItem("currentUserRefreshToken");
      const decodedRefreshToken = jwtDecode(refreshToken);
      const refreshTokenExpired = (decodedRefreshToken.exp - (Date.now() / 1000) <= 0);
      if (refreshTokenExpired) {
        throw new Error('Login status expired')
      }
      var res = await postAsync(`${config.apiUrl}/users/token/refresh`, {
        refreshToken,
      });

      // Store user details and JWT token in local storage to keep a user logged in between page refreshes
      var user = jwtDecode(res.token);
      localStorage.setItem("authServCurrentUser", JSON.stringify(user));
      localStorage.setItem("currentUserToken", res.token);
      localStorage.setItem("currentUserRefreshToken", res.refreshToken);
      currentUserSubject.next(user);

      return res.token;
    } catch (err) {
      this.logout();
      authStore.logout();
    }
  },

  // A method for registering a user
  async register(fullName, email, password, sessionId) {
    try {
      var res = await postAsync(`${config.apiUrl}/users/signup`, {
        fullName,
        email,
        password,
        // agreedToTerms,
        sessionId,
      });

      // Clear session id
      localStorage.removeItem("sessionId");
      sessionStorage.removeItem("sessionId");

      // Store user details and JWT token in local storage to keep a user logged in between page refreshes
      var user = jwtDecode(res.token);
      localStorage.setItem("authServCurrentUser", JSON.stringify(user));
      localStorage.setItem("currentUserToken", res.token);
      localStorage.setItem("currentUserRefreshToken", res.refreshToken);
      currentUserSubject.next(user);
      return res.token;
    } catch (err) {
      throw err;
    }
  },

  // A method for requesting SMS verification
  async requestSMSVerification(phoneNumber) {
    try {
      await postAsync(`${config.apiUrl}/users/request/verification/sms`, {
        phoneNumber,
      });
    } catch (err) {
      throw err;
    }
  },

  // A method for verifying SMS
  async verifySMS(phoneNumber, code) {
    try {
      let res = await postAsync(`${config.apiUrl}/users/verify/sms`, {
        phoneNumber,
        code,
      });

      // Store user details and JWT token in local storage to keep a user logged in between page refreshes
      var user = jwtDecode(res.token);
      localStorage.setItem("currentUser", JSON.stringify(user));
      localStorage.setItem("authServCurrentUser", JSON.stringify(user));
      localStorage.setItem("currentUserToken", res.token);
      currentUserSubject.next(user);
      return user;
    } catch (err) {
      throw err;
    }
  },

  // A method for requesting email verification
  async requestEmailVerification() {
    try {
      await getAsync(`${config.apiUrl}/users/request/verification/email`);
      // Store user details and JWT token in local storage to keep a user logged in between page refreshes
    } catch (err) {
      throw err;
    }
  },

  // A method for verifying email change request
  async requestEmailChangeVerification(email) {
    try {
      await postAsync(`${config.apiUrl}/users/request/email-change`, {
        email,
      });

    } catch (err) {
      throw err;
    }
  },

  async changeEmailAndSendCode(email) {
    try {
      let res = await postAsync(`${config.apiUrl}/users/request/change-email-send-code`, {
        email,
      });

      // Store user details and JWT token in local storage to keep a user logged in between page refreshes
      var user = jwtDecode(res.token);
      localStorage.setItem("currentUser", JSON.stringify(user));
      localStorage.setItem("authServCurrentUser", JSON.stringify(user));
      localStorage.setItem("currentUserToken", res.token);
      currentUserSubject.next(user);
    } catch (err) {
      throw err;
    }
  },

  // A method for verifying email
  async verifyEmail(code) {
    try {
      let res = await postAsync(`${config.apiUrl}/users/verify/email`, {
        code,
      });

      // Store user details and JWT token in local storage to keep a user logged in between page refreshes
      var user = jwtDecode(res.token);
      localStorage.setItem("currentUser", JSON.stringify(user));
      localStorage.setItem("authServCurrentUser", JSON.stringify(user));
      localStorage.setItem("currentUserToken", res.token);
      currentUserSubject.next(user);
      return user;
    } catch (err) {
      throw err;
    }
  },

  // A method for verifying an email change request
  async verifyEmailChangeRequest(email, code) {
    // var body= JSON.parse({"email":email,"code":code});
    try {
      let res = await postAsync(
        `${config.apiUrl}/users/email-verification/verify`,
        {
          email,
          code,
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  },

  // A method for changing email
  async verifyEmailChangeVerification(email, code) {
    try {
      let res = await postAsync(`${config.apiUrl}/users/change-email`, {
        email,
        code,
      });

      var user = jwtDecode(res.token);
      localStorage.setItem("currentUser", JSON.stringify(user));
      localStorage.setItem("authServCurrentUser", JSON.stringify(user));
      localStorage.setItem("currentUserToken", res.token);
      currentUserSubject.next(user);
      return user;
    } catch (err) {
      throw err;
    }
  },
  async changeStudentEmail(email) {
    try {
      let res = await putAsync(`${config.apiUrl}/users/email/student`, {
        email,
      });

      var user = jwtDecode(res.token);
      localStorage.setItem("currentUser", JSON.stringify(user));
      localStorage.setItem("authServCurrentUser", JSON.stringify(user));
      localStorage.setItem("currentUserToken", res.token);
      currentUserSubject.next(user);
      return user;
    } catch (err) {
      throw err;
    }
  },

  // A method for updating user's profile
  async updateProfile(
    fullName,
    email,
    emailVerificationCode,
    phoneNumber,
    phoneNumberVerificationCode,
    currentPassword,
    newPassword,
    instagram
  ) {
    try {
      let res = await putAsync(`${config.apiUrl}/users/`, {
        fullName,
        email,
        emailVerificationCode,
        phoneNumber,
        phoneNumberVerificationCode,
        currentPassword,
        newPassword,
        instagram,
      });

      return res;
    } catch (err) {
      throw err;
    }
  },

  async updateInstagram(instagram) {
    try {
      let res = await putAsync(`${config.apiUrl}/users/instagram`, {
        instagram,
      });

      return res;
    } catch (err) {
      throw err;
    }
  },


  // A method for getting password reset link
  async passwordResetInfo(email) {
    try {
      var res = await postAsync(
        `${config.apiUrl}/users/forget/password/reset`,
        {
          email,
        }
      );
      return res;
    } catch (err) {
      throw err;
    }
  },

  // A method for resetting password
  async passwordReset(password) {
    try {
      var res = await postAsync(`${config.apiUrl}/users/reset-password`, {
        password,
      });
      return res;
    } catch (err) {
      throw err;
    }
  },

  // A method for getting user's information
  async getUser() {
    try {
      var res = await getAsync(`${config.apiUrl}/users`);

      return res;
    } catch (err) {
      throw err;
    }
  },

  // A method for getting list of organizers 
  async getOrganizers() {
    try {
      var res = await getAsync(`${config.apiUrl}/organizers/all`);
      return res;
    } catch (err) {
      throw err;
    }
  },

  // A method for getting list of events with organizer info
  async getEvents() {
    try {
      var res = await getAsync(`${config.apiUrl}/events/`);
      return res;
    } catch (err) {
      throw err;
    }
  },

  // A method for getting list of subscribed events with organizer info
  async getSubscribedEvents() {
    try {
      var res = await getAsync(`${config.apiUrl}/subscriptions/events/`);
      return res;
    } catch (err) {
      throw err;
    }
  },

  // A method for getting list of organizers user subscribed to
  async getSubscribedOrganizers() {
    try {
      var res = await getAsync(`${config.apiUrl}/subscriptions/`);
      return res.organizers;
    } catch (err) {
      throw err;
    }
  },

  // A method for getting user preference like fliter organizers
  async getUserPreference() {
    try {
      var res = await getAsync(`${config.apiUrl}/user-preferences/`);
      return res;
    } catch (err) {
      throw err;
    }
  },

  // A method for getting statistics
  async getUserStatistics() {
    try {
      var res = await getAsync(`${config.apiUrl}/users/sign-up-flow-statistics/by-stages`);
      return res;
    } catch (err) {
      throw err;
    }
  },

  // A method for subscribing to organizers
  // SUGGESTION: rename this to saveSubscriptions() or updateSubscriptions()
  async subscribeOrganizers(subscribeOrganizerIds, unsubscribeOrganizerIds, organizerPage) {
    try {
      var res = await putAsync(`${config.apiUrl}/subscriptions/`, {
        subscribeOrganizerIds,
        unsubscribeOrganizerIds,
      });
      return res;
    } catch (err) {
      throw err;
    }
  },

  // A method for updating user preference
  async updateUserPreference(data) {
    try {
      var res = await putAsync(`${config.apiUrl}/user-preferences/`, data);
      return res;
    } catch (err) {
      throw err;
    }
  },

  async getAllNotificationPreferences() {
    try {
      var res = await getAsync(`${config.apiUrl}/user-preferences/notifications`);
      return res;
    } catch (err) {
      throw err;
    }
  },

  async saveBrowserNotificationPreference(data) {
    try {
      const res = await putAsync(`${config.apiUrl}/user-preferences/browser-notifications`, data);
      return res;
    } catch (err) {
      throw err;
    }
  },

  async trackAppInstall(data) {
    try {
      const res = await postAsync(`${config.apiUrl}/user-preferences/app-install-tracking`, data);
      return res;
    } catch (err) {
      throw err;
    }
  },

  updateUserSubject(data) {
    const userString = localStorage.getItem("authServCurrentUser");
    const user = JSON.parse(userString);
    const updatedUser = {
      ...user,
      ...data,
    };

    localStorage.setItem("authServCurrentUser", JSON.stringify(updatedUser));
    currentUserSubject.next(updatedUser);
  }
};

export default authenticationService;
