import { useTransition, a } from "@react-spring/web";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styles from "../../css/InstallInstructionModal.module.css";
import usePWAInstall from "../../hooks/usePWAInstall";
import HowToGif from '../../images/ios-safari-instructions.gif';


interface ModalProps {
  open: boolean;
  onClose: () => void;
}

export default function InstallInstructionModal({open, onClose}: ModalProps) {
  const [containerZIndex, setContainerZIndex] = useState(-1);
  const modalRef = useRef<HTMLDivElement>(null);
  const { callback } = usePWAInstall();

  useEffect(() => {
    function closeOnTouchOutside(e: TouchEvent) {
      if (open) {
        console.log(e.target);
        if (!modalRef.current?.contains(e.target as Node)) {
          onClose();
        }
      }
    }

    window.addEventListener("touchend", closeOnTouchOutside);

    if (open) {
      setContainerZIndex(3);
    } else {
      setTimeout(() => setContainerZIndex(-1), 300);
    }

    return () => {
      window.removeEventListener("touchend", closeOnTouchOutside);
    }
  }, [open, onClose]);
  


  const transitions = useTransition(open, {
    from: { opacity: 0, transform: "translateY(100%)" },
    enter: { opacity: 1, transform: "translateY(0%)" },
    leave: { opacity: 0, transform: "translateY(100%)" },
    config: {
      mass: 1,
      tension: 150,
      friction: 20,
      clamp: true,
    },
  });

  const handleClose = () => {
    onClose();
    callback();
  };


  return createPortal(transitions((style, show) => (
    <div className={`${styles.container}`} style={{ zIndex: containerZIndex }}>
      {show && (
        <a.div style={style} ref={modalRef} className={styles.modal}>
          <div className={styles.header}>
            <div className={styles.title}>Add to Home Screen</div>
            <div className={styles.close} onClick={handleClose}>
              {'×'}
            </div>
          </div>
          <div className={styles.content}>
            <img className={styles.gif} src={HowToGif} alt="How to add to homescreen" />
            {/* <ul>
              <li>
                Open the <b>Settings</b>.
              </li>
              <li>
                Tap <b>Add to Home Screen</b>.
              </li>
            </ul> */}
          </div>
        </a.div>
      )}
    </div>
  )), document.body);
}