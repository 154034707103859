import { action, observable, makeObservable, runInAction } from "mobx";

class LoadingStore {
  constructor() {
    makeObservable(this, {
      loading: observable,
      pb_loading: observable,
      pb_loaded: observable,
      page_loading: observable,
      setLoading: action,
      setLoaded: action,
      setPBLoading: action,
      setPBLoaded: action,
      setPageMaskLoading: action,
      setPageMaskLoaded: action
      // setPageLoading: action,
    });
  }

  loading = false;
  pb_loading = false;
  pb_loaded = false;
  page_loading = false;

  // set loading to tru
  setLoading() {
    this.loading = true;
  }

  //set loading to false
  setLoaded() {
    this.loading = false;
  }

  setPageMaskLoading() {
    this.page_loading = true;
  }

  setPageMaskLoaded() {
      this.page_loading = false;
  }
 
  
  setPBLoading() {
    this.loading = true;
    this.pb_loading = true;
    this.pb_loaded = false;
  }

  setPBLoaded() {
    this.pb_loaded = true;
    this.pb_loading = false;

    setTimeout(runInAction(() => {
      this.pb_loaded = false;
      this.loading = false;
    }), 700);
  }
}

export default new LoadingStore();
