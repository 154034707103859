import { getAsync, postAsync } from "../_helpers/adapters";
import config from "../_helpers/config";

const baseUrl = `${config.apiUrl}/walkthrough`;

export const getSeenHints = () =>
  getAsync(`${baseUrl}/hints`) as Promise<string[]>;

export const trackHint = (hint: string) =>
  postAsync(`${baseUrl}/hint`, {hint});
