import { Button, Icon } from 'semantic-ui-react';
import IcpLogo from '../../images/icp-logo.svg';


const Icons = () => (
    <div className="icons-container">
        <a href="https://instagram.com/leuveneventplanner" target="_blank" rel="noreferrer">
            <i className="fa fa-instagram" />
        </a>

        <a href="https://wa.me/32496608026" target="_blank" rel="noreferrer">
            <i className="fa fa-whatsapp" />
        </a>

    </div>
)

// Change this to enable name on mobile navbar
const NAME_ON_MOBILE = false;


const LandingPageNav = ({ hiddeButtons, joinColor, onJoinClick, onLoginClick, className }) => {

    return (
        <div className={`lpnav-wrapper ${className || ''}`}>
            <nav className="lpnav">
                <a href="/#">
                    <img src={IcpLogo} height="34" alt="logo" />
                    <span className={`icp-name ${NAME_ON_MOBILE ? 'name-on-mobile': ''}`}>Leuven Event Planner</span>
                </a>

                {hiddeButtons ? (
                    <Icons />
                ) : (
                    <div className={`navbar-buttons ${NAME_ON_MOBILE ? 'name-on-mobile': ''}`}>
                        <Icons />
                        <Button primary onClick={onLoginClick}>
                            Log In
                        </Button>

                        <Button
                            style={{
                                color: joinColor,
                                backgroundColor: 'white',
                            }}
                            className="signup-button"
                            onClick={onJoinClick}
                        >
                            Sign Up
                        </Button>
                    </div>
                )}
            </nav>
        </div>
    )
}

export default LandingPageNav;