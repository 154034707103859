import React, { createContext, useEffect, useState } from "react";

interface IRandomLocationContext {
  terms: string;
}

export const RandomLocationContext = createContext<IRandomLocationContext>({
  terms: "",
});

export const RandomLocationProvider = ({ children }: { children: React.ReactNode}) => {
  const [terms, setTerms] = useState("");

  useEffect(() => {
    setTerms("/"+Math.random().toString(32).slice(-10));
  }, []);

  return (
    <RandomLocationContext.Provider value={{ terms }}>
      {children}
    </RandomLocationContext.Provider>
  ) 
}


