import moment from "moment-timezone";
import { DropdownItemProps } from "semantic-ui-react";

export const sortFunctions: { [key: string]: SortFn<ParsedEvent | ParsedEventRequest> } = {
  "organizer": (a, b) => (a.organizer?.name || "").localeCompare((b.organizer?.name || "")),
  "-organizer": (a, b) => (b.organizer?.name || "").localeCompare((a.organizer?.name || "")),
  "title": (a, b) => a.title.localeCompare(b.title),
  "-title": (a, b) => b.title.localeCompare(a.title),
  "startDate": (a, b) => {
      if (a.startDate.isSame(b.startDate)) return 0;
      if (a.startDate.isBefore(b.startDate)) return -1;
      return 1;
  },
  "-startDate": (a, b) => {
      if (a.startDate.isSame(b.startDate)) return 0;
      if (a.startDate.isBefore(b.startDate)) return 1;
      return -1;
  },
  "endDate": (a, b) => {
      if (a.endDate.isSame(b.endDate)) return 0;
      if (a.endDate.isBefore(b.endDate)) return -1;
      return 1;
  },
  "-endDate": (a, b) => {
      if (a.endDate.isSame(b.endDate)) return 0;
      if (a.endDate.isBefore(b.endDate)) return 1;
      return -1;
  },
  "updatedAt": (a, b) => {
      if (a.updatedAt.isSame(b.updatedAt)) return 0;
      if (a.updatedAt.isBefore(b.updatedAt)) return -1;
      return 1;
  },
  "-updatedAt": (a, b) => {
      if (a.updatedAt.isSame(b.updatedAt)) return 0;
      if (a.updatedAt.isBefore(b.updatedAt)) return 1;
      return -1;
  }
}

export const filterFunctions: { [key: string]: FilterFn<ParsedEvent | ParsedEventRequest> } = {
  "all": (event) => true,
  "past": (event) => event.endDate.isBefore(moment()),
  "current": (event) => event.startDate.isSameOrBefore(moment()) && event.endDate.isSameOrAfter(moment()),
  "future": (event) => event.startDate.isAfter(moment()),
}


export const sortOptions: DropdownItemProps[] = [
  { key: "organizer", text: "Organizer", value: "organizer" },
  { key: "-organizer", text: "Organizer (descending)", value: "-organizer" },
  { key: "title", text: "Title", value: "title" },
  { key: "-title", text: "Title (descending)", value: "-title" },
  { key: "startDate", text: "Start Date", value: "startDate" },
  { key: "-startDate", text: "Start Date (descending)", value: "-startDate" },
  { key: "endDate", text: "End Date", value: "endDate" },
  { key: "-endDate", text: "End Date (descending)", value: "-endDate" },
  { key: "updatedAt", text: "Last Updated", value: "updatedAt" },
  { key: "-updatedAt", text: "Last Updated (descending)", value: "-updatedAt" }
];


export const filterOptions: DropdownItemProps[] = [
  { key: "all", text: "All", value: "all" },
  { key: "past", text: "Past", value: "past" },
  { key: "current", text: "Current", value: "current" },
  { key: "future", text: "Future", value: "future" },
];

export const emptyEvent: NewEvent = {
  title: "",
  startDate: "",
  endDate: "",
  link: "",
  cancelled: false,
  organizer: {},
  categories: [],
};


export function parseEvents(response: ResponseEvents) {
  const events = response.events.map(event => ({
      ...event,
      requests: response.eventRequests.filter(request => request.event === event._id),
  }))

  const addRequests = response.eventRequests.filter(({ type }) => type === "ADD");
  return [...events, ...addRequests].map(parseEvent);
}

export function parseEvent(event: RawEvent | RawEventRequest): ParsedEvent | ParsedEventRequest {
  return {
      ...event,
      startDate: moment.tz(event.startDate, 'Europe/Brussels'),
      endDate: moment.tz(event.endDate, 'Europe/Brussels'),
      updatedAt: moment.tz(event.updatedAt, 'Europe/Brussels'),
      createdAt: moment.tz(event.createdAt, 'Europe/Brussels'),
      requests: "requests" in event ? event.requests.map(request => ({
          ...request,
          startDate: moment.tz(request.startDate, 'Europe/Brussels'),
          endDate: moment.tz(request.endDate, 'Europe/Brussels'),
          updatedAt: moment.tz(request.updatedAt, 'Europe/Brussels'),
          createdAt: moment.tz(request.createdAt, 'Europe/Brussels'),
      })) : [],
  };
}


export function formatEvent(event: ParsedEvent | ParsedEventRequest): RawEvent | RawEventRequest {
  return {
      ...event,
      startDate: formatDate(event.startDate),
      endDate: formatDate(event.endDate),
      updatedAt: formatDate(event.updatedAt),
      createdAt: formatDate(event.createdAt),
      requests: "requests" in event ? event.requests.map(request => ({
          ...request,
          startDate: formatDate(request.startDate),
          endDate: formatDate(request.endDate),
          updatedAt: formatDate(request.updatedAt),
          createdAt: formatDate(request.createdAt),
      })) : [],
  };
}

export function formatDate(date: moment.Moment | undefined): string {
  if (!date) return "";
  return date.format("DD/MM/YYYY HH:mm");
}

export function formatEventsToSearch(events: Array<RawEvent | RawEventRequest>): EventSearchItem[] {
  return events.map(event => ({
      ...event,
      link: event.link.replace(/^https?:\/\/(www\.)?/, ""),
      _link: event.link,
      featured: event.featured,
      _featured: `Featured ${event.featured ? "Yes" : "No"}`,
      cancelled: event.cancelled ? "Yes" : "No",
      status: event.status || "Submitted",
      requests: "requests" in event ? event.requests.map(request => ({
          ...request,
          link: event.link.replace(/^https?:\/\/(www\.)?/, ""),
          _link: event.link,
          featured: event.featured,
          _featured: `Featured ${event.featured ? "Yes" : "No"}`,
          cancelled: request.cancelled ? "Yes" : "No",
          status: "Edit Submitted"
      })) : [],
  }));
}

export function isRequest(event: ParsedEvent | ParsedEventRequest): event is ParsedEventRequest;
export function isRequest(event: RawEvent | RawEventRequest): event is RawEventRequest;
export function isRequest(event: ParsedEvent | ParsedEventRequest | RawEvent | RawEventRequest): event is ParsedEventRequest | RawEventRequest {
  return "type" in event;
}


export function isEvent(event: NewEvent | RawEvent | RawEventRequest): event is RawEvent | RawEventRequest {
  return "_id" in event; 
}