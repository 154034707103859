import React, { createContext, useEffect, useState } from "react";

interface IPopupManagerContext {
  showedInstallPopup: boolean
  setShowedInstallPopup: (showed: boolean) => void
  showedNotificationsPopup: boolean
  setShowedNotificationsPopup: (showed: boolean) => void
  showedAllPopups: boolean
}

const PopupManagerContext = createContext<IPopupManagerContext|null>(null);

export const PopupManagerProvider = ({ children }: { children: React.ReactNode}) => {
  const [showedInstallPopup, setShowedInstallPopup] = useState(false);
  const [showedNotificationsPopup, setShowedNotificationsPopup] = useState(false);
  const [showedAllPopups, setShowedAllPopups] = useState(false);

  useEffect(() => {
    if (
      showedInstallPopup &&
      showedNotificationsPopup  
    ) {
      setTimeout(() => {
        setShowedAllPopups(true);
      }, 1000);
    }
  }, [showedInstallPopup, showedNotificationsPopup]);

  console.log("showedAllPopups", showedAllPopups);
  console.log("showedInstallPopup", showedInstallPopup);
  console.log("showedNotificationsPopup", showedNotificationsPopup);

  return (
    <PopupManagerContext.Provider value={{
      showedInstallPopup,
      setShowedInstallPopup,
      showedNotificationsPopup,
      setShowedNotificationsPopup,
      showedAllPopups,
    }}>
      {children}
    </PopupManagerContext.Provider>
  );
}

export default function usePopupManager() {
  const context = React.useContext(PopupManagerContext);
  if (context === null) {
    throw new Error("usePopupManager must be used within a PopupManagerProvider");
  }
  return context;
}