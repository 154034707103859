import usePreferences from "../../hooks/usePreferences";
import styles from "../../css/NotificationBanner.module.css";
import authenticationService from "../../_services/authentication.service";
import { useLocation } from "react-router-dom";
import Bowser from "bowser";
import { useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import { createPortal } from "react-dom";
import { useTransition, animated as a } from "@react-spring/web";
import usePopupManager from "../../contexts/PopupManagerContext";

export default function NotificationBanner() {
  const [requestedPermission, setRequestedPermission] = useState(false);
  const [show, setShow] = useState(false);
  const { preferences } = usePreferences();
  const location = useLocation();
  const { setShowedNotificationsPopup } = usePopupManager();



  useEffect(() => {
    try {
      setRequestedPermission(
        sessionStorage.getItem("requestedPermission") === "true"
      );

      if ("Notification" in window && Notification.permission) {
        const parser = Bowser.getParser(window.navigator.userAgent);

        authenticationService.saveBrowserNotificationPreference({
          browser: parser.getBrowserName(),
          browserVersion: parser.getBrowserVersion(),
          platform: parser.getPlatformType(),
          os: parser.getOSName(),
          osVersion: parser.getOSVersion(),
          permission: Notification.permission,
        });
      }
    } catch { }
  }, []);

  const prompt = () => {
    Notification.requestPermission().then(permission => {
      // Store info on backend
      const parser = Bowser.getParser(window.navigator.userAgent);
      authenticationService.saveBrowserNotificationPreference({
        browser: parser.getBrowserName(),
        browserVersion: parser.getBrowserVersion(),
        platform: parser.getPlatformType(),
        os: parser.getOSName(),
        osVersion: parser.getOSVersion(),
        permission
      })
      
    });

    close();
  }

  const close = () => {
    setRequestedPermission(true);
    setShowedNotificationsPopup(true);
    try {
      sessionStorage.setItem("requestedPermission", "true");
    } catch { }
  }

  useEffect(() => {
    if (
      (
        Object.keys(preferences).length !== 0 && 
        !preferences.browserNotificationsSwitch
      ) ||
      !authenticationService.currentUserValue ||
      location.pathname !== "/" ||
      requestedPermission ||
      !("Notification" in window) ||
      Notification.permission === "granted" ||
      Notification.permission === "denied"
    ) {
      setShow(false);
      setShowedNotificationsPopup(true);
    } else {
      setShowedNotificationsPopup(false);
      setShow(true);
    }
  }, [preferences.browserNotificationsSwitch, requestedPermission, location.pathname]);

  const transitions = useTransition(show, {
    from: { opacity: 0, transform: "translateY(-100%)" },
    enter: { opacity: 1, transform: "translateY(0%)", delay: 3500 },
    leave: { opacity: 0, transform: "translateY(-100%)" },
    config: {
      mass: 2,
      tension: 150,
      friction: 20,
      clamp: true,
    },
  });



  return createPortal(transitions((style, show) => (
    <div className={`${styles.container}`} style={{ zIndex: 7 }}>
      {show && (

        <a.div style={style} className={styles.banner}>
          <div className={styles.text}>
            <Icon name="bell" className={styles.icon} /> Get notifications about events you subscribed to
          </div>

          <div className={styles.actions}>
            <Button basic className={styles.button} onClick={close}>
              No thanks
            </Button>

            <Button primary className={styles.button} onClick={prompt}>
              Yes please
            </Button>

          </div>
        </a.div>
      )}
    </div>
  )), document.body);
}