import React, { Fragment } from 'react';
import { Form, Button } from 'semantic-ui-react';
const { Input, Field } = Form;

// component generating forgot  password ui
export const ForgottenPassword = ({ sendResetEmail }) => {
  return (
    <Fragment>
      <div className="form forgetton-password" data-testid="form-div">
        <form onSubmit={sendResetEmail} autoComplete="off" data-testid="form">
          <div className="form-input-field-all">
            <input
              className="form-input-all"
              type="email"
              placeholder="Enter your email"
            />
          </div>
          <Field className="button">
            <Button primary>Send a password reset email</Button>
          </Field>
        </form>
      </div>
    </Fragment>
  );
};

export default ForgottenPassword;
