import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { hermes } from '../../utilities';
import { errors, success } from '../../messages';
import Bug from '../../components/report-bug/Bug';
import {
  deleteBug,
  getBugs,
  toggleFixed
} from '../../_services/bugs.service';
import { goTo } from '../../routing';
import { inject, observer } from 'mobx-react';

function ReportedBugs({ history, loadingStore }) {
  const [bugs, setBugs] = useBugs(history);

  const onDeleteBug = async (id) => {
    loadingStore.setLoading();

    try {
      const { deleted } = await deleteBug(id);
      if (deleted) {

        // Remove bug from list
        setBugs(bugs.filter(bug => bug._id !== id));
        hermes.success(success.BUG_REPORT_DELETED);
      } else {
        hermes.error("error");
      }
    } catch (e) {
      console.error(e);
      hermes.error("error");
    }

    loadingStore.setLoaded();
  }

  const onToggleFixed = async (id) => {
    loadingStore.setLoading();

    try {
      const updatedBug = await toggleFixed(id);
      if (updatedBug) {
        
        // Update bug in state
        setBugs(bugs.map(bug => bug._id === id ? { ...bug, fixed: updatedBug.fixed } : bug));
        hermes.success(success.BUG_REPORT_UPDATED);
      } else {
        hermes.error("error");
      }
    } catch (e) {
      console.error(e);
      hermes.error("error");
    }

    loadingStore.setLoaded();
  }  

  return (
    <Container style={{marginTop: "50px"}}>
      {bugs.length ? bugs.map((bug) => <Bug key={bug._id} {...bug} setBugs={setBugs} onToggleFixed={onToggleFixed} onDeleteBug={onDeleteBug} />
      ) : <h1>No Bugs found!</h1>}
    </Container>
  ) 
}


function useBugs(history) {
  const [bugs, setBugs] = useState([])

  useEffect(() =>  {
    (async () => {
      try {
        const data = await getBugs();
        if (data) {
          setBugs(data.bugs);
        } else {
          goTo('/', history);
        }
      } catch (e) {
        console.error(e);
        hermes.error("error");
      }
    })();
  }, [])

  return [bugs, setBugs];
}

export default inject('loadingStore')(withRouter(ReportedBugs));