import { getAsync, postAsync } from "../_helpers/adapters";
import config from "../_helpers/config";


export const getUsersSubscribeHitAnalytics = () =>
    getAsync(`${config.apiUrl}/analytics/subscribe-hits`);

export const getOrganizersSubscribeHitAnalytics = () =>
    getAsync(`${config.apiUrl}/analytics/subscribe-hits/organizers`);

export const getUsersPageViewsAnalytics = () =>
    getAsync(`${config.apiUrl}/analytics/page-views`);

export const getOrganizersPageViewsAnalytics = () =>
    getAsync(`${config.apiUrl}/analytics/page-views/organizers`);

export const trackOrganizerPageViewed = (data) =>
    postAsync(`${config.apiUrl}/analytics/page-view`, data);
export const trackSubscribeHit = (data) =>
    postAsync(`${config.apiUrl}/analytics/subscribe-hit`, data);

export const getUserInvitesAnalytics = () =>
    getAsync(`${config.apiUrl}/analytics/invites`);

export const getOrganizerInvitesAnalytics = () =>
    getAsync(`${config.apiUrl}/analytics/invites/organizers`);

export const getUserSessionPageViewsAnalytics = () =>
    getAsync(`${config.apiUrl}/analytics/session-page-views`);

export const getOrganizerSessionPageViewsAnalytics = () =>
    getAsync(`${config.apiUrl}/analytics/session-page-views/organizers`);

export const getInvitedBy = () =>
    getAsync(`${config.apiUrl}/analytics/invited-by`);

export const setInvitedBy = (data) =>
    postAsync(`${config.apiUrl}/analytics/invited-by`, data);

export const skipOrganizersPage = () =>
    getAsync(`${config.apiUrl}/analytics/skip-organizers-page`);