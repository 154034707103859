import { action, observable, makeObservable } from "mobx";
import { handleError, hermes } from "../utilities";
import loadingStore from "./loading-store";
import api from "../_services/authentication.service";
import { errors, success } from "../messages";

class ProfileStore {
  userData = null;
  profileData = null;

  constructor() {
    makeObservable(this, {
      updateUserObject: action,
      profileData: observable,
      userData: observable,
    });
  }

  // Gets user data
  async getUserData() {
    try {
      const response = await api.getUser();
      this.userData = response.user;
      return response.user;
    } catch (error) {
      throw handleError(error);
    }
  }

  // Gets sign up flow statistics by stage
  async getUserStatistics() {
    try {
      loadingStore.setLoading();
      const response = await api.getUserStatistics();
      loadingStore.setLoaded();
      return response;
      
    } catch (error) {
      throw handleError(error);
    }
  }

  // Updates user data when there is a change
  updateUserObject(data) {
    if (data) {
      this.userData = data;
    }
  }

  /**
   * Sends phone verification code
   *
   * @param number -> phone number
   */
  sendVerificationCode = async (number) => {
    try {
      loadingStore.setLoading();
      await api.requestSMSVerification(number);
      hermes.success(success.VERIFICATION_CODE_SMS_SENT);
      loadingStore.setLoaded();
    } catch (e) {
      loadingStore.setLoaded();
      throw handleError(e);
    }
  };

  /**
   * Sends email verification code
   *
   * @param email -> email
   */
  sendEmailVerificationCode = async (email) => {
    try {
      loadingStore.setLoading();
      await api.requestEmailChangeVerification(email);
      hermes.success(success.VERIFICATION_CODE_EMAIL_SENT);
      loadingStore.setLoaded();
    } catch (e) {
      loadingStore.setLoaded();
      throw handleError(e);
    }
  };

  changeEmailAndSendCode = async (email) => {
    try {
      const data = await api.changeEmailAndSendCode(email);
      hermes.success(success.VERIFICATION_CODE_EMAIL_SENT);
      loadingStore.setLoaded();
    } catch (e) {
      loadingStore.setLoaded();
      throw handleError(e);
    }
  };

  // Verifies email verification code
  verifyEmailCode = async (email, code) => {
    try {
      loadingStore.setLoading();
      const response = await api.verifyEmailChangeRequest(email, code);
      loadingStore.setLoaded();
      return response;
    } catch (e) {
      loadingStore.setLoaded();
      throw handleError(e);
    }
  };

  // update user profile
  updateProfile = async (profile) => {
    try {
      loadingStore.setLoading();
      await api.updateProfile(
        profile.fullName,
        profile.email,
        profile.emailVerificationCode,
        profile.phoneNumber,
        profile.phoneNumberVerificationCode,
        profile.currentPassword,
        profile.newPassword,
        profile.instagram
      );
      hermes.success(success.PROFILE_UPDATED);
      loadingStore.setLoaded();
    } catch (e) {
      loadingStore.setLoaded();
      throw handleError(e);
    }
  };

  /**
   * Verifies phone number verification code
   *
   * @param number -> phone number
   * @param code -> verification code
   */
  verifyCode = async (number, code) => {
    try {
      loadingStore.setLoading();
      // hermes.success("loading");
      const updatedUser = await api.verifySMS(number, code);
      hermes.success(success.PHONE_VERIFIED);

      this.updateUserObject(updatedUser);
      loadingStore.setLoaded();
    } catch (e) {
      loadingStore.setLoaded();
      throw handleError(e);
    }
  };

  /**
   * Verify email verification code
   *
   * @param email -> email
   * @param code -> verification code
   */
  verifyEmailVerification = async (email, code) => {
    try {
      loadingStore.setLoading();
      const updatedUser = await api.verifyEmailChangeVerification(email, code);
      hermes.success(success.PHONE_VERIFIED);

      this.updateUserObject(updatedUser);
      loadingStore.setLoaded();

      loadingStore.setLoaded();
    } catch (e) {
      loadingStore.setLoaded();
      throw handleError(e);
    }
  };
}

export default new ProfileStore();
