import useSWR from "swr";
import { getOrganizerSuggestions } from "../../_services/organizers.service";


export default function useOrganizerSuggestions() : IOrganizerSuggestion[] {
  const { data, error } = useSWR("organizerSuggestions", getOrganizerSuggestions);

  if (error) {
    console.error(error);
  }

  return data || [];
}