import { Button } from "semantic-ui-react";
import { createPortal } from "react-dom";
import { useEffect, useState } from "react";
import { useTransition, animated as a } from "@react-spring/web";
import Bowser from 'bowser';
import styles from "../../css/NotificationBanner.module.css";
import authenticationService from "../../_services/authentication.service";
import { useLocation } from "react-router-dom";
import usePWAInstall from "../../hooks/usePWAInstall";
import usePopupManager from "../../contexts/PopupManagerContext";

export default function InstallPopup() {
  const [prompted, setPrompted] = useState(false);
  const [show, setShow] = useState(false);
  const location = useLocation();
  const { showInstallInstructions, installPrompt } = usePWAInstall();
  const { setShowedInstallPopup, showedNotificationsPopup } = usePopupManager() 

  useEffect(() => {
    try {
      setPrompted(
        sessionStorage.getItem("promptedInstall") === "true"
      );
    } catch { }
  }, []);

  useEffect(() => {
    const parser = Bowser.getParser(window.navigator.userAgent);
    const os = parser.getOS().name;
    const browser = parser.getBrowser().name;
    const iOS = os === "iOS";
    const safari = browser === "Safari";
    const android = os === "Android";
    const chrome = browser === "Chrome";


    // Only show on Safari IOS, and Android Chrome if not already prompted or if using installed app
    if (
      !prompted &&
      showedNotificationsPopup &&
      ((iOS && safari) || (android && chrome && installPrompt !== null)) &&
      // @ts-ignore - navigator.standalone is not typed
      !(window.navigator.standalone || window.matchMedia("(display-mode: standalone)").matches) &&
      authenticationService.currentUserValue &&
      authenticationService.currentUserValue.isEmailVerified &&
      authenticationService.currentUserValue.isSMSVerified &&
      location.pathname === "/"
    ) {
      setShow(true);
    } else {
      setShow(false);
      if (showedNotificationsPopup) {
        setShowedInstallPopup(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prompted, location.pathname, authenticationService.currentUserValue, installPrompt, showedNotificationsPopup]);

  const transitions = useTransition(show, {
    from: { opacity: 0, transform: "translateY(-100%)" },
    enter: { opacity: 1, transform: "translateY(0%)", delay: 3500 },
    leave: { opacity: 0, transform: "translateY(-100%)" },
    config: {
      mass: 2,
      tension: 150,
      friction: 20,
      clamp: true,
    },
  });

  const close = () => {
    const parser = Bowser.getParser(window.navigator.userAgent);
    authenticationService.trackAppInstall({
      browser: parser.getBrowserName(),
      browserVersion: parser.getBrowserVersion(),
      platform: parser.getPlatformType(),
      os: parser.getOSName(),
      osVersion: parser.getOSVersion(),
      outcome: "dismissed"
    })

    setPrompted(true);
    setShowedInstallPopup(true);

    try {
      sessionStorage.setItem("promptedInstall", "true");
    } catch { }

    setShow(false);
  };

  const showInstructionsModal = () => {
    // Only show instructions if on IOS and Safari
    if (showInstallInstructions !== null) {
      showInstallInstructions?.(() => setShowedInstallPopup(true));
    } else if (installPrompt !== null) {
      installPrompt();
      setShowedInstallPopup(true);
    }

    setPrompted(true);
    try {
      sessionStorage.setItem("promptedInstall", "true");
    } catch { }

    setShow(false);
  };

  return createPortal(transitions((style, show) => (
    <div className={`${styles.container}`} style={{ zIndex: 5 }}>
      {show && (

        <a.div style={style} className={styles.banner}>
          <div className={styles.text}>
            {/* <Icon name="bell" className={styles.icon} /> */}
            Add our app to your home screen
          </div>

          <div className={styles.actions}>
            <Button basic className={styles.button} onClick={close}>
              No thanks
            </Button>

            <Button primary className={styles.button} onClick={showInstructionsModal}>
              Yes please
            </Button>

          </div>
        </a.div>
      )}
    </div>
  )), document.body);
} 