import { useEffect, useState } from "react";
import { Container, Header, Table } from "semantic-ui-react";
import loadingStore from "../../stores/loading-store";
import { formatDate } from "../../utilities";
import { getUserInvitesAnalytics } from "../../_services/analytics.service";
import _ from "lodash";

const OrgAnalytics5 = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        loadingStore.setPageMaskLoading();
        getUserInvitesAnalytics().then(data => {
            setUsers(_.sortBy(data, 'createdAt'));
        }).finally(() => {
            loadingStore.setPageMaskLoaded();
        });
    }, []);

    return (
        <Container className="analytics-container">
            <Header as="h1">
                <Header.Content>
                    Analytics for Subscribe button hit.
                </Header.Content>
            </Header>
            <Table className="analytics-table" inverted celled singleLine unstackable striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Email
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Phone
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Invited By
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Page Viewed
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Hit subscribe at
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Joined at
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {users.map(({fullName, email, phoneNumber, createdAt, invitedBy, _id}) =>
                        <Table.Row key={email}>
                            <Table.Cell>{fullName || _id}</Table.Cell>
                            <Table.Cell>{email || '-'}</Table.Cell>
                            <Table.Cell>{phoneNumber || '-'}</Table.Cell>
                            <Table.Cell>
                                <Table inverted singleLine unstackable>
                                    <Table.Body>
                                        {invitedBy.map(({ organizer: { _id, name }}) => (
                                            <Table.Row key={_id}>
                                                <Table.Cell>{name}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>    
                            </Table.Cell>
                            <Table.Cell>
                                <Table inverted singleLine unstackable>
                                    <Table.Body>
                                        {invitedBy.map(({_id, viewedAt}) => (
                                            <Table.Row key={_id}>
                                                <Table.Cell>{formatDate(viewedAt)}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </Table.Cell>
                            <Table.Cell>
                                <Table inverted singleLine unstackable>
                                    <Table.Body>
                                        {invitedBy.map(({organizer: { _id }, hitSubscribeAt}) => (
                                            <Table.Row key={_id}>
                                                <Table.Cell>{formatDate(hitSubscribeAt)}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </Table.Cell>
                            <Table.Cell>{formatDate(createdAt)}</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </Container>
    );
}

export default OrgAnalytics5;