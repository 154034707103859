// get item from local storage
function loadState(key) {
  const stringifiedData = localStorage.getItem(key);
  return JSON.parse(stringifiedData);
}

// update item on local storage
function updateState(key, data) {
  const stringifiedData = JSON.stringify(data);
  const savedData = localStorage.setItem(key, stringifiedData);
  return savedData;
}

//clear storage
function clearStorage() {
  localStorage.clear();
}

export default {
  clearStorage,
  updateState,
  loadState,
};
