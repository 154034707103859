import LandingPageNav from "../../components/shared/LandingPageNav";
import { useHistory } from "react-router-dom";
import ReactPlayer from 'react-player/youtube';
import config from "../../_helpers/config";
import styles from '../../css/Demo.module.css';

export default function Demo() {

  const history = useHistory();
  
  return (
    <>
    <LandingPageNav
      hiddeButtons={false}
      joinColor={"#1f364d"}
      onJoinClick={() => history.push(`/?action=join`)}
      onLoginClick={() => history.push(`/?action=login`)}
    />
    <div className={styles.container}>
      <div className={styles.limiter}>
        <div className={styles.wrapper}>
          <ReactPlayer
            url={config.demoVideo}
            width='100%'
            height='100%'
            autoplay
            controls
            muted
            playsinline
            
          />
        </div>
      </div>
    </div>

   </>
  );
}