//
import React from "react";
import Slider from "./slider";
import Slide1 from "./slide1";
import Slide4 from './slide4'
function LandingPage() {
    return (
      <Slider>
        <Slide4 />
        <Slide1 />
      </Slider>
    );
}

export default LandingPage;
