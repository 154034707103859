import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Container, Dropdown, Form, Label, Progress } from "semantic-ui-react";
import { goTo } from "../../routing";
import loadingStore from "../../stores/loading-store";
import { hermes } from "../../utilities";
import authApi, { currentUserSubject } from "../../_services/authentication.service";
import { getInvitedBy, setInvitedBy, skipOrganizersPage } from "../../_services/analytics.service";
import nanobar from "../../components/Nanobar";
import { suggestOrganizer } from "../../_services/organizers.service";


// to show list of selected or subscribed organizers
const SelectedEntity = ({ id, name, remove }) => {
    return (
        <Label className="entity-label" basic>
            {name}
            <div
                onClick={() => {
                    remove(id);
                }}
                className="remove"
            >
                <span>x</span>
            </div>
        </Label>
    );
};

const cropImage = (image) => {
    return (image || '').replace('/upload/', '/upload/c_fill,h_256/');
}


const OrganizersPage = () => {
    const [organizers, setOrganizers] = useState([]);
    const [selectedOrganizersInvitedBy, setSelectedOrganizersInvitedBy] = useState([]);
    const [selectedOrganizersToSubscribe, setSelectedOrganizersToSubscribe] = useState([]);
    const [initialOrganizers, setInitialOrganizers] = useState([]);
    // const [instagram, setInstagram] = useState('');
    const history = useHistory();   
    const [lastOrganizerSuggestion, setLastOrganizerSuggestion] = useState(0);

    useEffect(() => {
        loadingStore.setPageMaskLoading();
        nanobar.go(30);
        authApi.getOrganizers().then(res => {
            setOrganizers([
                {
                    key: "default",
                    text: "Search for organisers",
                    "data-id": "default"
                },
                ...res.organizers.map(organizer => ({
                    key: organizer._id,
                    text: organizer.name,
                    value: organizer._id,
                    image:  cropImage(organizer.image) || "https://via.placeholder.com/150",
                    description: organizer.description,
                }))
            ]);

        }).catch(err => {
            console.log(err);
            hermes.error("Error fetching organizers");
            loadingStore.setPageMaskLoaded();

        })
    }, [])

    useEffect(() => {
        if (!organizers.length) return;

        Promise.all([
            getInvitedBy(),
            authApi.getSubscribedOrganizers()
        ]).then(([organizersInvitedBy, resToSubscribe]) => {

            setSelectedOrganizersInvitedBy(organizersInvitedBy.map(
                organizerId => organizers.find(o => o.key === organizerId)
            ));

            setSelectedOrganizersToSubscribe(
                resToSubscribe.map(
                    subscription => organizers.find(org => org.key === subscription.organizer._id)
                )
            );

            setInitialOrganizers(
                resToSubscribe.map(
                    subscription => subscription.organizer._id
                )
            );
        }).catch(err => {
            console.log(err);
            hermes.error("Error fetching subscriptions");
        }).finally(() => {
            console.log("PageMaskLoaded");
            nanobar.go(100);
            loadingStore.setPageMaskLoaded();
        })
    }, [organizers])


    const handleChangeInvitedBy = (e, { value }) => {
        const organizer = organizers.find(organizer => organizer.value === value);

        if (!organizer) return;

        if (!selectedOrganizersInvitedBy.includes(organizer))
            setSelectedOrganizersInvitedBy([...selectedOrganizersInvitedBy, organizer]);
    }


    const handleChangeToSubscribe = (e, { value }) => {
        const organizer = organizers.find(organizer => organizer.value === value);

        if (!organizer) return;

        if (!selectedOrganizersToSubscribe.includes(organizer))
            setSelectedOrganizersToSubscribe([...selectedOrganizersToSubscribe, organizer]);
    }

    const handleRemoveInvitedBy = (id) => {
        setSelectedOrganizersInvitedBy(selectedOrganizersInvitedBy.filter(organizer => organizer.key !== id));
    }

    const handleRemoveToSubscribe = (id) => {
        setSelectedOrganizersToSubscribe(selectedOrganizersToSubscribe.filter(organizer => organizer.key !== id));
    }

    const handleSuggestOrganizer = async (e, { value }) => {
        try {
            if (lastOrganizerSuggestion + 30000 < Date.now()) {
              await suggestOrganizer(value);
              hermes.success("Your suggestion has been submitted");
              setLastOrganizerSuggestion(Date.now());
            }
          } catch (err) {
            hermes.error("Failed to submit suggestion");
          }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        loadingStore.setLoading();

        const selectedOrganizersToSubscribeIds = selectedOrganizersToSubscribe.map(organizer => organizer.key);
        const unsubscribedOrganizersIds = initialOrganizers.filter(organizer => !selectedOrganizersToSubscribeIds.includes(organizer));

        Promise.all([
            setInvitedBy(selectedOrganizersInvitedBy.map(organizer => organizer.key)),
            authApi.subscribeOrganizers(selectedOrganizersToSubscribeIds, unsubscribedOrganizersIds),
        ]).then(() => {
            hermes.success("Subscribed successfully");
            const userString = localStorage.getItem("authServCurrentUser");
            const user = JSON.parse(userString);
            user.isOrganizersPageCompleted = true;
            localStorage.setItem("authServCurrentUser", JSON.stringify(user));
            currentUserSubject.next(user);
            goTo("/onboarding/install", history);
        }).catch(err => {
            console.log(err);
            hermes.error("Error subscribing");
        }).finally(() => {
            loadingStore.setLoaded();
        });
    }

    const handleSkip = async () => {
        loadingStore.setLoading();
        await skipOrganizersPage();
        const userString = localStorage.getItem("authServCurrentUser");
        const user = JSON.parse(userString);
        user.isOrganizersPageCompleted = true;
        localStorage.setItem("authServCurrentUser", JSON.stringify(user));
        currentUserSubject.next(user);
        goTo("/onboarding/install", history);
    }



    return (
        <>
            <Progress
                percent={75}
                className="onboarding-progress-bar"
            />
            <Container fluid>
                <div>
                    <div className="onboarding-5-container organizers">
                        <div className="onboarding-heading">
                            Personalise your event feed
                        </div>

                        <div className="onboarding-sub-heading">
                            So you can quickly see events you like 
                        </div>

                        <Form onSubmit={handleSubmit}>
                            <Card>
                            <Form.Field>
                                    <label>
                                        Subscribe to one or more organisation - <i>Optional</i>
                                    </label>
                                    <Dropdown
                                        placeholder="Select organisations"
                                        onChange={handleChangeToSubscribe}
                                        onAddItem={handleSuggestOrganizer}
                                        options={organizers}
                                    />
                                    {!!selectedOrganizersToSubscribe.length && (
                                        <div className="entities-wrapper">
                                            {selectedOrganizersToSubscribe.map(organizer => (
                                                <SelectedEntity
                                                    id={organizer.value}
                                                    name={organizer.text}
                                                    key={organizer.key}
                                                    remove={handleRemoveToSubscribe}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </Form.Field>

                                <Form.Field>
                                    <label>
                                        Did any organisations refer you to us? - <i>Optional</i>
                                    </label>
                                    <Dropdown
                                        placeholder="Select organisations"
                                        onChange={handleChangeInvitedBy}
                                        onAddItem={handleSuggestOrganizer}
                                        options={organizers}
                                    />
                                    {!!selectedOrganizersInvitedBy.length && (
                                        <div className="entities-wrapper">
                                            {selectedOrganizersInvitedBy.map(organizer => (
                                                <SelectedEntity
                                                    id={organizer.value}
                                                    name={organizer.text}

                                                    const hANDLE
                                                    key={organizer.key}
                                                    remove={handleRemoveInvitedBy}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </Form.Field>
                               

                                {/* <Form.Field>
                                    <label>What is your instagram username?</label>
                                    <Input value={instagram} onChange={e => setInstagram(e.target.value)} placeholder="Example: clarasmith" />
                                </Form.Field> */}
                            </Card>
                            <Button type="button" primary onClick={handleSkip}> Skip </Button>
                            <Button type="submit" primary> Save </Button>
                        </Form>
                    </div>
                </div>
            </Container>
        </>
    );
}


export default OrganizersPage;