import config from "../_helpers/config";
import { getAsync, postAsync, putAsync, deleteAsync } from "../_helpers/adapters";

const endpoint = `${config.apiUrl}/bug-report`;


export async function getBugs() {
  return getAsync(endpoint);
}
export async function reportBug(data) {
  return postAsync(endpoint, data);
}
export async function toggleFixed(id) {
  return getAsync(`${endpoint}/toggle-fixed/${id}`);
}
export async function deleteBug(id) {
  return deleteAsync(`${endpoint}/${id}`);
}
