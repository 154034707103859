import React, { Fragment, useEffect } from "react";
import { Form, Button, Card } from "semantic-ui-react";
import { Container } from "semantic-ui-react";
import "../../css/onboarding.css";
import { Progress } from "semantic-ui-react";
import authenticationService from "../../_services/authentication.service";
import { hermes } from "../../utilities";
import { useHistory } from "react-router-dom";
import { BLOCK_STUDENT_EMAIL } from "../../constants";

function validateEmail(email: string) {
  if (email.endsWith("@student.kuleuven.be")) {
    return "Please use a non-student email address";
  }

  if (email.length === 0) {
    return "Please input your email";
  }

  // Check if email is valid
  const emailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/    
  if (!emailRegEx.test(email)) {
    return "Please input a valid email";
  }

  return "";
}

const RequestEmailChange = () => {
  const [email, setEmail] = React.useState("");
  const history = useHistory();

  useEffect(() => {
    const currentEmail = authenticationService.currentUserValue.email;
    
    if (
      !currentEmail.trim().endsWith("@student.kuleuven.be") ||
      !BLOCK_STUDENT_EMAIL
    ) {
      history.push("/onboarding/email");
    }
  }, [email, history]);

  const handleSubmit = async () => {
    try {
      const trimmedEmail = email.trim();
      const validationError = validateEmail(trimmedEmail);
      if (validationError.length > 0) {
        throw validationError;
      }
      await authenticationService.changeStudentEmail(trimmedEmail);
      history.push("/onboarding/email");
    } catch (error) {
      hermes.error(error);
    }
  };


  return (
    <Fragment>
      <Progress percent={50} className="onboarding-progress-bar" />
      <Container fluid>
        <div>
          <div className="onboarding-5-container">
            <div className="onboarding-heading">Input your personal email</div>
            <div className="onboarding-sub-heading ">
              Please input your personal email address.
            </div>

            <Form onSubmit={handleSubmit} autoComplete="off">
              <Card className="onboarding-form-wrapper">
                <Form.Field style={{ marginBottom: 0 }}>
                  <label>Email</label>

                  <input
                    type="email"
                    value={email}
                    name="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Field>
              </Card>
              <Button primary type="submit">
                Continue
              </Button>
            </Form>
          </div>
        </div>
      </Container>
    </Fragment>
  );
}

export default RequestEmailChange