import moment from "moment-timezone";
import { useState } from "react";
import { Button, Dropdown, Table } from "semantic-ui-react";
import { hermes, isValidUrl } from "../../utilities";
import styles from "./AdminEvents.module.css";
import { emptyEvent, isEvent, isRequest } from "./helpers";
import useAdminEvents from "./useAdminEvents";


export default function EditEventRow({ event, exit }: EditEventRowProps) {
    const [newEvent, setNewEvent] = useState<NewEvent | RawEvent | RawEventRequest>(event || emptyEvent);
    const { addEvent, editEvent, editEventRequest, organizers, categories } = useAdminEvents();
    const { validate, errors, clearError } = useValidation();
    

    async function handleSave() {
        if (!validate(newEvent)) {
            return
        }

        if (!isEvent(newEvent)) {
            await addEvent(newEvent);

        } else if (!isRequest(newEvent)) {
            await editEvent(newEvent);

        } else {
            await editEventRequest(newEvent);
        }
        exit();
    }

    return (
        <Table.Row className={styles.active}>
            <Table.Cell className={styles.edit}>
                <span className="mobile-only">Organizer:</span>
                <Dropdown
                    placeholder={newEvent.organizer?.name || "Select an organizer"}
                    search
                    selection
                    options={organizers.map(org => ({
                        key: org._id,
                        text: org.name,
                        value: org._id
                    }))}
                    onChange={(e, { value }) => {
                        let organizer = organizers.find(org => org._id === value) || {};
                        setNewEvent({ ...newEvent, organizer });
                    }}
                    className={errors.organizer ? styles.error : ""}
                    onFocus={() => clearError("organizer")}
                />


            </Table.Cell>
            <Table.Cell className={styles.edit}>
                <span className="mobile-only">Title:</span>
                <input
                    type="text"
                    value={newEvent.title}
                    placeholder="Title"
                    onChange={e => setNewEvent({ ...newEvent, title: e.target.value })}
                    className={errors.title ? styles.error : ""}
                    onFocus={() => clearError("title")}
                />
            </Table.Cell>
            <Table.Cell className={styles.edit}>
                <span className="mobile-only">Start Date:</span>
                <input
                    type="text"
                    value={newEvent.startDate}
                    placeholder="Start Date"
                    onChange={e => setNewEvent({ ...newEvent, startDate: e.target.value })}
                    className={errors.startDate ? styles.error : ""}
                    onFocus={() => clearError("startDate")}
                />
            </Table.Cell>
            <Table.Cell className={styles.edit}>
                <span className="mobile-only">End Date:</span>
                <input
                    type="text"
                    value={newEvent.endDate}
                    placeholder="End Date"
                    onChange={e => setNewEvent({ ...newEvent, endDate: e.target.value })}
                    className={errors.endDate ? styles.error : ""}
                    onFocus={() => clearError("endDate")}
                />
            </Table.Cell>
            <Table.Cell className={styles.edit}>
                <span className="mobile-only">Link:</span>
                <input
                    type="text"
                    value={newEvent.link}
                    placeholder="Link"
                    onChange={e => setNewEvent({ ...newEvent, link: e.target.value })}
                    className={errors.link ? styles.error : ""}
                    onFocus={() => clearError("link")}
                />
            </Table.Cell>
            <Table.Cell className={styles.edit}>
                <span className="mobile-only">Categories:</span>
                <Dropdown
                    placeholder={newEvent.categories?.join(", ") || "Select categories"}
                    // search
                    selection
                    multiple
                    options={categories.map(cat => ({
                        // key: cat._id,
                        text: cat.name,
                        value: cat._id
                    }))}
                    value={newEvent.categories.map(cat => cat._id)}
                    onChange={(e,  {value}) => {
                        setNewEvent({
                            ...newEvent,
                            categories: (value as string[]).map(
                                catId => categories.find(cat => cat._id === catId) || null
                            ).filter(cat => cat) as ICategory[]
                        });
                    }}
                    className={styles.categories}
                    // className={errors.categories ? styles.error : ""}  
                />
            </Table.Cell>
            <Table.Cell className={styles.edit}>
                <span className="mobile-only">Featured:</span>
                <Dropdown
                    search
                    selection
                    value={newEvent.featured}
                    options={[
                        { key: "true", text: "Yes", value: true },
                        { key: "false", text: "No", value: false },
                    ]}
                    onChange={(e, { value }) => setNewEvent({ ...newEvent, featured: !!value })}
                    placeholder={newEvent.featured ? "Yes" : "No"}
                />
            </Table.Cell>
            <Table.Cell className={styles.edit}>
                <span className="mobile-only">Cancelled:</span>
                <Dropdown
                    search
                    selection
                    value={newEvent.cancelled}
                    options={[
                        { key: "true", text: "Yes", value: true },
                        { key: "false", text: "No", value: false },
                    ]}
                    onChange={(e, { value }) => setNewEvent({ ...newEvent, cancelled: !!value })}
                    placeholder={newEvent.cancelled ? "Yes" : "No"}
                />
            </Table.Cell>
            <Table.Cell className={styles.edit}>
                <span className="mobile-only">Status:{' '}</span>
                {newEvent.status || '-'}</Table.Cell>
            <Table.Cell className={styles.edit}>
                <span className="mobile-only">Last updated:{' '}</span>
                {newEvent.updatedAt || '-'}</Table.Cell>
            <Table.Cell>
                <Button primary onClick={handleSave}>Save</Button>
            </Table.Cell>
            <Table.Cell>
                <Button basic type="button" onClick={exit}>Cancel</Button>
            </Table.Cell>
            <Table.Cell></Table.Cell>
        </Table.Row>
    );
}

function useValidation() {
    const [errors, setErrors] = useState({
        organizer: false,
        title: false,
        startDate: false,
        endDate: false,
        link: false,

    });

    function validate(event: RawEvent | NewEvent) {
        const _startDate = moment.tz(event.startDate, 'DD/MM/YYYY HH:mm', true, 'Europe/Brussels')
        const _endDate = moment.tz(event.endDate, 'DD/MM/YYYY HH:mm', true, 'Europe/Brussels')
        let hasErrors = false;

        if (!event.organizer?.name) {
            hermes.error("Please select an organizer");
            setErrors(errors => ({ ...errors, organizer: true }));
            hasErrors = true;
        }

        if (!event.title) {
            hermes.error("Please enter a title");
            setErrors(errors => ({ ...errors, title: true }));
            hasErrors = true;
        }

        if (!_startDate.isValid()) {
            hermes.error("Start date must be in the format DD/MM/YYYY HH:mm");
            setErrors(errors => ({ ...errors, startDate: true }));
            hasErrors = true;
        }

        if (!_endDate.isValid()) {
            hermes.error("End date must be in the format DD/MM/YYYY HH:mm");
            setErrors(errors => ({ ...errors, endDate: true }));
            hasErrors = true;
        }

        if (_startDate.isAfter(_endDate)) {
            hermes.error("End date must be after start date");
            setErrors(errors => ({ ...errors, endDate: true }));
            hasErrors = true;
        }

        if (!event.link || !isValidUrl(event.link)) {
            hermes.error("Please enter a valid link");
            setErrors(errors => ({ ...errors, link: true }));
            hasErrors = true;
        }

        return !hasErrors;


    }

    function clearError(field: string) {
        setErrors(errors => ({ ...errors, [field]: false }));
    }

    return { errors, validate, clearError };
}