import { useState } from "react";
import { Button, Card, Checkbox, Confirm, Container, Dropdown, Form, Header, Input, Label, Search, Segment, Select } from "semantic-ui-react";
import { sendNewsletter } from "../../_services/newsletter.service";


const SendNewsletter = () => {
  const [newsletterId, setNewsletterId] = useState('');
  const [subject, setSubject] = useState('');
  const [amount, setAmount] = useState(0);
  const [interval, setInterval] = useState(0);
  const [customOpenTracking, setCustomOpenTracking] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSend = async () => {
    setShowConfirmation(false);
    await sendNewsletter({
      amount,
      interval,
      newsletterId,
      customOpenTracking,
      subject
    });
  }

  return (
    <Container>
      <h1 >Send Newsletter</h1 >
      <Card>
        <Form onSubmit={() => setShowConfirmation(true)}>

          <Form.Field>
            <label>Newsletter ID</label>
            <Input
              placeholder='Newsletter ID'
              value={newsletterId}
              onChange={(e, { value }) => setNewsletterId(value)}
            />
          </Form.Field>

          <Form.Field>
            <label>Subject - Possible variables: name, events, and brand. Example: {"Hi {{ name }}, there are {{ events }} in {{ brand }}"} </label>
            <Input
              placeholder='Subject'
              value={subject}
              onChange={(e, { value }) => setSubject(value)}
            />
          </Form.Field>


          <Form.Field>
            <label>Maximum Amount (Leave at 0 to send to all)</label>
            <Input
              type='number'
              value={amount}
              min={0}
              onChange={(e) => setAmount(
                Math.abs(parseInt(e.target.value) || 0)
              )}
            />
          </Form.Field>



          <Form.Field>
            <label>Interval between each (in milliseconds)</label>
            <Input type='number'
              value={interval}
              min={0}
              onChange={(e) => setInterval(
                Math.abs(parseInt(e.target.value) || 0)
              )}
            />
          </Form.Field>

          <Form.Field>
            <Checkbox
              label='Custom Open Tracking'
              checked={customOpenTracking}
              onChange={() => setCustomOpenTracking(!customOpenTracking)}
            />
          </Form.Field> 
          <Button primary>Send</Button>


        </Form>
          <Confirm 
            open={showConfirmation}
            onCancel={() => setShowConfirmation(false)}
            onConfirm={handleSend}
            content='Are you sure you want to send this newsletter?'
            confirmButton='Send'
          />
      </Card>
    </Container>
  )
}


export default SendNewsletter;