import React from "react";
import IcpLogo from "../../images/icp-logo.svg";

export default () => (
  <div>
    <div
      className="landing-page-container fixed-viewport"
    >
      <div className="landing-page">
        <div className="landing-page-wrapper" style={{ maxWidth: "750px" }}>
          <div className="new-tagline-container">
            <h2 className="major-text">
              We only support <b>Google Chrome</b>, <b>Firefox</b>, <b>Opera</b> {' '}
              and <b>Safari</b> for now.
            </h2>
            <span className="minor-text">- Leuven Event Planner</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);
