const FeaturedEvent = ({ startDate, link, title, organizer={}}) => {
    const date = new Date(startDate);

    const day = date.getDate();
    const month = date.toLocaleString('en-us', { month: 'short' });
    

    return (
        <>
            <a className="featured-event" href={link} rel="noreferrer" target="_blank">
            <div className="date">
                <span className="month">{month}</span>
                <span className="day">{day}</span>
            </div>
            <div className="text">
                <span className="name">{title}</span>
                <span className="location">{organizer.name}</span>
            </div>
            </a>
        </>
    )
};

export default FeaturedEvent;