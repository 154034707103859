import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Button, Checkbox, Container, Form, Header, Segment } from "semantic-ui-react";
import { goTo } from "../../routing";
import loadingStore from "../../stores/loading-store";
import { hermes } from "../../utilities";
import api from "../../_services/authentication.service";
import nanobar from '../../components/Nanobar';

const NotificationPreferences = () => {
    const [newsletter, setNewsletter] = useState(false);
    const [whatsapp, setWhatsapp] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const history = useHistory();
    console.log(goTo, history);


    useEffect(async () => {
        nanobar.go(30);
        try {
            loadingStore.setLoading();
            const data = await api.getUserPreference();
            await setNewsletter(data.newsletter);
            await setWhatsapp(data.whatsapp);
            console.log(data);
            loadingStore.setLoaded();
            setLoaded(true);
        } catch (error) {
            hermes.error("Error loading user preferences");
            console.error(error);
        }
        nanobar.go(100);
    }, []);

    const handleCancel = () => {
        goTo("/", history);
    }

    const handleSave = async () => {
        // Save data to api
        try {
            await api.updateUserPreference({newsletter, whatsapp});
            hermes.success("Preferences saved successfully");
            goTo("/", history);
        } catch (error) {
            hermes.error("Error");
            console.error(error);
        }
    }

    if (!loaded) {
        return null;
    }

    return (
        <div className="form-template-wrapper">
            <Container fluid className="form-template notifications-page">
                <Header inverted>Notification Preferences</Header>
                <Segment>
                    <Form>
                        <div className="notifications-toggle">
                            <Checkbox toggle checked={newsletter} onChange={()=>setNewsletter(!newsletter)} label="Receive emails and email newsletters from our team" className="toggle"/>
                        </div>
                        <div className="notifications-toggle">
                            <Checkbox toggle checked={whatsapp} onChange={()=>setWhatsapp(!whatsapp)} label="Receive Whatsapp messages from our team" className="toggle"/>
                        </div>
                        <Button
                            onClick={handleSave}
                            primary
                        >
                            Save
                        </Button>
                        <Button basic onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Form>
                </Segment>
            </Container>
        </div>
    );

}

export default NotificationPreferences;