import { useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import ReactHtmlParser from 'react-html-parser';
import loadingStore from "../../stores/loading-store";
import nanobar from "../../components/Nanobar";
import { getTerms } from "../../_services/terms.service";
import LandingPageNav from "../../components/shared/LandingPageNav";
import authenticationService from "../../_services/authentication.service";
import { useHistory } from "react-router-dom";

export default function Terms() {
  const [terms, setTerms] = useState("");
  const history = useHistory();

  useEffect(() => {
    loadingStore.setPageMaskLoading();
    nanobar.go(30);
    getTerms().then(setTerms).then(() => {;
      loadingStore.setPageMaskLoaded();
      nanobar.go(100);
    });
  }, []);

  return (
    <Container className="legal-page">
      {ReactHtmlParser(terms)}
    </Container>
  );
}
