import { useEffect, useState } from "react";
import { Button, Card, Container, Form, Segment } from "semantic-ui-react";

interface RequireRandomPasswordProps {
  children: React.ReactNode;
  label: (password: string) => string;
  buttonLabel: string;
  className?: string;
}


export default function RequireRandomPassword({
  children,
  label,
  buttonLabel,
  className,
}: RequireRandomPasswordProps): React.ReactElement {
  const [randomPassword, setRandomPassword] = useState("");
  const [password, setPassword] = useState("");
  const [allow, setAllow] = useState(false);

  useEffect(() => {
    setRandomPassword(Math.random().toString(32).slice(-10));
  }, []);

  if (!children) {
    throw new Error("RequireRandomPassword: children is required");
  }

  if (!allow) {

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setAllow(password === randomPassword);
    };

    return (
      <div className={`require-password ${className || ''}`}>
        <Container className="legal-page">
          <div className="onboarding-5-container">
            <div className="onboarding-heading">Terms</div>
            <div className="onboarding-sub-heading ">{label(randomPassword)}</div>
            <div className="onboarding-column">
              <Form autoComplete="off" onSubmit={onSubmit}>
                <Card className="onboarding-form-wrapper">
                  <Form.Field>
                    <label>Password</label>
                    <input
                      type="text"
                      placeholder="Enter the password here"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Field>
                </Card>
                <Button
                  primary
                  type="submit"
                >
                  {buttonLabel}
                </Button>
              </Form>
            </div>
          </div>

        </Container>

      </div>
    );
  }

  return <>{children}</>;
}