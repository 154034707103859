import { Button, Input, Form, Segment, Header, Popup, Checkbox } from "semantic-ui-react";
import React, { Component, Fragment } from "react";
import { hermes } from "../../utilities";
import * as settingsSchema from "../../schemas/settings-schema";
import { withFormik, ErrorMessage } from "formik";
import ForgotPasswordButton from "../../components/settings/forgot-password-btn";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import profileStore from "../../stores/profile-store";
import { errors, success } from "../../messages";

class SettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyOpen: false,
      verifyEmailOpen: false,
      phoneNumber: "",
    };
  }

  componentDidMount() {
    this.lastVerificationNumberSent = "";
    this.lastVerificationEmailSent = "";
    this.setState({ verifyOpen: false });
  }

  // send verficiation to the specified phone number
  handleVerifyClick = async () => {
    const {
      values: { phoneNumber },
    } = this.props;

    if (this.lastVerificationNumberSent !== phoneNumber) {
      this.lastVerificationNumberSent = phoneNumber;
      try {
        await this.props.sendVerificationCode(phoneNumber);
      } catch (e) {
        hermes.error(e);
        return;
      }
    }
    this.setState({ verifyOpen: true });
  };

  //send email verification code for the specified email
  handleEmailVerifyClick = async () => {
    const {
      values: { email },
    } = this.props;

    if (this.lastVerificationEmailSent !== email) {
      this.lastVerificationEmailSent = email;
      try {
        await this.props.sendEmailVerificationCode(email);
      } catch (e) {
        hermes.error(e);
        return;
      }
    }
    this.setState({ verifyEmailOpen: true });
  };

  // Close opened phone number verify popup
  handleVerifyClose = () => {
    this.setState({ verifyOpen: false });
  };

  // Close opened email verify pop up
  handleEmailVerifyClose = () => {
    this.setState({ verifyEmailOpen: false });
  };

  // Will update phoneNumber
  handlePhoneNumberChange = (value) => {
    const { setFieldValue } = this.props;
    setFieldValue("phoneNumber", value);
  };

  handleToggle = (e, { name, checked }) => {
    this.props.setFieldValue(name, checked);
  }

  /**
   *  verify phone number verification code
   *  - checks verification code length
   *  - send the verification code with phone number to api endpoint
   *
   */
  verifyCode = async () => {
    const {
      values: { phoneNumber, verificationCode },
      verifyCode,
      setFieldValue,
    } = this.props;

    if (verificationCode.length !== 5) {
      hermes.error(errors.INVALID_VERIFICATION_CODE_LENGTH);
      return;
    }
    try {
      await verifyCode(phoneNumber, verificationCode);
      setFieldValue("lastPhoneNumbeVerified", phoneNumber);
      this.setState({ verifyOpen: false });
      hermes.success(success.PHONE_VERIFIED);
    } catch (e) {
      if (e.message) {
        hermes.error(e.message);
      } else {
        hermes.error(e);
      }
    }
  };

  // Verifies email verification code, checks verification code length, and send the verification code with email to api endpoint
  verifyEmailCode = async () => {
    const {
      values: { email, emailVerificationCode },
      verifyEmailCode,
      setFieldValue,
    } = this.props;

    if (emailVerificationCode.length !== 5) {
      hermes.error(errors.INVALID_VERIFICATION_CODE_LENGTH);
      return;
    }
    try {
      let response = await verifyEmailCode(email, emailVerificationCode);

      if (response.isValid) {
        setFieldValue("lastEmailVerified", email);
        this.setState({ verifyEmailOpen: false });
        hermes.success("Email Verified Successfully");
      } else {
        hermes.success("Email verification Failed");
      }
    } catch (e) {
      hermes.error(errors.INVALID_VERIFICATION_CODE);
    }
  };

  render() {
    const {
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      handleCancel,
      savingSettingsData,
      uploadingImage,

      sendingResetLink,
      sendResetEmail,
    } = this.props;

    let {
      email,
      lastEmailVerified,
      fullName,
      confirmPassword,
      newPassword,
      currentPassword,
      phoneNumber,
      lastPhoneNumbeVerified,
      verificationCode,
      emailVerificationCode,
      newsletter,
      personalEmails,
      whatsapp,
      browserNotificationsSwitch,
    } = values;

    return (
      <Fragment>
        <Header inverted>Settings</Header>
        <Segment className="segment">
          <Form onSubmit={handleSubmit} autoComplete="off">
            <div className="form-section">
              <Form.Field>
                <label className="label">Change your name</label>
                <Input
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={fullName}
                  type="text"
                  name="fullName"
                  autoComplete="new-password"
                  placeholder="Example: Clara Smith"
                />

                <div className="error-message" hidden>
                  <ErrorMessage name="fullName" />
                </div>
              </Form.Field>
            </div>

            <div className="form-section">
              <Form.Field>
                <label className="label">Change your email</label>
                <Input
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={email}
                  placeholder="Example: clarasmith@gmail.com"
                  autoComplete="new-password"
                  type="text"
                  name="email"
                />
                <div className="error-message" hidden>
                  <ErrorMessage name="email" />
                </div>
              </Form.Field>

              {email !== lastEmailVerified ? (
                <Popup
                  inverted
                  on="click"
                  open={this.state.verifyEmailOpen}
                  onOpen={this.handleEmailVerifyClick}
                  onClose={this.handleEmailVerifyClose}
                  content={
                    <Form className="form-template">
                      <Form.Field>
                        <label className="label">Enter email verification code</label>
                        <input
                          name="emailVerificationCode"
                          autoComplete="new-password"
                          value={emailVerificationCode}
                          onChange={handleChange}
                        />
                      </Form.Field>
                      <Button
                        primary
                        type="button"
                        onClick={this.verifyEmailCode}
                      >
                        Verify
                      </Button>
                    </Form>
                  }
                  trigger={
                    <Button type="button" className="verify-button" primary>
                      Verify
                    </Button>
                  }
                />
              ) : null}
            </div>

            <div className="form-section">
              <Form.Field>
                <label className="label">Change your phone number</label>
                <PhoneInput
                  value={phoneNumber}
                  name="phoneNumber"
                  placeholder="Enter your phone number"
                  autoComplete="new-password"
                  onChange={this.handlePhoneNumberChange}
                />
              </Form.Field>
              {phoneNumber !== lastPhoneNumbeVerified ? (
                <Popup
                  inverted
                  on="click"
                  open={this.state.verifyOpen}
                  onOpen={this.handleVerifyClick}
                  onClose={this.handleVerifyClose}
                  content={
                    <Form className="form-template">
                      <Form.Field>
                        <label className="label">Enter verification code</label>
                        <input
                          name="verificationCode"
                          autoComplete="new-password"
                          value={verificationCode}
                          onChange={handleChange}
                        />
                      </Form.Field>
                      <Button primary type="button" onClick={this.verifyCode}>
                        Verify
                      </Button>
                    </Form>
                  }
                  trigger={
                    <Button type="button" className="verify-button" primary>
                      Verify
                    </Button>
                  }
                />
              ) : null}
            </div>

            {/* Instagram username input */}
            <div className="form-section">
              <Form.Field>
                <label className="label">Instagram</label>
                <Input
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.instagram}
                  placeholder="Example: clarasmith"
                  autoComplete="instagram"
                  type="text"
                  name="instagram"
                />
              </Form.Field>
            </div>


            <div className="">
              <div className="notifications-toggle">
                <Checkbox
                  toggle
                  name="newsletter"
                  checked={newsletter}
                  onChange={this.handleToggle}
                  label="Receive emails and email newsletters from our team"
                  className="toggle"
                />
              </div>

              <div className="notifications-toggle">
                <Checkbox
                  toggle
                  name="browserNotificationsSwitch"
                  checked={browserNotificationsSwitch}
                  onChange={this.handleToggle}
                  label="Receive notifications when organisations you subscribe to post or cancel events"
                  className="toggle"
                />
              </div>

              <div className="notifications-toggle">
                <Checkbox
                  toggle
                  name="whatsapp"
                  checked={whatsapp}
                  onChange={this.handleToggle}
                  label="Receive Whatsapp messages from our team"
                  className="toggle"
                />
              </div>
              <div className="notifications-toggle">
                <Checkbox
                  toggle
                  name="personalEmails"
                  checked={personalEmails}
                  onChange={this.handleToggle}
                  label="Can we email you personally?"
                  className="toggle"
                />
              </div>
            </div>
            

            <div className="form-section">
              <Form.Field style={{ marginBottom: "13px" }}>
                <label className="label">Change your password</label>
                <Input
                  value={newPassword}
                  placeholder="At least 8 characters"
                  type="password"
                  name="newPassword"
                  autoComplete="new-password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="error-message" hidden>
                  <ErrorMessage name="newPassword" />
                </div>
              </Form.Field>

              <Form.Field>
                <Input
                  placeholder="Confirm your new password"
                  value={confirmPassword}
                  type="password"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="error-message" hidden>
                  <ErrorMessage name="confirmPassword" />
                </div>
              </Form.Field>
            </div>

            <div className="form-section">
              <Form.Field>
                <label className="label">To save changes, enter your password</label>
                <Input
                  value={currentPassword}
                  name="currentPassword"
                  type="password"
                  autoComplete="password"
                  placeholder="Enter your current password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="error-message" hidden>
                  <ErrorMessage name="currentPassword" />
                </div>
              </Form.Field>

              <div className="forgotten-password">
                <ForgotPasswordButton
                  inProgress={sendingResetLink}
                  sendEmail={sendResetEmail}
                />
              </div>
            </div>

            <Button
              primary
              type="submit"
              disabled={savingSettingsData || uploadingImage}
            >
              Save
            </Button>
            <Button className="cancel-button" type="button" onClick={handleCancel} basic>
              Cancel
            </Button>
          </Form>
        </Segment>
      </Fragment>
    );
  }
}

function createNeatObject({
  email,
  fullName,
  image,
  phoneNumber,
  instagram,
  verifiedPhoneNumber,
  newsletter,
  personalEmails,
  whatsapp,
  browserNotificationsSwitch,
}) {
  return {
    email: email || "",
    newPassword: "",
    confirmPassword: "",
    image: image,
    fullName: fullName,
    currentPassword: "",
    phoneNumber: phoneNumber || "+",
    instagram: instagram || "",
    verificationCode: "",
    lastPhoneNumbeVerified: phoneNumber || "",
    lastEmailVerified: email || "",
    emailVerificationCode: "",
    newsletter: newsletter || false,
    whatsapp: whatsapp || false,
    personalEmails: personalEmails || false,
    browserNotificationsSwitch: browserNotificationsSwitch || false,
  };
}

const MyEnhancedForm = withFormik({
  mapPropsToValues: (props) => {
    return createNeatObject(props);
  },

  validate: async (values) => {
    const {
      email,
      phoneNumber,
      lastPhoneNumbeVerified,
      newPassword,
      confirmPassword,
      currentPassword,
    } = values;

    if (!currentPassword) {
      hermes.error(errors.CURRENT_PASSWORD_REQUIRED);
      throw errors.CURRENT_PASSWORD_REQUIRED;
    }

    if (email !== profileStore.userData.email) {
      const error = await settingsSchema.emailSchema
        .validate(values)
        .then(() => false)
        .catch((err) => {
          hermes.error(err.errors[0]);
          return err;
        });
      if (error) {
        throw error;
      }
    }

    if (newPassword || confirmPassword) {
      const error = await settingsSchema.passwordSchema
        .validate(values)
        .then(() => false)
        .catch((err) => {
          hermes.error(err.errors[0]);
          return err;
        });
      if (error) {
        throw error;
      }
    }

    if (phoneNumber) {
      if (phoneNumber.trim() !== lastPhoneNumbeVerified.trim()) {
        hermes.error(errors.PHONE_VERIFICATION_REQUIRED);
        throw Error("Phone not verified");
      }
    }

    return {};
  },

  validateOnChange: false,
  validateOnBlur: false,

  handleSubmit: async (values, bag) => {
    await bag.props.submitData(values);
    bag.setFieldValue("newPassword", "");
    bag.setFieldValue("confirmPassword", "");
    bag.setFieldValue("currentPassword", "");
  },

  displayName: "SettingsForm",
})(SettingsForm);

export default MyEnhancedForm;
