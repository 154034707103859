import { addDays, isAfter, isBefore, isThisWeek, isToday, lastDayOfWeek, startOfWeek } from "date-fns";

type Filter = (event: IEvent, subscriptions?: ISubscription[]) => boolean;


export const timeFilters: { [key in TimeFilterType]: Filter } = {
  'today': ({ startDate, endDate }) => {
    const _startDate = startDate.toDate();
    const _endDate = endDate.toDate();
    const now = new Date();

    return (
      isToday(_startDate) ||
      (_startDate <= now && _endDate > now)
    )

  },

  'this-week': ({ startDate, endDate }) => {
    const _startDate = startDate.toDate();
    const _endDate = endDate.toDate();
    const now = new Date();

    return (
      isThisWeek(_startDate, { weekStartsOn: 1 }) ||
      (_startDate <= now && _endDate > now)
    )
  },
  "next-week": ({ startDate, endDate }) => {
    const _startDate = startDate.toDate();
    const _endDate = endDate.toDate();
    const now = new Date();

    return (
      // If start date is next week or before
      isBefore(_startDate, addDays(lastDayOfWeek(addDays(now, 7), { weekStartsOn: 1 }), 1)) &&
      // If end date is next week or after
      isAfter(_endDate, startOfWeek(addDays(now, 7), { weekStartsOn: 1 }))
    )
  },
  "later": ({ startDate }) => {
    const _startDate = startDate.toDate();
    const now = new Date();
    
    // If start date is later than next week
    return isAfter(_startDate, addDays(lastDayOfWeek(addDays(now, 7), { weekStartsOn: 1 }), 1));
  }
}


export const tabFilters : { [key in TabFilterType]: Filter } = {
  'all': () => true,
  'subscribed': ({ organizer }, subscriptions) => {
    if (!subscriptions || !subscriptions.length) {
      return false;
    }

    return subscriptions.some(subscription => subscription.organizer?._id === organizer?._id);
  }
}


export const filterNameMapper: { [key: string]: string } = {
  'today': 'Today',
  'this-week': 'This Week',
  'next-week': 'Next Week',
  'later': 'Later',
  'all': 'Discover',
  'subscribed': 'Subscribed'
}



type filterEventsQuery = {
  events: IEvent[];
  tabFilter: TabFilterType;
  timeFilter: TimeFilterType;
  subscriptions: ISubscription[];
  categories: string[];
}

export const filterEvents = (query:filterEventsQuery) => {
  const { events, tabFilter, timeFilter, subscriptions, categories } = query;
  
  return events.filter(event => (
    !event.featured &&
    tabFilters[tabFilter](event, subscriptions) && 
    timeFilters[timeFilter](event) &&
    (categories.length === 0 || event.categories.some(category => categories.includes(category._id)))
  ));
}
