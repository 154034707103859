import useSWR, { KeyedMutator } from 'swr';
import { hermes } from '../utilities';
import api from '../_services/authentication.service';

export default function usePreferences(): usePreferencesReturn{
  const { data, error, mutate } = useSWR<IUserPreferences>('preferences', api.getUserPreference);

  if (error) {
    hermes.error("Error fetching preferences");
  }

  return { mutate, preferences: data || {} }; 
}

interface usePreferencesReturn {
  mutate: KeyedMutator<IUserPreferences>;
  preferences: IUserPreferences;
}
