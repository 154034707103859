import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Form, Button, Card } from "semantic-ui-react";
import authenticationService from "../../_services/authentication.service";
import profileStore from "../../stores/profile-store";
import { hermes } from "../../utilities";
import { goTo } from "../../routing";
import { withRouter } from "react-router-dom";
import { Container } from "semantic-ui-react";
import "../../css/onboarding.css";
import { errors, success } from "../../messages";
import { Progress } from "semantic-ui-react";
import loadingStore from "../../stores/loading-store";
import config from "../../_helpers/config";
import { BLOCK_STUDENT_EMAIL } from "../../constants";

class EmailVerificationPage extends React.Component {
  constructor(props) {
    super(props);
    const user = profileStore.userData
      ? profileStore.userData
      : authenticationService.currentUserValue;
    this.state = {
      code: "",
      requestSent: true,
      time: 10,
      user: user,
      previousEmail: user?.email,
    };

    this.handleCode = this.handleCode.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResend = this.handleResend.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.sendVerificationCode = this.sendVerificationCode.bind(this);
    this.sendEmailVerificationCode = this.sendEmailVerificationCode.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.verifyCode = this.verifyCode.bind(this);

    const { history } = this.props;

    let shouldVerify = true;

    // redirect to home if already email verified
    if (authenticationService?.currentUserValue?.isEmailVerified === true) {
      goTo("/", history);
      shouldVerify = false;
    }

    const email = authenticationService?.currentUserValue?.email || "";

    if (shouldVerify && BLOCK_STUDENT_EMAIL && email.includes("@student.kuleuven.be")) {
      goTo("/onboarding/email/change", history);
      shouldVerify = false;
    }
    
    if (shouldVerify) {
      loadingStore.setPageMaskLoaded();
      const { code } = this.props.match.params;
      if (code) {
        this.verifyCode(code);
      } else if (this.props.location.search !== '?sent') {  
        this.sendVerificationCode();
      }
    }
  }

  // send verification code to users email
  sendVerificationCode() {
    authenticationService.requestEmailVerification().then(
      () => {
        this.setState({ requestSent: true, time: 60 });
        hermes.success(success.VERIFICATION_CODE_EMAIL_SENT);
      },
      (err) => {
        if (err === "TypeError: Failed to fetch") {
          hermes.error("TypeError: Failed to fetch");
        } else {
          hermes.error(err);
        }
      }
    );
  }

  // will show the second ui for entering verification code
  handleBackButton(e) {
    e.preventDefault();
    this.setState({ requestSent: true });
  }

  // sends email verification code to the new email user changed
  async sendEmailVerificationCode(e) {
    const { profileStore } = this.props;
    if (this.state.user.email === this.state.previousEmail) {
      this.setState({ requestSent: true, time: 60 });
    }

    try {
      //Send the verification code
      await profileStore.changeEmailAndSendCode(this.state.user.email);
      this.setState({ requestSent: true, time: 60 });
      hermes.success(success.VERIFICATION_CODE_EMAIL_SENT);
      this.setState({ previousEmail: this.state.user.email });

      //Set the view to the verify code view
      this.setState({
        view: this.CODE_VIEW,
      });
    } catch (e) {
      if (e === "TypeError: Failed to fetch") {
        hermes.error("TypeError: Failed to fetch");
      } else {
        hermes.error(e);
      }
    }
    e.preventDefault();
  }

  handleCode(event) {
    if (/^\d+$/.test(event.target.value) || event.target.value === "")
      this.setState({ code: event.target.value });
  }

  /**
   *  verifiy email verification code
   *  - checks verification code length
   *  - send the verification code with email to api endpoint
   *
   */
  async verifyCode(code) {
    const { profileStore } = this.props;

    if (code.length !== 5) {
      hermes.error(errors.INVALID_VERIFICATION_CODE_LENGTH);
      return;
    }
    try {
      await profileStore.verifyEmailVerification(
        this.state.user.email,
        code
      );
      hermes.success(success.EMAIL_VERIFIED);
      loadingStore.setPageMaskLoading();

      let nextPage = config.isOrganizerPageRequired() ? "/onboarding/subscribe" : "/onboarding/install";
      nextPage = config.isInvitePageRequired() ? "/onboarding/invites" : nextPage;

      console.log(nextPage);
      this.props.history.push(nextPage);
    } catch (e) {
      if (e.message) {
        hermes.error(e.message);
      } else {
        hermes.error(e);
      }
    }
  }

  handleSubmit(event) {
    this.verifyCode(this.state.code);

    event.preventDefault();
  }

  // resend verification code to users email
  handleResend(event) {
    event.preventDefault();
    this.setState({ code: "" });
    authenticationService.requestEmailVerification().then(
      () => {
        this.setState({ requestSent: true, time: 60 });
        hermes.success(success.VERIFICATION_CODE_EMAIL_SENT);
      },
      (err) => {
        if (err === "TypeError: Failed to fetch") {
          hermes.error("TypeError: Failed to fetch");
        } else {
          hermes.error(err);
        }
      }
    );
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    if (this.state.time !== 0 && this.state.requestSent)
      this.setState({
        time: this.state.time - 1,
      });
  }

  handleEmailChange(event) {
    var userr = this.state.user;
    userr.email = event.target.value;
    this.setState({ user: userr });
  }

  render() {
    const changeEmail = () => {
      return (
        <Fragment>
          <Form onSubmit={this.sendEmailVerificationCode} autoComplete="off">
            <Card className="onboarding-form-wrapper">
              <Form.Field style={{ marginBottom: 0 }}>
                <label>Email</label>

                <input
                  type="email"
                  value={this.state.user.email}
                  name="email"
                  onChange={this.handleEmailChange}
                />
              </Form.Field>
            </Card>
            <Button primary type="submit">
              Send
            </Button>
            <Button primary onClick={this.handleBackButton}>
              Back
            </Button>
          </Form>
        </Fragment>
      );
    };

    const codeInput = () => {
      return (
        <div className="onboarding-column">
          <Form onSubmit={this.handleSubmit}>
            <Card className="onboarding-form-wrapper">
              <Form.Field style={{ marginBottom: 0 }}>
                <label>Enter the verification code we sent</label>
                <input
                  type="text"
                  maxLength={5}
                  value={this.state.code}
                  onChange={this.handleCode}
                />
              </Form.Field>
            </Card>
            <Button primary type="button" onClick={this.handleResend}>
              Resend
            </Button>
            <Button
              primary
              type="button"
              onClick={() => this.setState({ requestSent: false, time: 60 })}
            >
              Change
            </Button>
            <Button primary type="submit">
              Verify
            </Button>
          </Form>
        </div>
      );
    };

    return (
      <Fragment>
        <Progress percent={50} className="onboarding-progress-bar" />
        <Container fluid>
          <div>
            <div className="onboarding-5-container">
              <div className="onboarding-heading">Confirm your email</div>
              <div className="onboarding-sub-heading ">
                Check your inbox, other and junk folders for {this.state.previousEmail}
              </div>

              {this.state.requestSent ? codeInput() : changeEmail()}
            </div>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default withRouter(
  inject("profileStore", "authStore")(observer(EmailVerificationPage))
);
