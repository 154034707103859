import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Header, Segment } from "semantic-ui-react";
import { goTo } from "../../routing";
import SubscriptionFormItem from "./subscription-form-item";
import api from "../../_services/authentication.service";
import nanobar from "../Nanobar";
import loadingStore from "../../stores/loading-store";
import { hermes } from "../../utilities";
import _useSubscriptions from "../../hooks/useSubscriptions";
import { suggestOrganizer as _suggestOrganizer } from "../../_services/organizers.service";

const defaultOrganizer = { _id: "default", name: "Search for organisers", "data-id": "default" }

const useSubscriptions = () => {
  const [loading, setLoading] = useState(true);

  const [organizers, setOrganizers] = useState<IOrganizer[]>([]);
  const [subscribedOrganizers, setSubscribedOrganizers] = useState<IOrganizer[]>([]);
  const [initialOrganizers, setInitialOrganizers] = useState<string[]>([]);

  useEffect(() => {
    nanobar.go(30)
    loadingStore.setPageMaskLoading();
    Promise.all([
      api.getOrganizers().then(res => res.organizers),
      api.getSubscribedOrganizers(),
      api.getUserPreference()
    ]).then(([organizers, subscribedOrganizers, userPreference]) => {

      setSubscribedOrganizers(subscribedOrganizers.filter((s: ISubscription) => s.organizer).map((subscription: ISubscription) => ({
        _id: subscription.organizer?._id,
        name: subscription.organizer?.name,
      })));

      setInitialOrganizers(subscribedOrganizers.map((subscription: ISubscription) => subscription.organizer?._id).filter(Boolean));
      setOrganizers([defaultOrganizer, ...organizers
        // .map((organizer: IOrganizer) => ({
        // _id: organizer._id,
        // name: organizer.name,
        // ""
        // }))
      ]);

    }).finally(() => {
      nanobar.go(100);
      loadingStore.setPageMaskLoaded();
      setLoading(false);
    })
  }, []);


  return {
    loading,
    organizers,
    subscribedOrganizers,
    setSubscribedOrganizers,
    initialOrganizers,
  }
}


const SubscriptionForm = () => {
  const history = useHistory();
  const [lastOrganizerSuggestion, setLastOrganizerSuggestion] = useState(0);

  const {
    loading,
    organizers,
    subscribedOrganizers,
    setSubscribedOrganizers,
    initialOrganizers,
  } = useSubscriptions();

  const { mutate } = _useSubscriptions();

  async function updateSubscriptions() {

    loadingStore.setLoading();

    try {
      await updateOrganizers();
      await mutate();
      hermes.success("Subscriptions saved");
      goTo("/", history);
    } catch (err) {
      console.log(err);
      hermes.error("Failed to update subscriptions")
    } finally {
      loadingStore.setLoaded();
    }
  }

  function updateOrganizers() {
    const subscribeOrganizerIds = subscribedOrganizers.map(organizer => organizer._id);
    const unsubscribeOrganizerIds = initialOrganizers.filter(organizerId => !subscribeOrganizerIds.includes(organizerId));
    return api.subscribeOrganizers(subscribeOrganizerIds, unsubscribeOrganizerIds);
  }

  const addEntity = (type: string, id: string) => {
    switch (type) {
      case "organizer": return addOrganizer(id);
      default: return;
    }
  }

  const removeEntity = (type: string, id: string) => {
    switch (type) {
      case "organizer": return removeOrganizer(id);
      default: return;
    }
  }

  const suggestAddition = (type: string, name: string) => {
    switch (type) {
      case "organizer": return suggestOrganizer(name);
      default: return;
    }
  }

  const addOrganizer = (id: string) => {
    if (subscribedOrganizers.findIndex(organizer => organizer._id === id) === -1 && id !== "default") {
      const organizer = organizers.find(organizer => organizer._id === id);
      if (organizer) setSubscribedOrganizers([...subscribedOrganizers, organizer]);
    }
  }

  const removeOrganizer = (id: string) => {
    setSubscribedOrganizers(subscribedOrganizers.filter(organizer => organizer._id !== id));
  }

  const suggestOrganizer = async (name: string) => {
    try {
      if (lastOrganizerSuggestion + 30000 < Date.now()) {
        await _suggestOrganizer(name);
        hermes.success("Your suggestion has been submitted");
        setLastOrganizerSuggestion(Date.now());
      }
    } catch (err) {
      hermes.error("Failed to submit suggestion");
    }
  }

  if (loading) {
    return null;
  }

  const entities = [
    {
      placeholder: "Search for organisers",
      type: "organizer",
      entities: organizers,
      selectedEntities: subscribedOrganizers,
      allowSuggestions: true,
    },
  ]

  return (
    <>
      <Header inverted>Subscriptions</Header>
      <Segment className="subscription">
        <Form autoComplete="off">
          {entities.map((entity) => {
            return (
              <div className="form-section" key={entity.type}>
                <SubscriptionFormItem
                  entityData={entity}
                  addEntity={addEntity}
                  removeEntity={removeEntity}
                  suggestAddition={entity.allowSuggestions ? suggestAddition : undefined}
                />
              </div>
            );
          })}

          <Button
            onClick={updateSubscriptions}
            primary
          >
            Save
          </Button>
          <Button basic onClick={() => goTo('/', history)}>
            Cancel
          </Button>
        </Form>
      </Segment>
    </>
  );
}


export default SubscriptionForm;