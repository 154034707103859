import React, { useState, useEffect } from 'react';
import {
  getEvents,
  deleteEvent,
  numberOfEvents,
} from '../../_services/event.service';
import hermes from '../../utilities/hermes';
import { Button, Card, Container, Label } from 'semantic-ui-react';
import { getOrdinalNum } from '../../utilities';
import Loader from '../../components/loader';
import ReactPaginate from 'react-paginate';
import '../../css/user-timeline.css';
import authenticationService from '../../_services/authentication.service';
import {
  Link,
  BrowserRouter as Router,
  Route,
  useHistory,
} from 'react-router-dom';
import moment from 'moment';


const ListEvent = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [eventTotal, setEventTotal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const getEventList = async () => {
    setLoading(true);
    const res = await getEvents(pageNumber);
    setEvents(res.events);
    setLoading(false);
  };
  const getTotalNumberOfEvent = async () => {
    const res = await numberOfEvents();
    setEventTotal(res);
  };

  useEffect(() => {
    getEventList();
    getTotalNumberOfEvent();
  }, []);

  function calculatePagesCount(pageSize, totalCount) {
    // we suppose that if we have 0 items we want 1 empty page
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  }

  var pageSize = 10;
  var itemsCount = eventTotal;
  var pagesCount = calculatePagesCount(pageSize, itemsCount);
  const deleteEventHandler = async (id) => {
    const res = deleteEvent(id).then((res) => {
      getEventList(res);
    });
    await getEventList();
    hermes.success('Event deleted');
    await getEventList();
    // await window.location.reload();
  };

  // paginate using backend
  const loadPage = (e) => {
    e.preventDefault();
    const params = new URLSearchParams(window.location.search);
    const page = parseInt(params.get('page')) || 1;
    if (page !== pageNumber) {
      getEventList();
    }
    getEventList();
  };
  return (
    <>
      <Container fluid>
        <div className="feed">
          <div className="main">
            <div style={{ display: 'flex', alignItems:'center', marginBottom:'10px' }}>
              <div>
                <div>
                  Page {pageNumber} of {pagesCount}
                </div>
                <form
                  onSubmit={(e) => loadPage(e)}
                  style={{ marginBottom: '10px' }}
                >
                  <input
                    name="page"
                    value={pageNumber}
                    placeholder="Page Number Input"
                    type="number"
                    className="page-count-input"
                    min="1"
                    max={pagesCount}
                    onChange={(e) => setPageNumber(e.target.value)}
                  />
                  <Button className="page-count-submit" type="submit" basic>
                    Go To
                  </Button>
                </form>
              </div>
              {/* add event button */}
              <div style={{height:'25px', marginLeft:'10px'}}>
                <Link to={`/add-event`}>
                  <Button primary type="submit">
                    Add Event
                  </Button>
                </Link>
              </div>
            </div>

            <div>
              {loading ? (
                <span>Loading</span>
              ) : (
                <>
                  {events.map((event) => {
                    // start date format
                    const checkStartDate = moment(event.startDate).utc(true).format(
                      'ddd Do MMMM YYYY HH:mm'
                    );
                    // end date format
                    const checkEndDate = moment(event.endDate).utc(true).format(
                      'ddd Do MMMM YYYY HH:mm'
                    );

                    const checkCancelled = event.cancelled;
                    return (
                      <Card fluid className="event-list-card">
                        <Card.Content>
                          {/* title */}
                          <div className="mb">
                            <Card.Meta
                              style={{ color: 'white', fontWeight: 'bold' }}
                            >
                              <span className={checkCancelled ? `event_cancelled` : ''}>{` ${event.title}`}</span>
                            </Card.Meta>
                          </div>
                          {/* start date */}
                          <div className="mb">
                            <Card.Meta style={{ color: 'white' }}>
                              Start:<span>{` ${checkStartDate}`}</span>
                            </Card.Meta>
                          </div>
                          {/* end date */}
                          <div className="mb">
                            <Card.Meta style={{ color: 'white' }}>
                              End:
                              {event.endDate === null ? (
                                ''
                              ) : (
                                <span>{` ${checkEndDate}`}</span>
                              )}
                            </Card.Meta>
                          </div>
                          {/* organizer name */}
                          <div className="mb">
                            <Card.Meta
                              style={{ color: 'white', fontWeight: 'bold' }}
                            >
                              <span>
                                <a
                                  className="link"
                                  href={event.organizer.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {event.organizer.name}
                                </a>{' '}
                              </span>
                            </Card.Meta>
                          </div>
                          {/* event link */}
                          <div className="mb">
                            <Card.Meta style={{ color: 'white' }}>
                              <span>
                                <a
                                  className="link"
                                  href={event.link}
                                  target="_blank"
                                >
                                  {event.link}
                                </a>{' '}
                              </span>
                            </Card.Meta>
                          </div>
                          <div className="mb">
                            <Card.Meta >
                              {event.categories.map(({ name }) => (
                                <Label className="entity-label" basic>
                                  {name}
                                </Label>
                              ))}
                            </Card.Meta>
                          </div>
                        </Card.Content>
                        {authenticationService?.currentUserValue?.role ===
                        'Admin' ? (
                          <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                            <div className="mb">
                              <Link to={`/edit/${event._id}`}>
                                <Button
                                  className="e_buttons"
                                  primary
                                  type="submit"
                                >
                                  Edit
                                </Button>
                              </Link>
                            </div>
                            <div>
                              <Button
                                type="button"
                                className="e_buttons"
                                basic
                                onClick={() => deleteEventHandler(event._id)}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        ) : null}
                      </Card>
                    );
                  })}
                  {/* <ReactPaginate
                    previousLabel={''}
                    nextLabel={''}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    pageClassName={'pagination-item'}
                    activeClassName={'active'}
                  /> */}
                </>
              )}
            </div>
            <Loader />
            {/* {children} */}
          </div>
        </div>{' '}
      </Container>
    </>
  );
};

export default ListEvent;