import { Link, useHistory } from "react-router-dom";
import { WhatsappShareButton } from "react-share";
import { Container } from "semantic-ui-react";
import FeedItem from "../../components/feed/FeedItem";
import useFilters from "../../hooks/useFilters";
import { ReactComponent as RefreshIcon } from '../../images/refresh.svg';
import { filterNameMapper, tabFilters, timeFilters } from "./helpers";
import { FeedContextProvider, useFeedContext } from "./useFeedContext";
import '../../css/timeline.css';
import { FEATURED_ON_TOP, WHATSAPP_MESSAGE } from "../../constants";
import FeaturedEvent from "../../components/subscription/featured-event";
import { useEffect, useRef } from "react";
import HintPopup from "../../components/shared/HintPopup";
import useWalkThrough from "../../hooks/useWalkThrough";

interface TimeFilterProps {
  type: TimeFilterType;
}

interface TabFilterProps {
  type: TabFilterType;
}

const TimeFilter = ({ type }: TimeFilterProps) => {
  const { timeFilter, updateTimeFilter } = useFilters();
  const { current, goToNext } = useWalkThrough();
  const filterRef = useRef<HTMLSpanElement>(null);

  let Wrapper = ({ children }: any) => children;

  if (type === 'later') {
    Wrapper = ({ children }: any) => (
      <HintPopup
        name="later"
        title="Later events"
        action="Next"
        onClick={() => updateTimeFilter(type)}
        verticalOffset={-10}
        position="bottom right"
        closeOnClickAway
        keepInViewPort={false}
        elementRef={filterRef}
      >
        {children}
      </HintPopup>
    )
  }

  const handleClick = () => {
    updateTimeFilter(type);
    if (type === 'later' && current === 'later') {
      goToNext();
    }
  }



  return (
    <Wrapper>
      <span
        ref={filterRef}
        className={`sub_nav_link ${type === timeFilter ? 'active' : ''}`}
        onClick={handleClick}
      >
        {filterNameMapper[type]}
      </span>
    </Wrapper>
  );
};

const TimeFilters = () => (
  <>
    {Object.keys(timeFilters).map((filter) => (
      <TimeFilter key={filter} type={filter as TimeFilterType} />
    ))}
  </>
)


const TabFilters = () => (
  <div className="nav_content">
    {Object.keys(tabFilters).map((filter) => (
      <TabFilter key={filter} type={filter as TabFilterType} />
    ))}
  </div>
);


const TabFilter = ({ type }: TabFilterProps) => {
  const { tabFilter, updateTabFilter } = useFilters();
  const { current, goToNext } = useWalkThrough();
  const filterRef = useRef<HTMLSpanElement>(null);

  let Wrapper = ({ children }: any) => children;

  if (type === 'subscribed') {
    Wrapper = ({ children }: any) => (
      <HintPopup
        name="subscribed tab"
        title="Subscribed"
        action="Next"
        id="subscribed-tab-popup"
        onClick={() => updateTabFilter(type)}
        position="bottom center"
        horizontalOffset={-14}
        verticalOffset={-10}
        closeOnClickAway
        elementRef={filterRef}
      >
        {children}
      </HintPopup>
    )
  } else if (type === 'all') {
    Wrapper = ({ children }: any) => (
      <HintPopup
        action="Next"
        title="All events"
        name="discover tab"
        id="discover-tab-popup"
        onClick={() => updateTabFilter(type)}
        verticalOffset={-14}
        horizontalOffset={10}
        position="bottom left"
        closeOnClickAway
        elementRef={filterRef}
      >
        {children}
      </HintPopup>
    )
  }

  const handleClick = () => {
    updateTabFilter(type);
    if (
      (type === 'subscribed' && current === 'subscribed tab') ||
      (type === 'all' && current === 'discover tab')
    ) {
      goToNext();
    }
  }

  return (
    <Wrapper>
      <span
        ref={filterRef}
        className={`nav_link ${tabFilter === type ? 'active' : ''}`}
        onClick={handleClick}>
        {filterNameMapper[type]}
      </span>
    </Wrapper>
  );
};


function FeedPage() {
  const { events, featuredEvents } = useFeedContext();
  const { current, goToNext } = useWalkThrough();
  const history = useHistory();

  useEffect(() => {
    document.getElementById("root")?.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Container fluid>
        <div className="feed">
          <div className="main">
            <div style={{ maxWidth: '700px' }}>
              <header className="feed_section_header">
                <nav className="feed_section_nav">
                  <TabFilters />
                  <div className="button-wrapper" id="poptarget">
                    <HintPopup
                      action="Go to subscription page"
                      title="Subscribe to events"
                      name="subscribe button"
                      onClick={() => history.push('/subscribe')}
                      verticalOffset={7}
                      position="bottom right"
                    >
                      <Link
                        className="-btn"
                        to="/subscribe"
                        onClick={() => current === 'subscribe button' && goToNext()}
                      >
                        <span className="filter_button">Subscribe</span>
                      </Link>
                    </HintPopup>
                  </div>
                </nav>
              </header>
              <nav className="feed_section_sub_nav">
                <TimeFilters />
                {/* <FilterTrigger /> */}
                <a href="/" className="sub_nav_link_refresh">
                  <RefreshIcon className="refresh-icon" />
                </a>
              </nav>
            </div>

            <div className="feed_events_wrapper">
              <div className="featured-items">
                {featuredEvents.map((event) => (
                  <FeedItem key={event._id} event={event} />
                ))}
              </div>
              {events.map((event) => (
                <FeedItem key={event._id} event={event} />
              ))}
            </div>
          </div>



          <div className="sidebar tablet-and-up">
            <section>
              <span className="title">
                <span>Contact Us</span>
              </span>
            </section>

            <div className="links-list">

              <div
                className="site-info-row"
              >
                <a
                  className="site-info"
                  href="https://wa.me/32496608026"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-whatsapp" />
                  <span>Whatsapp</span>
                </a>
              </div>

              <div className="site-info-row">

                <a
                  className="site-info"
                  href="https://instagram.com/leuveneventplanner"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-instagram" />
                  <span>Instagram</span>
                </a>
              </div>


            </div>


            {featuredEvents.length > 0 && (
              <div className={FEATURED_ON_TOP ? "hidden" : ""}>
                <section>
                  <span className="title">
                    <span>Featured</span>
                  </span>
                </section>

                <div className="links-list">
                  {featuredEvents.map(event => (
                    <FeaturedEvent key={event._id} {...event} />
                  ))}
                </div>
              </div>
            )}

            <section>
              <span className="title">
                <span>Help</span>
              </span>
            </section>

            <div className="links-list">
              {/*
              <div className="site-info-row">
                <Link to="/walkthrough" className="site-info" >
                  <i className="fa fa-info-circle" />
                  <span>Walkthrough</span>
                </Link>
              </div>
              */}
              <div className="site-info-row">
                <Link to="/report-bug" className="site-info">
                  <i className="fa fa-pencil" />
                  <span>Report Bug</span>
                </Link>
              </div>
            </div>


            <section>
              <span className="title">
                <span>About</span>
              </span>
            </section>

            <div className="links-list">

              <div className="site-info-row">
                <span
                  className="site-info"
                >
                  <i className="fab fa-whatsapp" />
                  <WhatsappShareButton
                    className="site-info"
                    title={WHATSAPP_MESSAGE}
                    separator=" "
                    url="https://www.leuveneventplanner.com"
                  ><span>Share On Whatsapp</span>
                  </WhatsappShareButton>
                </span>
              </div>



              <div className="site-info-row">
                <Link to="/terms" className="site-info">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z" />
                  </svg>
                  <span>Terms</span>
                </Link>

              </div>

            </div>
          </div>

        </div>
      </Container>
    </>
  );
}


export default function Feed() {
  return (
    <FeedContextProvider>
      <FeedPage />
    </FeedContextProvider>
  );
}