import React, { useState, useEffect } from 'react';
import { errors, success } from '../../messages';
import hermes from '../../utilities/hermes';
import { createBlob } from '../../utilities/';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Input,
  Form,
  Segment,
  Header,
  Container,
  Checkbox,
} from 'semantic-ui-react';
import {
  uploadOrganizerImage,
  getOrganizer,
  updateOrganizer,
} from '../../_services/organizers.service';
import '../../css/settings.css';
import axios from 'axios';
import config from '../../_helpers/config';
const EditOrganizer = ({ match }) => {
  const {
    params: { id },
  } = match;

  let history = useHistory();
  const token = localStorage.getItem('currentUserToken');

  const [organizer, setOrganizer] = useState({});
  const [imageChanged, setImageChanged] = useState(false);

  const [formData, setFormData] = useState({});

  const [image, setImage] = useState('');

  let simage = image ? createBlob(image) : organizer.image;

  const { name, url } = formData;

  useEffect(() => {
    setImageChanged(false);
    fetchData();

    async function fetchData() {
      const res = await getOrganizer(id);
      setOrganizer(res);
      setFormData({
        name: res.name,
        url: res.url || '',
        description: res.description || '',
        showAllEvents: res.showAllEvents || false,
        showAllEventInfo: res.showAllEventInfo || false,
      });
    }
  }, [id]);

  // image upload file change
  const onFileChange = (e) => {
    setImageChanged(true);
    setImage(e.target.files[0]);
  };

  // upload image
  const onUploadImage = (id) => {
    const formData = new FormData();
    formData.append('image', image);
    axios
      .put(`${config.apiUrl}/organizers/${id}/upload-image`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
      });
  };

  // changing text
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onChangeCheckbox = (e, { name, checked }) => 
    setFormData({ ...formData, [name]: checked });

  // submit form
  const onSubmit = async () => {
    try {
      if (imageChanged) {
        await onUploadImage(id);
      }
      await updateOrganizer(formData, id);
      history.push('/organizers');
      hermes.success('Update successful');
    } catch (error) {
      hermes.error('Update failed');
    }
    // history.push('/organizers'); // push to /organizers after submit
  };
  // add https:// to url input
  const onChangeUrl = (e) => {
    const url = e.target.value;
    if (url.indexOf('https://') === -1) {
      setFormData({ ...formData, url: `https://${url}` });
    } else {
      setFormData({ ...formData, url });
    }
  };

  return (
    <>
      <div className="form-template-wrapper">
        <Container fluid className="form-template settings">
          <Header inverted>Edit Organizer</Header>
          <Segment>
            <Form onSubmit={onSubmit}>
              <div className="form-section form-upload">
                <img
                  style={{
                    width: '3em',
                    height: '3em',
                    borderRadius: '50%',
                    marginRight: '1em',
                  }}
                  src={
                    simage ||
                    'https://t4.ftcdn.net/jpg/02/15/84/43/240_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg'
                  }
                />
                <a
                  role="button"
                  onClick={() => {
                    document.querySelector('.form-upload input').click();
                  }}
                >
                  Change profile photo
                </a>
                <input
                  type="file"
                  onChange={(e) => onFileChange(e)}
                  placeholder="Upload Image"
                />
              </div>
              <div className="form-section">
                <Form.Field>
                  <label>Name</label>
                  <Input
                    required
                    name="name"
                    value={name || organizer.name}
                    placeholder="Organizer name"
                    onChange={(e) => onChange(e)}
                  />
                </Form.Field>
              </div>
              <div className="form-section">
                <Form.Field>
                  <label>URL</label>
                  <Input
                    required
                    name="url"
                    value={url || organizer.url}
                    placeholder="https://example.com"
                    onChange={(e) => onChange(e)}
                  />
                </Form.Field>
              </div>
              <div className="form-section">
                <Form.Field>
                  <label>Description</label>
                  <Input
                    name="description"
                    value={formData.description}
                    placeholder="One sentence description"
                    onChange={(e) => onChange(e)}
                  />
                </Form.Field>
              </div>

              <div className="form-section">
                <Form.Field>
                  <Checkbox
                    label="Show all events"
                    name="showAllEvents"
                    checked={formData.showAllEvents}
                    onChange={onChangeCheckbox}
                  />
                </Form.Field>

                <Form.Field>
                  <Checkbox
                    label="Show all event info"
                    name="showAllEventInfo"
                    checked={formData.showAllEventInfo}
                    onChange={onChangeCheckbox}
                    />
                </Form.Field>
              </div>

              <Button primary type="submit">
                Save
              </Button>
              <Button onClick={history.goBack} type="button" basic>
                Cancel
              </Button>
            </Form>
          </Segment>
        </Container>
      </div>
    </>
  );
};

export default EditOrganizer;
